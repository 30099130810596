<template>
    <div>
        <div class="row">
            <div class="col-12 mb">
                <div class="h3 mb-small">
                    {{ $t("Components.Selbstauskunft.Show.TabRechnungsaddr.Header") }}
                </div>
                <p>
                    {{ $t("Components.Selbstauskunft.Show.TabRechnungsaddr.Text") }}
                </p>
            </div>
            <div class="col-12 col-xl-10">
                <validation-observer v-slot="{ invalid, dirty, handleSubmit }">
                    <formular-rechnungsadresse
                        class="mb-small"
                        :rechnungsadresse="rechnungsadresse"
                        :submitting-tried="submittingTried"
                    />
                    <div class="row">
                        <div class="col-12">
                            <button
                                class="btn btn-default"
                                :class="{ 'btn-disabled': submitButtonIsDisabled(invalid, dirty) }"
                                :disabled="submitButtonIsDisabled(invalid, dirty)"
                                @click.prevent="setSubmittingTried() && handleSubmit(submitRechnungsadresse)"
                                data-qa="rechnungsadresse-aendern-button"
                            >
                                {{ $t("Components.Selbstauskunft.Show.TabRechnungsaddr.Button") }}
                            </button>
                            <crefo-ring-loading v-if="rechnungsadresseSubmitting" />
                            <crefo-error-message
                                v-if="!rechnungsadresseSubmitting && rechnungsadresseSubmitted"
                                type="success"
                                class="mt"
                            >
                                {{ $t("Components.Selbstauskunft.Show.TabRechnungsaddr.Success") }}
                            </crefo-error-message>
                            <crefo-error-message
                                v-if="!rechnungsadresseSubmitting && rechnungsadresseAjaxError"
                                :ajax-error="rechnungsadresseAjaxError"
                                error-prefix="Rechnungsadresse"
                                class="mt"
                            />
                        </div>
                    </div>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useAbonnementStore } from '@/stores/abonnement'
    import { ValidationObserver } from "vee-validate"
    import FormularRechnungsadresse from "@/components/forms/formular-rechnungsadresse"
    import CrefoErrorMessage from "@dm/crefo-error-message"
    import CrefoRingLoading from "@dm/crefo-ring-loading"
    import * as api from "../../../services/index.js"
    import WrapperWebtrekk from "@/helper/webtrekk-wrapper"
    export default {
        name: "SelbstauskunftActionTabRechnungsadresse",
        components: { ValidationObserver, FormularRechnungsadresse, CrefoErrorMessage, CrefoRingLoading },
        props: {
            abonnement: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                submittingTried: false,
                rechnungsadresseSubmitting: false,
                rechnungsadresseSubmitted: false,
                rechnungsadresseAjaxError: null,
                rechnungsadresse: {}
            }
        },
        computed: {
            ...mapStores(useUserStore, useAbonnementStore),
            submitButtonIsDisabled () {
                return (invalid, dirty) => (invalid && (dirty || this.submittingTried)) || this.rechnungsadresseSubmitting
            }
        },
        watch: {
            abonnement: function (newValue, oldValue) {
                this.setRechnungsadresse()
            }
        },
        mounted () {
            this.setRechnungsadresse()
        },
        methods: {
            setRechnungsadresse () {
                const rechnungsadresse = {}
                if (this.abonnement && this.abonnement.rechnungsadresse) {
                    rechnungsadresse.name = this.abonnement.rechnungsadresse.name
                    rechnungsadresse.strasse = this.abonnement.rechnungsadresse.strasse
                    rechnungsadresse.hausnummer = this.abonnement.rechnungsadresse.hausnummer
                    rechnungsadresse.plz = this.abonnement.rechnungsadresse.plz
                    rechnungsadresse.ort = this.abonnement.rechnungsadresse.ort
                    rechnungsadresse.land = this.abonnement.rechnungsadresse.land
                    rechnungsadresse.email = this.abonnement.rechnungsadresse.email
                    rechnungsadresse.umsatzsteuerId = this.abonnement.rechnungsadresse.umsatzsteuerId
                    rechnungsadresse.kommunikationssprache = this.abonnement.rechnungsadresse.kommunikationssprache
                }
                this.rechnungsadresse = rechnungsadresse
            },
            setSubmittingTried () {
                return (this.submittingTried = true)
            },
            submitRechnungsadresse () {
                if (this.rechnungsadresseSubmitting) {
                    return
                }
                this.rechnungsadresseSubmitting = true
                this.rechnungsadresseSubmitted = false
                this.rechnungsadresseAjaxError = null
                api.setRechnungsadresseForAbonnement(this.abonnement.aboId, this.rechnungsadresse)
                    .then(() => {
                        this.abonnementStore.updateAbonnements({
                            crid: this.userStore.user.cr_id,
                            crefonummer: this.userStore.crefonummer
                        })
                        this.rechnungsadresseSubmitted = true
                        WrapperWebtrekk.trackAction(
                            "meine_bonitaet_frontend_action_updateRechnungsadresse_for_digitales_mitglied"
                        )
                    })
                    .catch(error => {
                        WrapperWebtrekk.trackAction(
                            "meine_bonitaet_frontend_error_updateRechnungsadresse_for_digitales_mitglied"
                        )
                        this.rechnungsadresseAjaxError = error
                    })
                    .finally(() => {
                        this.rechnungsadresseSubmitting = false
                    })
            }
        }
    }
</script>

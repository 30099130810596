<template>
    <div class="row">
        <div class="col-12 col-lg-10">
            <div :class="{ 'box-spacing': boxSpacing }">
                <div v-if="isNotAllowedCountry">
                    <crefo-error-message type="warning">
                        <div class="h3 h3--warning">
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.CountryErrorText', [ allowedCountriesAsText ]) }}
                        </div>
                    </crefo-error-message>
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.CountryText') }}
                    </p>
                    <p>
                        <strong class="text-semibold mb-small">
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.CountryActionText') }}
                        </strong>
                        <a
                            href="https://www.creditreform.de/creditreform/kontakt"
                            role="button"
                            class="btn btn-default btn-default--contact mb"
                            data-qa="redirect-register-ubo"
                        >
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.CountryButtonText') }}
                        </a>
                    </p>
                </div>
                <div v-if="isAuskunftNotAvailable">
                    <crefo-error-message type="warning">
                        <div class="h3 h3--warning">
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.NAErrorText') }}
                        </div>
                    </crefo-error-message>
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.NAText') }}
                    </p>
                    <i18n
                        path="Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.NAText2"
                        tag="p"
                    >
                        <router-link
                            :to="`/portal/ubo/aktivierung?crefonummer=${crefonummer ? crefonummer : ''}`"
                            data-qa="redirect-activate-ubo"
                        >
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.NAText2.LinkText') }}
                        </router-link>
                    </i18n>
                    <p v-if="authenticated">
                        <strong class="text-semibold mb-small">
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.NAActionText.LoggedIn') }}
                        </strong>
                        <router-link
                            :to="`/portal/ubo/unternehmensdaten-pflegen?crefonummer=${crefonummer ? crefonummer : ''}`"
                            role="button"
                            class="btn btn-default btn-default--ubo mb"
                            data-qa="redirect-register-ubo"
                        >
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.NAButtonText.LoggedIn') }}
                        </router-link>
                    </p>
                    <p v-else>
                        <strong class="text-semibold mb-small">
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.NAActionText.NotLoggedIn') }}
                        </strong>
                        <router-link
                            :to="`/portal/ubo/unternehmensdaten-pflegen?crefonummer=${crefonummer ? crefonummer : ''}`"
                            role="button"
                            class="btn btn-default btn-default--ubo mb"
                            data-qa="redirect-register-ubo"
                        >
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.NAButtonText.NotLoggedIn') }}
                        </router-link>
                    </p>
                </div>
                <div v-if="isKurzprofilNotAvailable">
                    <crefo-error-message type="warning">
                        <div class="h3 h3--warning">
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.KeinProfilErrorText') }}
                        </div>
                    </crefo-error-message>
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.KeinProfilText') }}
                    </p>
                    <p>
                        <strong class="text-semibold mb-small">
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.KeinProfilActionText') }}
                        </strong>
                        <a
                            href="https://www.creditreform.de/creditreform/kontakt"
                            role="button"
                            class="btn btn-default btn-default--contact mb"
                            data-qa="redirect-register-ubo"
                        >
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktNichtVerfuegbar.KeinProfilButtonText') }}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CrefoErrorMessage from '@dm/crefo-error-message'
    import GeneralUtilityHelper from '@/helper/general'
    export default {
        name: 'ProduktNichtVerfuegbar',
        components: { CrefoErrorMessage },
        props: {
            authenticated: {
                type: Boolean,
                required: true
            },
            crefonummer: {
                required: true
            },
            kurzprofil: {
                required: false,
                default: null
            },
            boxSpacing: {
                required: false,
                default: true
            }
        },
        computed: {
            isNotAllowedCountry () {
                return this.kurzprofil
                    && (
                        (this.kurzprofil.adresse && !GeneralUtilityHelper.isCountryAllowed(this.kurzprofil.adresse.land))
                        || (!this.kurzprofil.adresse && this.kurzprofil.selbstauskunftVerfuegbar)
                    )
            },
            allowedCountriesAsText: function () {
                const lang = this.$i18n.locale.split('_')[0].toLowerCase().replace('at', 'de')
                return GeneralUtilityHelper.getAllowedCountriesAsText(lang)
            },
            isAuskunftNotAvailable () {
                return this.kurzprofil
                    && (!this.kurzprofil.adresse || this.kurzprofil.adresse && GeneralUtilityHelper.isCountryAllowed(this.kurzprofil.adresse.land))
                    && !this.kurzprofil.selbstauskunftVerfuegbar
            },
            isKurzprofilNotAvailable () {
                return !this.kurzprofil
            }
        }
    }
</script>

<style scoped lang="less">
    strong {
        display: block;
    }

    .h3--warning {
        color: #ffffff;
        margin-bottom: 0;
    }

    .btn-default--ubo {
        @media only screen and (max-width: 570px) {
            line-height: normal;
            padding-top: 17px;
            padding-bottom: 16px;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
            line-height: normal;
            padding-top: 17px;
            padding-bottom: 16px;
        }
    }

    .btn-default--contact {
        @media only screen and (max-width: 400px) {
            line-height: normal;
            padding-top: 17px;
            padding-bottom: 16px;
        }
    }
</style>

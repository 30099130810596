import { defineStore } from 'pinia'
import * as api from '@/services/index.js'
import { checkUserStateForLoggedInUser } from '@/helper/user-state'
import { dispatchEvent } from '@/helper/polyfill'
import WrapperWebtrekk from '@/helper/webtrekk-wrapper'

export const useUserStore = defineStore(
    'user',
    {
        state: () => ({
            authenticated: false,
            delayedLoginInProgress: false,
            token: null,
            isMemberUser: false,
            crefonummerFetching: null,
            crefonummerUpdating: false,
            crefonummer: null,
            isVertretungsberechtigt: null,
            isUserStateDetermined: false,
            dateLocale: 'de',
            ajaxError: null,
            user: {
                cr_id: null,
                givenName: '',
                surname: '',
                email: null,
                user_roles: [],
                cr_membernumber: null,
                actForMemberId: null,
                actForMemberName: ''
            }
        }),
        actions: {
            /**
             * Should be called, when the keycloak token was initially set.
             *
             * @param {Object} data
             * @param {?string} data.token
             * @param {Object} data.idTokenParsed
             * @param {?string} data.idTokenParsed.email
             * @param {string} data.idTokenParsed.given_name
             * @param {string} data.idTokenParsed.family_name
             * @param {Array<string>} data.idTokenParsed.cr_userrole
             * @param {?string} data.idTokenParsed.cr_id
             * @param {?string} data.idTokenParsed.cr_membernumber
             */
            ON_VALID_TOKEN_EXISTS (data) {
                this.authenticated = true
                this.token = data.token
                this.isMemberUser = !!data.idTokenParsed.cr_mapping
                this.user.email = data.idTokenParsed.email
                this.user.givenName = data.idTokenParsed.given_name
                this.user.surname = data.idTokenParsed.family_name
                this.user.user_roles = data.idTokenParsed.cr_userrole
                this.user.cr_id = data.idTokenParsed.cr_id
                this.user.cr_membernumber = data.idTokenParsed.cr_membernumber
            },
            /**
             * Should be called, when the keycloak token was updated.
             *
             * Currently there is no real difference to ON_VALID_TOKEN_EXISTS.
             * Its only there, if it is needed to differentiate between token updates and the initial login.
             *
             * @param {Object} data
             * @param {?string} data.token
             * @param {Object} data.idTokenParsed
             * @param {?string} data.idTokenParsed.email
             * @param {string} data.idTokenParsed.given_name
             * @param {string} data.idTokenParsed.family_name
             * @param {Array<string>} data.idTokenParsed.cr_userrole
             * @param {?string} data.idTokenParsed.cr_id
             * @param {?string} data.idTokenParsed.cr_membernumber
             */
            ON_KEYCLOAK_TOKEN_UPDATE_SUCCESS (data) {
                this.authenticated = true
                this.token = data.token
                this.isMemberUser = !!data.idTokenParsed.cr_mapping
                this.user.email = data.idTokenParsed.email
                this.user.givenName = data.idTokenParsed.given_name
                this.user.surname = data.idTokenParsed.family_name
                this.user.user_roles = data.idTokenParsed.cr_userrole
                this.user.cr_id = data.idTokenParsed.cr_id
                this.user.cr_membernumber = data.idTokenParsed.cr_membernumber
            },
            /**
             * Should be called, when the user navigation to a different company using Unternehmensnavigation.
             *
             * @param {Object} data
             * @param {?string} data.actForMemberId
             * @param {string} data.actForMemberName
             */
            SET_ACT_FOR_MEMBER (data) {
                this.user.actForMemberId = data.actForMemberId
                this.user.actForMemberName = data.actForMemberName
            },
            /**
             * Should be called with the dayjs locale that should be used for date formatting.
             *
             * @param {string} dateLocale
             * @see https://github.com/iamkun/dayjs/tree/dev/src/locale
             */
            ON_SET_DATE_LOCALE (dateLocale) {
                this.dateLocale = dateLocale
            },
            /**
             * Should be called when fetching Crefonummer.
             */
            ON_SET_CREFONUMMER_FETCHING () {
                this.crefonummerFetching = true
            },
            /**
             * Should be called when fetching Crefonummer.
             */
            ON_SET_CREFONUMMER_UPDATING () {
                this.crefonummerUpdating = true
            },
            /**
             * Should be called with the Crefonummer that will be used for the current user.
             *
             * @param {?string} crefonummer
             */
            ON_SET_CREFONUMMER_SUCCESS (crefonummer) {
                this.crefonummer = crefonummer
                this.crefonummerFetching = false
                this.crefonummerUpdating = false
                this.ajaxError = null
            },
            /**
             * Should be called, when Crefonummer could not be fetched.
             *
             * @param {AjaxError} error
             */
            ON_SET_CREFONUMMER_ERROR (error) {
                this.crefonummer = null
                this.crefonummerFetching = false
                this.crefonummerUpdating = false
                this.ajaxError = error
            },
            /**
             * Should be called, when the user is Vertretungsberechtigt for the currently set Crefonummer.
             *
             * Vertretungsberechtigung should be checked against the currently set Crefonummer (state.crefonummer).
             *
             * @param {boolean} isVertretungsberechtigt
             */
            ON_SET_VERTRETUNGSBERECHTIGT (isVertretungsberechtigt) {
                this.isVertretungsberechtigt = isVertretungsberechtigt
            },
            /**
             * Should be called when user state was determined.
             *
             * Determined means that it is clear if
             *  - User has Abonnement
             *  - Which Abonnement to show (if there are multiple)
             *  - User is Vertretungsberechtigt
             *  - Crefonummer from user was fetched (does not matter if he has one or not)
             *  - Kurzprofil was fetched (does not matter if kurzprofil is available or not)
             *
             * @param {boolean} isDetermined
             */
            ON_SET_USER_STATE_DETERMINED (isDetermined) {
                this.isUserStateDetermined = isDetermined
            },
            ON_SET_DELAYED_LOGIN_IN_PROGRESS (inProgress) {
                this.delayedLoginInProgress = inProgress
            },
            /**
             * Handling of initial keycloak token.
             *
             * @param {Object} data
             * @param {?string} data.token
             * @param {Object} data.idTokenParsed
             * @param {?string} data.idTokenParsed.email
             * @param {string} data.idTokenParsed.given_name
             * @param {string} data.idTokenParsed.family_name
             * @param {Array<string>} data.idTokenParsed.cr_userrole
             * @param {?string} data.idTokenParsed.cr_id
             * @param {?string} data.idTokenParsed.cr_membernumber
             */
            async onValidTokenExists (data) {
                const changedFromNotLoggedInToLoggedIn = this.authenticated === false
                this.ON_SET_DELAYED_LOGIN_IN_PROGRESS(changedFromNotLoggedInToLoggedIn)
                this.ON_VALID_TOKEN_EXISTS(data)
                if (changedFromNotLoggedInToLoggedIn && this.crefonummer === null) {
                    await checkUserStateForLoggedInUser()
                    dispatchEvent('rolesVertretungsberechtigungUpdated')
                    this.ON_SET_DELAYED_LOGIN_IN_PROGRESS(false)
                }
            },
            /**
             * Handling of keycloak token updates.
             *
             * @param {Object} data
             * @param {?string} data.token
             * @param {Object} data.idTokenParsed
             * @param {?string} data.idTokenParsed.email
             * @param {string} data.idTokenParsed.given_name
             * @param {string} data.idTokenParsed.family_name
             * @param {Array<string>} data.idTokenParsed.cr_userrole
             * @param {?string} data.idTokenParsed.cr_id
             * @param {?string} data.idTokenParsed.cr_membernumber
             */
            async onKeycloakTokenUpdateSuccess (data) {
                const changedFromNotLoggedInToLoggedIn = this.authenticated === false
                this.ON_SET_DELAYED_LOGIN_IN_PROGRESS(changedFromNotLoggedInToLoggedIn)
                this.ON_KEYCLOAK_TOKEN_UPDATE_SUCCESS(data)
                if (changedFromNotLoggedInToLoggedIn && this.crefonummer === null) {
                    await checkUserStateForLoggedInUser()
                    dispatchEvent('rolesVertretungsberechtigungUpdated')
                    this.ON_SET_DELAYED_LOGIN_IN_PROGRESS(false)
                }
            },
            /**
             * Handling of company change using Unternehmensnavigation.
             *
             * @param {Object} data
             * @param {?string} data.actForMemberId
             * @param {string} data.actForMemberName
             */
            setActForMember (data) {
                this.SET_ACT_FOR_MEMBER(data)
            },
            /**
             * Handling of date format change based on given locale.
             *
             * @param {string} locale
             * @see https://github.com/iamkun/dayjs/tree/dev/src/locale
             */
            onLanguageChange (locale) {
                let dateLocale = locale.toLowerCase().split('_')

                // dayjs supports e.g 'de' 'de-at' 'de-ch' 'en' 'en-gb' 'en-au' 'es' 'es-us'...
                if (dateLocale[0] === dateLocale[1]) {
                    dateLocale = dateLocale[0]
                } else if (dateLocale[0] === 'en' && dateLocale[1] === 'us') {
                    //dateLocale = dateLocale[0]
                    // FIX FÜR DATUMSANZEIGE
                    dateLocale = 'en-gb'
                } else if (dateLocale[0] === 'en') {
                    // FIX FÜR EN
                    dateLocale = 'en-gb'
                } else {
                    dateLocale = dateLocale.join('-')
                }

                this.ON_SET_DATE_LOCALE(dateLocale)
            },
            /**
             * Sets the users Crefonummer based on member data or selfcare for non members.
             */
            async setCrefonummerForCurrentUser () {
                let crefonummer = null
                this.ON_SET_CREFONUMMER_FETCHING()

                try {
                    crefonummer = await getCrefonummerForCurrentUserFromMemberData()
                    this.ON_SET_CREFONUMMER_SUCCESS(crefonummer)
                } catch (error) {
                    try {
                        crefonummer = await getCrefonummerForCurrentUserFromUserData()
                        this.ON_SET_CREFONUMMER_SUCCESS(crefonummer)
                    } catch (error) {
                        this.ON_SET_CREFONUMMER_ERROR(error)
                    }
                }
            },
            /**
             * Assigns crefonumber to user.
             *
             * @param {string} crefonummer
             */
            updateCrefonummerForCurrentUser (crefonummer) {
                return new Promise((resolve, reject) => {
                    this.ON_SET_CREFONUMMER_UPDATING()
                    if (this.crefonummer === crefonummer) {
                        this.ON_SET_CREFONUMMER_SUCCESS(crefonummer)
                        resolve(crefonummer)
                        return
                    }

                    const patchObject = {
                        path: '/unverifiedCrefonummer'
                    }
                    if (typeof this.crefonummer === 'string' && this.crefonummer.length) {
                        if (typeof crefonummer === 'string' && crefonummer.length) {
                            patchObject.op = 'replace'
                            patchObject.value = crefonummer
                        } else {
                            patchObject.op = 'remove'
                        }
                    } else if (typeof crefonummer === 'string' && crefonummer.length) {
                        patchObject.op = 'add'
                        patchObject.value = crefonummer
                    } else {
                        this.ON_SET_CREFONUMMER_SUCCESS(null)
                        resolve(null)
                        return
                    }

                    api.patchUser({ patchOperations: [ patchObject ] })
                        .then(response => {
                            this.ON_SET_CREFONUMMER_SUCCESS(crefonummer)
                            resolve(crefonummer)
                            WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_updateCrefonummer')
                        })
                        .catch(error => {
                            this.ON_SET_CREFONUMMER_ERROR(error)
                            reject(error)
                            WrapperWebtrekk.trackAction('meine_bonitaet_frontend_error_updateCrefonummer')
                        })
                })
            }
        }
    }
)

/**
 * Fetches the Crefonummer for current user by reading member data.
 *
 * @return {Promise<string>}
 */
function getCrefonummerForCurrentUserFromMemberData () {
    return new Promise((resolve, reject) => {
        api.getMitglied()
            .then(response => {
                if (response.data && typeof response.data.businessId === 'string' && response.data.businessId.length) {
                    resolve(response.data.businessId)
                } else {
                    reject()
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

/**
 * Fetches the Crefonummer for current user (non member) by reading selfcare data.
 *
 * @return {Promise<string>}
 */
function getCrefonummerForCurrentUserFromUserData () {
    return new Promise((resolve, reject) => {
        api.getUser()
            .then(response => {
                if (response.data && typeof response.data.unverifiedCrefonummer === 'string'
                    && response.data.unverifiedCrefonummer.length) {
                    resolve(response.data.unverifiedCrefonummer)
                } else {
                    reject()
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}

<template>
    <div :class="'scs-' + scsName">
        <crefo-ring-loading
            v-if="isLoading"
            :wrap-in-row="true"
            class="pt"
        />
        <router-view v-show="!isLoading" />
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import countries from 'i18n-iso-countries'
    import CrefoRingLoading from '@dm/crefo-ring-loading'

    export default {
        components: { CrefoRingLoading },
        data () {
            return {
                scsName: 'meine-bonitaet-portal'
            }
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore),
            isLoading: function () {
                return this.$route?.meta?.isLoading || this.userStore.delayedLoginInProgress
            }
        },
        created () {
            const initAxiosInterceptor = () => {
                axios.interceptors.request.use(
                    async config => {
                        if (!window?.keycloak?.token) return config

                        let tokenExpires = Math.round(window.keycloak.tokenParsed.exp + window.keycloak.timeSkew - new Date().getTime() / 1000)
                        if (tokenExpires < 10) {
                            console.log('[axios] Token expires soon. Updating token before sending request')
                            await window.updateKeycloakToken()
                        } else {
                            console.log('[axios] Token still valid. No need to refresh before sending request')
                        }
                        config.headers.Authorization = `Bearer ${window.keycloak.token}`

                        delete config.headers['X-Act-For-Member-Id']
                        if (sessionStorage.getItem('actForMemberId') !== null) {
                            config.headers['X-Act-For-Member-Id'] = sessionStorage.getItem('actForMemberId')
                        }

                        return config
                    },
                    error => Promise.reject(error)
                )
            }

            const logPackageName = () => {
                if (!process?.env) return

                if (typeof process.env.PACKAGE_NAME === 'string' && process.env.PACKAGE_NAME.includes('/')) {
                    this.scsName = process.env.PACKAGE_NAME.split('/')[1]
                }
                if (process.env.NODE_ENV !== 'test') {
                    console.log(`SCS ${process.env.PACKAGE_NAME} ${process.env.PACKAGE_VERSION}`)
                }
            }

            initAxiosInterceptor()
            this.checkToken() || this.checkTokenDelayed()
            window.addEventListener('loggedIn', this.checkToken, false)
            window.addEventListener('keycloakTokenUpdated', this.updateToken, false)
            window.addEventListener('actForMemberChanged', this.updateActForMember, false)
            countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
            countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
            countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
            countries.registerLocale(require('i18n-iso-countries/langs/it.json'))
            logPackageName()
        },
        mounted () {
            const initServicesMocks = () => {
                if (process?.env?.NODE_ENV !== 'test') {
                    if (!localStorage?.getItem('axiosmock')) return
                    if (!location.hostname.includes('cp.meine.creditreform.de') && !location.hostname.includes('localhost')) return
                }

                const matched = require.context('./services', true, /-mock\.js$/)
                matched.keys().forEach(matched)
            }

            initServicesMocks()
            window.addEventListener('languageChanged', this.setLanguage, false)
            this.setLanguage()
        },
        beforeDestroy () {
            window.removeEventListener('languageChanged', this.setLanguage)
            window.removeEventListener('actForMemberChanged', this.updateActForMember)
            window.removeEventListener('keycloakTokenUpdated', this.updateToken)
            window.removeEventListener('loggedIn', this.checkToken)
        },
        methods: {
            checkToken () {
                if (window.keycloak && window.keycloak.token) {
                    this.userStore.onValidTokenExists(window.keycloak)
                    return true
                }
                return false
            },
            checkTokenDelayed (timeout = 100) {
                setTimeout(() => {
                    if (!this.checkToken() && timeout * 2 <= 30000) {
                        this.checkTokenDelayed(timeout * 2)
                    }
                }, timeout)
            },
            updateToken () {
                this.userStore.onValidTokenExists(window.keycloak)
            },
            updateActForMember () {
                let actForMemberId = sessionStorage.getItem('actForMemberId')
                let actForMemberName = sessionStorage.getItem('actForMemberName')

                if (actForMemberId === null || actForMemberName === null ||
                    actForMemberId === this.userStore.user.cr_membernumber
                ) {
                    this.userStore.setActForMember({
                        actForMemberId: null,
                        actForMemberName: ''
                    })
                } else {
                    this.userStore.setActForMember({
                        actForMemberId: actForMemberId,
                        actForMemberName: actForMemberName
                    })
                }
            },
            setLanguage () {
                let clientLanguage = 'de_DE'

                if (document.cookie.indexOf('CAS_PREFERRED_LANGUAGE') !== -1) {
                    let langCode = document.cookie.split('CAS_PREFERRED_LANGUAGE=')[1].split(';')[0]
                    if (langCode && langCode.length && /^[a-zA-Z_]{2,5}$/.test(langCode)) {
                        clientLanguage = langCode
                    }
                }

                this.$i18n.locale = clientLanguage
                this.userStore.onLanguageChange(clientLanguage)
                if (this.kurzprofilStore.kurzprofilAvailable) {
                    this.kurzprofilStore.updateKurzprofil()
                }
            }
        }
    }
</script>

<style lang="less">
    .scs-meine-bonitaet-portal {
        .px {
            padding-left: 2rem;
            padding-right: 2rem;

            &-small {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            &-large {
                padding-left: 4rem;
                padding-right: 4rem;
            }

            @media only screen and (max-width: 1199px) {
                padding-left: 1.333rem;
                padding-right: 1.333rem;

                &-small {
                    padding-left: 0.667rem;
                    padding-right: 0.667rem;
                }

                &-large {
                    padding-left: 2.667rem;
                    padding-right: 2.667rem;
                }
            }

            @media only screen and (max-width: 768px) {
                padding-left: 1rem;
                padding-right: 1rem;

                &-small {
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                }

                &-large {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }
        }

        .py {
            padding-top: 2rem;
            padding-bottom: 2rem;

            &-small {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            &-large {
                padding-top: 4rem;
                padding-bottom: 4rem;
            }

            @media only screen and (max-width: 1199px) {
                padding-top: 1.333rem;
                padding-bottom: 1.333rem;

                &-small {
                    padding-top: 0.667rem;
                    padding-bottom: 0.667rem;
                }

                &-large {
                    padding-top: 2.667rem;
                    padding-bottom: 2.667rem;
                }
            }

            @media only screen and (max-width: 768px) {
                padding-top: 1rem;
                padding-bottom: 1rem;

                &-small {
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }

                &-large {
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }
            }
        }
    }
</style>

<template>
    <div>
        <div class="container">
            <div class="row mb">
                <div class="col-12 col-lg-10">
                    <intro-text :long-intro="false" />
                    <crefo-error-message type="info">
                        <p class="text-semibold">
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedInWithoutCompany.ErrorMessage1') }}
                        </p>
                        <p class="text-semibold">
                            {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedInWithoutCompany.ErrorMessage2') }}
                        </p>
                    </crefo-error-message>
                </div>
            </div>
            <suche-unternehmen @company-selected="onCompanySelected" />
        </div>
        <div
            v-if="crefonummer"
            class="bg-brand bg-brand-light-grey box-spacing big-box-spacing mb"
        >
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-7 col-xl-8">
                        <suggested-kurzprofil
                            :crefonummer="crefonummer"
                            :show-header="false"
                            @crefo-kurzprofil-fetching-success="onKurzprofilAvailable"
                            @crefo-kurzprofil-fetching-error="onKurzprofilNoData"
                            @crefo-kurzprofil-fetching="onKurzprofilFetching"
                        />
                        <div v-if="kurzprofilLoaded">
                            <div
                                v-if="isMeineBonitaetAvailable"
                                class="row"
                            >
                                <div class="col-12 col-lg-10">
                                    <div class="box-spacing">
                                        <i18n
                                            path="Components.Selbstauskunft.Order.Landingpage.LandingLoggedInWithoutCompany.TeaserText"
                                            tag="p"
                                        >
                                            <strong>
                                                {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedInWithoutCompany.TeaserText.Headline') }}
                                            </strong>
                                            <br>
                                        </i18n>
                                        <button
                                            class="btn btn-default btn-default--register mb"
                                            data-qa="assign-crefonummer"
                                            @click.prevent="onAssignCrefonummer"
                                        >
                                            <span class="d-xl-none">
                                                {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedInWithoutCompany.ButtonTextMobile') }}
                                            </span>
                                            <span class="d-none d-xl-inline">
                                                {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedInWithoutCompany.ButtonTextDesktop') }}
                                            </span>
                                        </button>
                                        <crefo-error-message
                                            v-if="ajaxError"
                                            :ajax-error="ajaxError"
                                        />
                                    </div>
                                </div>
                            </div>
                            <produkt-nicht-verfuegbar
                                v-else
                                :authenticated="true"
                                :crefonummer="crefonummer"
                                :kurzprofil="kurzprofilData"
                                :box-spacing="true"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-lg-5 col-xl-4">
                        <produkt-information />
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mb-large">
                <div class="col-12 col-lg-6 col-xl-5">
                    <unternehmen-nicht-gefunden />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CrefoErrorMessage from '@dm/crefo-error-message'
    import SucheUnternehmen from './suche-unternehmen'
    import SuggestedKurzprofil from './suggested-kurzprofil'
    import IntroText from './intro-text'
    import ProduktInformation from './produkt-information'
    import ProduktNichtVerfuegbar from './produkt-nicht-verfuegbar'
    import UnternehmenNichtGefunden from './unternehmen-nicht-gefunden'
    import WrapperWebtrekk from '@/helper/webtrekk-wrapper'
    import GeneralUtilityHelper from '@/helper/general'
    import { dispatchEvent } from '@/helper/polyfill'
    export default {
        name: 'LandingLoggedInWithoutCompany',
        components: {
            CrefoErrorMessage,
            SucheUnternehmen,
            SuggestedKurzprofil,
            IntroText,
            ProduktInformation,
            ProduktNichtVerfuegbar,
            UnternehmenNichtGefunden
        },
        props: {
            preselectedCrefonummer: {
                type: String,
                required: false,
                default: ''
            },
            ajaxError: {
                type: Object,
                required: false,
                default: null
            }
        },
        data () {
            return {
                crefonummer: this.preselectedCrefonummer,
                kurzprofilData: null,
                kurzprofilLoaded: false
            }
        },
        computed: {
            isMeineBonitaetAvailable () {
                return this.kurzprofilData
                    && this.kurzprofilData.adresse
                    && GeneralUtilityHelper.isCountryAllowed(this.kurzprofilData.adresse.land)
                    && this.kurzprofilData.selbstauskunftVerfuegbar
            }
        },
        methods: {
            onCompanySelected (crefonummer) {
                this.kurzprofilLoaded = false
                this.kurzprofilData = null
                this.crefonummer = crefonummer
            },
            onKurzprofilAvailable (kurzprofilData) {
                this.kurzprofilData = kurzprofilData
                this.kurzprofilLoaded = true
            },
            onKurzprofilNoData () {
                this.kurzprofilData = null
                this.kurzprofilLoaded = true
            },
            onKurzprofilFetching () {
                this.kurzprofilData = null
                this.kurzprofilLoaded = false
            },
            onLogin () {
                WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_LandingpageGoLogin_for_digitales_mitglied')
                dispatchEvent('redirectToLogin')
            },
            onAssignCrefonummer () {
                if (this.crefonummer) {
                    this.$emit('crefonummer-assign', this.crefonummer)
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .btn-purple {
        background-color: #a763a4;

        &:hover {
            background-color: #894d87;
        }
    }
</style>

<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li>
                    <a href="/">{{ $t('shared.breadcrumbs.home') }}</a>
                </li>
                <li>
                    <router-link :to="{ name: 'index' }">
                        {{ $t('Appname') }}
                    </router-link>
                </li>
                <li data-qa="headline">
                    {{ $t('View.SelbstauskunftRegistrierung.BreadcrumbTitle') }}
                </li>
            </crefo-breadcrumbs>
            <div class="row">
                <div class="col-12 col-lg-10">
                    <h1 class="marketing">
                        <span>{{ $t('View.SelbstauskunftRegistrierung.Headline') }}</span>
                    </h1>
                </div>
            </div>
        </div>
        <landing-logged-in
            v-if="userStore.authenticated && userStore.crefonummer"
            :crefonummer="userStore.crefonummer"
            :kurzprofil="kurzprofilStore.kurzprofil"
        />
        <landing-logged-in-without-company
            v-if="userStore.authenticated && !userStore.crefonummer"
            :preselected-crefonummer="preselectedCrefonummer"
            :ajax-error="userStore.ajaxError"
            @crefonummer-assign="onAssignCrefonummer"
        />
        <landing-not-logged-in
            v-if="!userStore.authenticated"
            :preselected-crefonummer="preselectedCrefonummer"
        />
        <more-information />
        <vorteile-meine-bonitaet-teaser />
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import { useAbonnementStore } from '@/stores/abonnement'
    import CrefoBreadcrumbs from '../components/helper/crefo-breadcrumbs'
    import LandingLoggedIn from '../components/selbstauskunft/order/landingpage/landing-logged-in'
    import LandingNotLoggedIn from '../components/selbstauskunft/order/landingpage/landing-not-logged-in'
    import LandingLoggedInWithoutCompany from '../components/selbstauskunft/order/landingpage/landing-logged-in-without-company'
    import VorteileMeineBonitaetTeaser from '../components/selbstauskunft/order/partials/vorteile-meine-bonitaet-teaser'
    import MoreInformation from '../components/selbstauskunft/order/landingpage/more-information'
    import { checkUserStateForLoggedInUser } from '../helper/user-state'
    export default {
        name: 'SelbstauskunftRegistrierung',
        components: {
            CrefoBreadcrumbs,
            LandingLoggedIn,
            LandingNotLoggedIn,
            LandingLoggedInWithoutCompany,
            VorteileMeineBonitaetTeaser,
            MoreInformation
        },
        props: {
            preselectedCrefonummer: {
                type: String,
                required: false,
                default: ''
            }
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore, useAbonnementStore)
        },
        created () {
            this.abonnementStore.ON_CHANGE_CHECKOUT_STARTED(false)
        },
        methods: {
            onAssignCrefonummer (crefonummer) {
                this.userStore.updateCrefonummerForCurrentUser(crefonummer)
                    .then(async () => {
                        await checkUserStateForLoggedInUser()
                        try {
                            await this.$router.push({ name: 'selbstauskunft-zahlungsturnus' })
                        } catch (e) {}
                    })
                    .catch(error => {
                        console.log('Error setting crefonumber', error)
                    })
            }
        }
    }
</script>

<template>
    <div>
        <div class="container pt mb-large">
            <crefo-breadcrumbs>
                <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
                <li><router-link :to="{name: 'index'}">{{ $t('Appname') }}</router-link></li>
                <li data-qa="headline">{{ $t('Breadcrumbs.Bestellen.Vertretungsberechtigung') }}</li>
            </crefo-breadcrumbs>

            <crefo-ring-loading
                v-if="isLoading"
                :wrap-in-row="true"
                additional-classes="mt"
            />

            <div v-else>
                <div class="row">
                    <div
                        class="col-12"
                        :class="{ 'mb-large': hasUserAlreadyAnAbonnementForCurrentCrefo === false }"
                    >
                        <h1 data-qa="kopfzeile-firmenname">{{ kurzprofilStore.getFirmenname }}</h1>
                    </div>
                </div>
                <div
                    v-if="hasUserAlreadyAnAbonnementForCurrentCrefo === false"
                    class="row justify-content-center"
                >
                    <div class="col-12 col-lg-11 col-xl-10 mb-small">
                        <selbstauskunft-order-progress-bar step="1" />
                    </div>
                </div>

                <div
                    v-if="verifyAjaxError"
                    class="row mt"
                >
                    <div class="col-12">
                        <crefo-error-message
                            :ajax-error="verifyAjaxError"
                            error-prefix="Verifikation"
                        />
                    </div>
                </div>
                <div
                    v-if="showVerifyFailure"
                    class="row mt"
                >
                    <div class="col-12">
                        <crefo-error-message>
                            <p class="mb-0">
                                {{ $t('Verifikation.Message.Fehler') }}<br>
                                {{ $t('Verifikation.Message.Fehler.Tipp') }}
                            </p>
                        </crefo-error-message>
                    </div>
                </div>
            </div>
        </div>

        <selbstauskunft-order-text-about-verifikation
            v-if="!isLoading"
            :show-price="hasUserAlreadyAnAbonnementForCurrentCrefo === false"
        >
            <button
                class="btn btn-default btn-block"
                :class="{ 'btn-disabled': verifySubmitting }"
                :disabled="verifySubmitting"
                data-qa="authorize-button"
                @click.prevent="authorize"
            >
                {{ $t('Verifikation.StartButton') }}
            </button>
        </selbstauskunft-order-text-about-verifikation>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import { useAbonnementStore } from '@/stores/abonnement'
    import HelperCrefotrust from '@dm/helper-crefotrust'
    import SelbstauskunftOrderTextAboutVerifikation from './partials/text-about-verifikation'
    import SelbstauskunftOrderProgressBar from './partials/progress-bar'
    import CrefoBreadcrumbs from '../../helper/crefo-breadcrumbs'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    import WrapperWebtrekk from '@/helper/webtrekk-wrapper'
    import CrefoErrorMessage from "@dm/crefo-error-message"
    export default {
        name: 'VerifikationMitglied',
        components: {
            SelbstauskunftOrderTextAboutVerifikation,
            SelbstauskunftOrderProgressBar,
            CrefoBreadcrumbs,
            CrefoRingLoading,
            CrefoErrorMessage
        },
        props: {
            verified: {
                type: Boolean,
                default: null
            }
        },
        data () {
            return {
                verifySubmitting: false,
                verifyErrorCode: null,
                verifyAjaxError: null
            }
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore, useAbonnementStore),
            isLoading () {
                return this.userStore.isUserStateDetermined !== true
            },
            hasUserAlreadyAnAbonnementForCurrentCrefo () {
                return this.abonnementStore.selectedAbonnement !== null
            },
            showVerifyFailure () {
                return this.verified === false && this.verifyAjaxError === null && this.verifySubmitting === false
            }
        },
        methods: {
            authorize () {
                if (this.verifySubmitting) {
                    return
                }
                this.verifySubmitting = true
                this.verifyErrorCode = null
                this.verifyAjaxError = null

                WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_VerifikationContinue_for_mitglied')
                HelperCrefotrust.verify({
                    clientId: 'meine-bonitaet-monatlich',
                    crefonummer: this.userStore.crefonummer,
                    crefonummerUnchangeable: true,
                    packageName: '@portal-microfrontend/meine-bonitaet-portal'
                })
                    .then(result => {
                        console.log('Redirecting to CrefoTrust')
                    })
                    .catch(reason => {
                        this.verifyErrorCode = reason.errorCode
                        this.verifyAjaxError = reason.ajaxError
                        this.verifySubmitting = false
                    })
            }
        }
    }
</script>

<style scoped lang="less">
    .crefo-ui-alert {
        p:last-child {
            margin-bottom: 0;
        }
    }
</style>

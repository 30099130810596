<template>
    <div class="row">
        <div class="col-12">
            <crefo-ring-loading additional-classes="mt" />
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useAbonnementStore } from '@/stores/abonnement'
    import HelperUser from '@dm/helper-user'
    import HelperCrefotrust from '@dm/helper-crefotrust'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    export default {
        name: 'VerifikationPruefseite',
        components: { CrefoRingLoading },
        props: {
            verified: {
                type: Boolean,
                default: true
            },
            zahlungsturnus: {
                type: String,
                default: ''
            }
        },
        computed: {
            ...mapStores(useUserStore, useAbonnementStore)
        },
        created () {
            setTimeout(() => {
                if (this.verified) {
                    this.checkIsVerified()
                } else if (this.userStore.isMemberUser) {
                    this.onMemberRetryVerification()
                } else {
                    this.onNonMemberRetryVerification()
                }
            }, 1)
        },
        methods: {
            onMemberShowAbonnement () {
                this.$router.replace({ name: 'selbstauskunft-show', params: { verified: true }})
            },
            onMemberContinueCheckout () {
                this.$router.replace({ name: 'selbstauskunft-abonnement', params: { verified: true }})
            },
            onMemberRetryVerification () {
                this.$router.replace({ name: 'selbstauskunft-verifikation', params: { verified: false }})
            },
            onNonMemberShowAbonnement () {
                this.$router.replace({ name: 'selbstauskunft-show', params: { verified: true }})
            },
            onNonMemberContinueCheckout () {
                this.abonnementStore.ON_CHANGE_CHECKOUT_STARTED(true)
                this.abonnementStore.ON_SELECT_PAYMENT_CYCLE(this.zahlungsturnus === 'JAEHRLICH' ? 'JAEHRLICH' : 'MONATLICH')
                this.$router.replace({ name: 'selbstauskunft-zahlung', params: { verified: true }})
            },
            onNonMemberRetryVerification () {
                this.abonnementStore.ON_CHANGE_CHECKOUT_STARTED(true)
                this.abonnementStore.ON_SELECT_PAYMENT_CYCLE(this.zahlungsturnus === 'JAEHRLICH' ? 'JAEHRLICH' : 'MONATLICH')
                this.$router.replace({ name: 'selbstauskunft-verifikation', params: { verified: false }})
            },
            isSelectedAbonnementAllowedToShow () {
                return this.abonnementStore.selectedAbonnement !== null
                    && [ 'AKTIV', 'GEKUENDIGT', 'BEENDET' ].includes(this.abonnementStore.selectedAbonnement.status)
            },
            async checkIsVerified () {
                try {
                    await HelperCrefotrust.checkIsVerified(this.userStore.crefonummer)
                    this.userStore.ON_VALID_TOKEN_EXISTS(window.keycloak)
                    this.userStore.ON_SET_VERTRETUNGSBERECHTIGT(HelperUser.hasRoleVertretungsberechtigung(this.userStore.crefonummer))
                    this.abonnementStore.GET_ABONNEMENTS_SUCCESS(this.abonnementStore.abonnements)
                    if (this.userStore.isMemberUser) {
                        if (this.isSelectedAbonnementAllowedToShow()) {
                            this.onMemberShowAbonnement()
                        } else {
                            this.onMemberContinueCheckout()
                        }
                    } else {
                        if (this.isSelectedAbonnementAllowedToShow()) {
                            this.onNonMemberShowAbonnement()
                        } else {
                            this.onNonMemberContinueCheckout()
                        }
                    }
                } catch (error) {
                    if (this.userStore.isMemberUser) {
                        this.onMemberRetryVerification()
                    } else {
                        this.onNonMemberRetryVerification()
                    }
                }
            }
        }
    }
</script>

import './set-public-path'
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import UUID from 'vue-uuid'

import pinia from './stores'
import router from './router'
import i18n from './i18n'
import LoadScript from 'vue-plugin-load-script'

import App from './App.vue'

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(LoadScript)
Vue.use(UUID)

const vueLifecycles = singleSpaVue({
    Vue,
    appOptions: {
        render: (h) => h(App),
        pinia,
        router,
        i18n
    }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount

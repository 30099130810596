// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/img/950x503-selbstauskunft.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ellipsis[data-v-aed61424]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.header[data-v-aed61424]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:100% 0;background-size:80%;min-height:250px}.header .marketing[data-v-aed61424]{margin-top:11%;font-size:26px;max-width:285px}.header .marketing span[data-v-aed61424]{-webkit-box-decoration-break:clone;box-decoration-break:clone}.nw[data-v-aed61424]{white-space:nowrap}.btn-min[data-v-aed61424]{margin:5px 0 6px 0!important}.margin-around[data-v-aed61424]{margin:10px 0 20px 0!important}.abo-stop-animation[data-v-aed61424]{width:auto!important;position:relative;top:8px;left:5px}.mt-16[data-v-aed61424]{margin-top:16%}.mt-10px[data-v-aed61424]{margin-top:10px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

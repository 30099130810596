<template>
    <div class="box-shadow-xy box-teaser box-teaser--product-info">
        <img src="@/assets/img/bild_tablet-auskunft.jpg" />
        <div class="box-spacing" data-qa="product-information-box">
            <h3 class="mb">{{ $t("Components.Selbstauskunft.Order.Landingpage.ProduktInformation.Header") }}</h3>
            <div data-qa="payment-cycle-monthly">
                <p>
                    <strong data-qa="zahlungsturnus-anzeige-monatlich">
                        {{ $t("Components.Selbstauskunft.Order.Landingpage.ProduktInformation.BeiMonatlich") }}
                    </strong>
                </p>
                <i18n
                    path="Components.Selbstauskunft.Order.Landingpage.ProduktInformation.BetragMonatlich"
                    tag="p"
                    class="h3 mb-small px-small"
                    data-qa="zahlbetrag-anzeige-monatlich"
                >
                    <sup>*</sup>
                </i18n>
            </div>
            <div data-qa="payment-cycle-annually">
                <p>
                    <strong data-qa="zahlungsturnus-anzeige-jährlich">
                        {{ $t("Components.Selbstauskunft.Order.Landingpage.ProduktInformation.BeiJaehrlich") }}
                    </strong>
                </p>
                <i18n
                    path="Components.Selbstauskunft.Order.Landingpage.ProduktInformation.BetragJaehrlich"
                    tag="p"
                    class="h3 px-small"
                    data-qa="zahlbetrag-anzeige-jährlich"
                >
                    <sup>*</sup>
                </i18n>
                <p class="mb-small px-small">
                    <strong data-qa="ersparnis-anzeige">{{
                        $t("Components.Selbstauskunft.Order.Landingpage.ProduktInformation.Rabatt")
                    }}</strong>
                </p>
            </div>
            <i18n
                path="Components.Selbstauskunft.Order.Landingpage.ProduktInformation.Steuer"
                tag="p"
                class="text-small mb-small"
                data-qa="zahlbetrag-anzeige-steuer"
            >
                <sup>*</sup>
                <br />
                {{ $t("Components.Selbstauskunft.Order.Landingpage.ProduktInformation.Steuer2") }}
            </i18n>
            <slot></slot>
            <hr class="mb-small" />
            <example-download />
        </div>
    </div>
</template>

<script>
    import ExampleDownload from "./example-download"
    export default {
        name: "ProduktInformation",
        components: { ExampleDownload }
    }
</script>

<style scoped lang="less">
    .box-teaser--product-info {
        background-color: #ffffff;

        img {
            width: 100%;
        }

        p {
            margin-bottom: 0;
        }

        .text--hinweis-mitglieder {
            margin-top: 5px;
        }
    }
</style>

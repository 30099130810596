<script>
    export default {
        name: 'TagTrim',
        props: {
            tag: {
                type: String,
                default: 'span'
            }
        },
        render: function (createElement) {
            const content = createElement(this.tag, this.$slots.default)
            if (content.children instanceof Array) {
                content.children.forEach(child => {
                    if (typeof child.text === 'string') {
                        child.text = child.text.trim()
                    }
                })
            }

            return content
        }
    }
</script>

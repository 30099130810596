import { checkUserStateForLoggedInUser, checkUserStateForNotLoggedInUser } from './user-state'
import pinia from '@/stores'
import { useUserStore } from '@/stores/user'
import { useKurzprofilStore } from '@/stores/kurzprofil'
import { useAbonnementStore } from '@/stores/abonnement'
import HelperUser from '@dm/helper-user'
import HelperCrefotrust from '@dm/helper-crefotrust'
import GeneralUtilityHelper from '@/helper/general'
import WrapperWebtrekk from '@/helper/webtrekk-wrapper'

/**
 * Check if the current user is allowed to see the given route and suggest nextStep.
 *
 * @param {?object} from
 * @param {?object} to
 * @param {boolean} waitForUserStateDetermined
 * @param {number} msToWaitForLogin
 * @returns {Promise<unknown>}
 */
export const isRouteAllowed = (from, to, waitForUserStateDetermined = false, msToWaitForLogin = 50) => {
    let referrerIsCrefoPay = false

    return new Promise(async (resolve, reject) => {
        if (typeof to !== 'object' || to === null) {
            reject(null)
        } else if (typeof to.name !== 'string' || to.name.length === 0) {
            reject(null)
        } else {
            if (to.query && to.query.orderID) {
                referrerIsCrefoPay = true
            }
            const queryParams = {
                crefonummer: to.query && to.query.crefonummer ? to.query.crefonummer : null
            }
            let routeFrom = {}
            if (typeof from === 'object' && from !== null) {
                routeFrom = from
            }

            const validRoutesAndNextSteps = await getValidRoutesAndNextStep(referrerIsCrefoPay, routeFrom, to, queryParams, waitForUserStateDetermined, msToWaitForLogin)
            if (validRoutesAndNextSteps.validRouteNames.includes(to.name)) {
                resolve(validRoutesAndNextSteps)
            } else {
                reject(validRoutesAndNextSteps)
            }
        }
    })
}

/**
 * Always redirects user to next suggested step.
 *
 * @param {function} callback
 * @param {?object} from
 * @param {?object} to
 * @param {boolean} waitForUserStateDetermined
 * @param {number} msToWaitForLogin
 */
export const getValidRoutesAndRedirectToNextStep = (callback, from, to, waitForUserStateDetermined = false, msToWaitForLogin = 50) => {
    isRouteAllowed(from, to, waitForUserStateDetermined, msToWaitForLogin)
        .then(validRoutesAndNextSteps => {
            redirectToNextStep(callback, validRoutesAndNextSteps, from)
        })
        .catch(validRoutesAndNextSteps => {
            redirectToNextStep(callback, validRoutesAndNextSteps, from)
        })
}

/**
 * Redirects user based on given validRoutedAndNextSteps.
 *
 * @param {function} callback
 * @param {?object} validRoutesAndNextSteps
 * @param {?object} from
 */
export const redirectToNextStep = (callback, validRoutesAndNextSteps, from = null) => {
    if (validRoutesAndNextSteps === null) {
        window.location.href = '/'
    } else if (validRoutesAndNextSteps.nextStep.replace) {
        window.location.replace(validRoutesAndNextSteps.nextStep.replace)
    } else if (validRoutesAndNextSteps.nextStep.location) {
        window.location.href = validRoutesAndNextSteps.nextStep.location
    } else if (typeof callback !== 'function') {
        window.location.href = '/'
    } else if (from === null || from !== validRoutesAndNextSteps.nextStep) {
        callback(validRoutesAndNextSteps.nextStep)
    }
}

function isStatusOfSelectedAbonnementOneOf (stati = []) {
    const abonnementStore = useAbonnementStore(pinia)

    if (abonnementStore.selectedAbonnement !== null && stati.includes(abonnementStore.selectedAbonnement.status)) {
        return true
    }
    return false
}

function isUserVertretungsberechtigtForSelectedAbonnement () {
    const abonnementStore = useAbonnementStore(pinia)

    if (abonnementStore.selectedAbonnement !== null) {
        return HelperUser.hasRoleVertretungsberechtigung(abonnementStore.selectedAbonnement.crefonummer)
    }
    return false
}

function isSelectedAbonnementForDifferentCrefonummer () {
    const userStore = useUserStore(pinia)
    const abonnementStore = useAbonnementStore(pinia)

    return abonnementStore.selectedAbonnement
        && abonnementStore.selectedAbonnement.crefonummer !== userStore.crefonummer
}

function hasUserAnActiveAbonnementForHisCrefonummer () {
    const userStore = useUserStore(pinia)
    const abonnementStore = useAbonnementStore(pinia)

    return abonnementStore.abonnements.find(abonnement =>
        abonnement.crefonummer === userStore.crefonummer
        && [ 'AKTIV', 'GEKUENDIGT' ].includes(abonnement.status)) !== undefined
}

function isAbonnementForUserAvailableForPurchase () {
    const kurzprofilStore = useKurzprofilStore(pinia)

    return kurzprofilStore.kurzprofilAvailable
        && kurzprofilStore.kurzprofil.adresse
        && GeneralUtilityHelper.isCountryAllowed(kurzprofilStore.kurzprofil.adresse.land)
        && kurzprofilStore.kurzprofil.selbstauskunftVerfuegbar
}

function isUserAllowedToStartCheckout () {
    const userStore = useUserStore(pinia)

    return userStore.crefonummer
        && userStore.isMemberUser === false
        && isAbonnementForUserAvailableForPurchase()
        && hasUserAnActiveAbonnementForHisCrefonummer() === false
}

function isUserAllowedToStartPayment () {
    const userStore = useUserStore(pinia)
    const abonnementStore = useAbonnementStore(pinia)

    return isUserAllowedToStartCheckout()
        && userStore.isVertretungsberechtigt
        && abonnementStore.paymentCycle
        && abonnementStore.rechnungsadresseAvailable
}

function isUserAllowedToFinalizePayment () {
    const userStore = useUserStore(pinia)
    const abonnementStore = useAbonnementStore(pinia)

    return userStore.crefonummer
        && userStore.isMemberUser === false
        && abonnementStore.abonnements.find(abonnement =>
            abonnement.crefonummer === userStore.crefonummer
            && [ 'AKTIV', 'VORBEREITET', 'NICHT_BEGONNEN' ].includes(abonnement.status)) !== undefined
}

function getValidRoutesAndNextStep (referrerIsCrefoPay, from, to, queryParams, waitForUserStateDetermined, msToWaitForLogin) {
    const userStore = useUserStore(pinia)
    const kurzprofilStore = useKurzprofilStore(pinia)
    const abonnementStore = useAbonnementStore(pinia)

    return new Promise(async resolve => {
        // Wait for check, if user is logged in
        const loggedIn = await isUserLoggedIn(msToWaitForLogin)

        // Wait until userState is determined
        if (userStore.isUserStateDetermined !== true) {
            if (waitForUserStateDetermined) {
                if (loggedIn) {
                    // Check if user has freshly returned from Crefotrust, in that case try update token until any VB role is set
                    if (to.name === 'verifikation-pruefseite-success') {
                        try {
                            await HelperCrefotrust.checkIsVerified()
                            console.log('Initial Crefotrust Verification for any VB role was successful!')
                        } catch (error) {
                            console.log('Initial Crefotrust Verification for any VB role was not successful!')
                        }
                    }
                    userStore.ON_VALID_TOKEN_EXISTS(window.keycloak)
                    const referrerIsCrefoTrust = ['verifikation-pruefseite-success', 'verifikation-pruefseite-error'].includes(to.name)
                    await checkUserStateForLoggedInUser(referrerIsCrefoPay, referrerIsCrefoTrust)
                } else {
                    await checkUserStateForNotLoggedInUser()
                }
                sendWebtrekkCheckStatus()
            } else {
                resolve({
                    validRouteNames: [ 'index' ],
                    nextStep: { location: '/' }
                })
                return
            }
        }

        // Check which route makes sense for the user
        if (loggedIn) {
            // Check if user has freshly returned from Crefotrust, in that case try update token until specific VB role is set
            if (to.name === 'verifikation-pruefseite-success' && !HelperUser.hasRoleVertretungsberechtigung(userStore.crefonummer)) {
                try {
                    await HelperCrefotrust.checkIsVerified(userStore.crefonummer)
                    userStore.ON_VALID_TOKEN_EXISTS(window.keycloak)
                    userStore.ON_SET_VERTRETUNGSBERECHTIGT(HelperUser.hasRoleVertretungsberechtigung(userStore.crefonummer))
                    abonnementStore.GET_ABONNEMENTS_SUCCESS(abonnementStore.abonnements)
                    console.log('Crefotrust Verification for specific VB role was successful!')
                } catch (error) {
                    console.log('Crefotrust Verification for specific VB role was not successful!')
                }
            }

            // Logged in users
            if (userStore.isMemberUser) {
                // VC Mitglied
                if (isStatusOfSelectedAbonnementOneOf([ 'AKTIV', 'GEKUENDIGT', 'BEENDET' ])) {
                    if (userStore.isVertretungsberechtigt) {
                        resolve({
                            validRouteNames: [ 'index', 'selbstauskunft-show', 'verifikation-pruefseite-success' ],
                            nextStep: {
                                name: 'selbstauskunft-show',
                                params: { aboId: abonnementStore.selectedAbonnement.aboId }
                            }
                        })
                    } else {
                        resolve({
                            validRouteNames: [ 'index', 'selbstauskunft-verifikation', 'verifikation-pruefseite-success', 'verifikation-pruefseite-error' ],
                            nextStep: { name: 'selbstauskunft-verifikation' }
                        })
                    }
                } else if (kurzprofilStore.kurzprofilAvailable !== true) {
                    resolve({
                        validRouteNames: [ 'index', 'selbstauskunft-ausland' ],
                        nextStep: { name: 'selbstauskunft-ausland' }
                    })
                } else if (!kurzprofilStore.kurzprofil.adresse ||
                    GeneralUtilityHelper.isCountryAllowed(kurzprofilStore.kurzprofil.adresse.land) === false) {
                    resolve({
                        validRouteNames: [ 'index', 'selbstauskunft-ausland' ],
                        nextStep: { name: 'selbstauskunft-ausland' }
                    })
                } else if (kurzprofilStore.kurzprofil.selbstauskunftVerfuegbar !== true) {
                    resolve({
                        validRouteNames: [ 'index', 'selbstauskunft-nicht-verfuegbar' ],
                        nextStep: { name: 'selbstauskunft-nicht-verfuegbar' }
                    })
                } else if (userStore.isVertretungsberechtigt !== true) {
                    resolve({
                        validRouteNames: [ 'index', 'selbstauskunft-erwerben', 'selbstauskunft-verifikation', 'verifikation-pruefseite-success', 'verifikation-pruefseite-error' ],
                        nextStep: { name: 'selbstauskunft-erwerben' }
                    })
                } else {
                    resolve({
                        validRouteNames: [ 'index', 'selbstauskunft-erwerben', 'selbstauskunft-abonnement', 'verifikation-pruefseite-success' ],
                        nextStep: { name: 'selbstauskunft-erwerben' }
                    })
                }
            } else {
                // Digitaler Kunde
                if (isSelectedAbonnementForDifferentCrefonummer()) {
                    // Spezialfall
                } else {
                    if (isStatusOfSelectedAbonnementOneOf([ 'AKTIV' ])) {
                        const validRouteNames = [ 'index' ]
                        const nextStep = {}
                        if (isUserVertretungsberechtigtForSelectedAbonnement()) {
                            const params = { aboId: abonnementStore.selectedAbonnement.aboId }
                            if (to.name === 'verifikation-pruefseite-success') {
                                params.verified = true
                            } else if (to.name === 'verifikation-pruefseite-success') {
                                params.verified = false
                            }

                            validRouteNames.push('selbstauskunft-show')
                            nextStep.name = 'selbstauskunft-show'
                            nextStep.params = params
                        } else {
                            validRouteNames.push('selbstauskunft-verifikation')
                            validRouteNames.push('verifikation-pruefseite-error')
                            validRouteNames.push('verifikation-pruefseite-success')
                            nextStep.name = 'selbstauskunft-verifikation'
                        }
                        if (isUserAllowedToFinalizePayment()) { // Für den Fall das der User das Abo gerade erworben hat - gerade AKTIV wurde
                            validRouteNames.push('crefopay-erfolgsseite')
                        }
                        resolve({ validRouteNames, nextStep })
                    } else if (isStatusOfSelectedAbonnementOneOf([ 'GEKUENDIGT' ])) {
                        const validRouteNames = [ 'index' ]
                        const nextStep = {}
                        if (isUserVertretungsberechtigtForSelectedAbonnement()) {
                            validRouteNames.push('selbstauskunft-show')
                            nextStep.name = 'selbstauskunft-show'
                            nextStep.params = { aboId: abonnementStore.selectedAbonnement.aboId }
                        } else {
                            validRouteNames.push('selbstauskunft-verifikation')
                            validRouteNames.push('verifikation-pruefseite-error')
                            validRouteNames.push('verifikation-pruefseite-success')
                            nextStep.name = 'selbstauskunft-verifikation'
                        }
                        resolve({ validRouteNames, nextStep })
                    } else if (isUserAllowedToStartPayment()) {
                        const validRouteNames = [ 'index', 'selbstauskunft-zahlungsturnus', 'selbstauskunft-zahlung', 'crefopay-mock-pay', 'crefopay-fehlerseite', 'verifikation-pruefseite-success' ]
                        const nextStep = { name: 'selbstauskunft-registrierung' }
                        if (isStatusOfSelectedAbonnementOneOf([ 'BEENDET' ])) {
                            validRouteNames.push('selbstauskunft-show')
                            nextStep.name = 'selbstauskunft-show'
                            nextStep.params = { aboId: abonnementStore.selectedAbonnement.aboId }
                        } else {
                            validRouteNames.push('selbstauskunft-registrierung')
                            if (abonnementStore.checkoutStarted) {
                                nextStep.name = 'selbstauskunft-zahlungsturnus'
                            }
                        }
                        if (isUserAllowedToFinalizePayment()) { // Wenn Abo in VORBEREITET oder NICHT_BEGONNEN, dann kann ein Kaufvorgang vorhanden sein
                            validRouteNames.push('crefopay-erfolgsseite')
                        }
                        resolve({ validRouteNames, nextStep })
                    } else if (isUserAllowedToStartCheckout()) {
                        const validRouteNames = [ 'index', 'selbstauskunft-zahlungsturnus', 'crefopay-fehlerseite' ]
                        const nextStep = { name: 'selbstauskunft-registrierung' }
                        if (isStatusOfSelectedAbonnementOneOf([ 'BEENDET' ]) && isUserVertretungsberechtigtForSelectedAbonnement()) {
                            validRouteNames.push('selbstauskunft-show')
                            nextStep.name = 'selbstauskunft-show'
                            nextStep.params = { aboId: abonnementStore.selectedAbonnement.aboId }
                        } else if (isStatusOfSelectedAbonnementOneOf([ 'BEENDET' ]) && !isUserVertretungsberechtigtForSelectedAbonnement()) {
                            validRouteNames.push('selbstauskunft-verifikation')
                            validRouteNames.push('verifikation-pruefseite-error')
                            nextStep.name = 'selbstauskunft-verifikation'
                        } else {
                            validRouteNames.push('selbstauskunft-registrierung')
                            if (!userStore.isVertretungsberechtigt
                                && abonnementStore.paymentCycle
                                && abonnementStore.rechnungsadresseAvailable
                            ) {
                                validRouteNames.push('selbstauskunft-verifikation')
                                validRouteNames.push('verifikation-pruefseite-error')
                            }
                            if (abonnementStore.checkoutStarted) {
                                nextStep.name = 'selbstauskunft-zahlungsturnus'
                            }
                        }
                        if (isUserAllowedToFinalizePayment()) { // Wenn Abo in VORBEREITET oder NICHT_BEGONNEN, dann kann ein Kaufvorgang vorhanden sein
                            validRouteNames.push('crefopay-erfolgsseite')
                        }
                        resolve({ validRouteNames, nextStep })
                    } else {
                        const validRouteNames = [ 'index', 'selbstauskunft-registrierung', 'crefopay-fehlerseite' ]
                        const nextStep = { name: 'selbstauskunft-registrierung', query: getQueryForRoute(queryParams, [ 'crefonummer' ])}
                        if (isUserAllowedToFinalizePayment()) { // Spezialfall wo nach dem Erwerb die Voraussetzungen zum Kauf nicht mehr vorliegen
                            validRouteNames.push('crefopay-erfolgsseite')
                        }
                        resolve({ validRouteNames, nextStep })
                    }
                }
            }
        } else {
            resolve({
                validRouteNames: [ 'index', 'selbstauskunft-registrierung' ],
                nextStep: { name: 'selbstauskunft-registrierung', query: getQueryForRoute(queryParams, [ 'crefonummer' ]) }
            })
        }
    })
}

function getQueryForRoute (queryParams, queryParamNamesToAdd) {
    if (typeof queryParams !== 'object' || queryParams === null) {
        return {}
    } else if (!(queryParamNamesToAdd instanceof Array) || queryParamNamesToAdd.length === 0) {
        return {}
    }

    const query = {}
    queryParamNamesToAdd.forEach(queryParamName => {
        if (typeof queryParams[queryParamName] === 'string' && queryParams[queryParamName].length) {
            query[queryParamName] = queryParams[queryParamName]
        }
    })

    return query
}

function isUserLoggedIn (maxWaitingTime) {
    function checkLoginState () {
        return window && window.keycloak && window.keycloak.token
        && window.keycloak.tokenParsed ? true : false
    }

    function checkLoginStateDelayed (resolve, timeout, timeElapsed = 0) {
        if (checkLoginState()) {
            resolve(true)
        } else if (timeElapsed >= maxWaitingTime || maxWaitingTime === 0) {
            resolve(false)
        } else {
            if (timeElapsed + timeout > maxWaitingTime) {
                timeout = maxWaitingTime - timeElapsed
            }
            setTimeout(() => {
                checkLoginStateDelayed(resolve, timeout * 2, timeElapsed + timeout)
            }, timeout)
        }
    }

    return new Promise(resolve => {
        checkLoginStateDelayed(resolve, 50)
    })
}

function sendWebtrekkCheckStatus () {
    const userStore = useUserStore(pinia)
    const kurzprofilStore = useKurzprofilStore(pinia)
    const abonnementStore = useAbonnementStore(pinia)

    let userStateParameters = (userStore.isVertretungsberechtigt ? 'Y' : 'N') + '/'
    userStateParameters += (kurzprofilStore.kurzprofilAvailable ? 'Y' : 'N') + '/'
    if (kurzprofilStore.kurzprofilAvailable) {
        userStateParameters += (kurzprofilStore.kurzprofil.selbstauskunftVerfuegbar === true ? 'Y' : 'N') + '/'
        userStateParameters += (kurzprofilStore.kurzprofil.adresse && GeneralUtilityHelper.isCountryAllowed(kurzprofilStore.kurzprofil.adresse.land) ? 'Y' : 'N')
    } else {
        userStateParameters += 'N/N'
    }

    WrapperWebtrekk.trackAction(
        'meine_bonitaet_frontend_action_checkUserState',
        {
            7: abonnementStore.selectedAbonnement === null ? '-' : String(abonnementStore.selectedAbonnement.aboId),
            8: typeof userStore.crefonummer === 'string' ? userStore.crefonummer : '-',
            9: abonnementStore.abonnementsVertretungsberechtigt.length,
            10: abonnementStore.abonnementsNotVertretungsberechtigt.length,
            12: userStateParameters
        }
    )
}

<template>
    <div>
        <div class="container">
            <div class="row mb pt">
                <div class="col-12">
                    <i
                        aria-hidden="true"
                        class="crefo-ui-icon icon-circle-success icon-color-blue"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h2
                        class="mb-small"
                        data-qa="order-success"
                    >
                        {{ $t('Abonnement.Success.Message') }}
                    </h2>
                </div>
            </div>
        </div>
        <div class="bg-brand bg-brand-blue box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-7 col-xl-8 mb">
                        <div class="box-teaser">
                            <img
                                src="@/assets/img/MeineBonitaet-Mockup-Freisteller.png"
                                alt=""
                            >
                        </div>
                    </div>
                    <div class="col-12 col-lg-5 col-xl-4">
                        <div class="row pt">
                            <div class="col-12 text-brand-white">
                                <h2>
                                    {{ $t('Components.Selbstauskunft.Order.Partials.Success.IhrProduktPrefix') }}
                                </h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-brand-white">
                                <h3>
                                    <span>{{ $t('Components.Selbstauskunft.Order.Partials.Success.IhrProdukt') }} </span>
                                    <span data-qa="firmenname">{{ kurzprofilStore.getFirmenname }}</span>
                                </h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="h3">
                                    <p>
                                        <router-link
                                            :to="{ name: 'selbstauskunft-show', params: { aboId: abonnementStore.selectedAbonnement ? abonnementStore.selectedAbonnement.aboId : '' }}"
                                            class="btn btn-default bg-brand-purple"
                                            role="button"
                                            data-qa="show-button"
                                        >
                                            <span>{{ $t('Abonnement.Label.Weiter') }}</span>
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import { useAbonnementStore } from '@/stores/abonnement'

    export default {
        name: 'SelbstauskunftOrderSuccess',
        props: {
            step: {
                type: String,
                default: '0'
            }
        },
        computed: {
            ...mapStores(useKurzprofilStore, useAbonnementStore)
        }
    }
</script>


<style scoped lang="less">
    .icon-circle-success {
        font-size: 73px;
    }

    .box-teaser {
        margin-top: 15px;

        img {
            width: 70%;
        }

        & > div {
            a.btn {
                margin-top: 3px;
            }
        }
    }

    .bg-brand {
        margin-top: 60px;
        margin-bottom: -60px;
        padding-bottom: 0 !important;

        .text-center {
            text-align: center;
        }

        h2, h3, p, span {
            color: #ffffff;
        }

        @media only screen and (max-width: 1199px) {
            h2, h3 {
                margin-bottom: 30px;
            }
        }

        .row-reasons {
            & > div {
                text-align: center;

                i {
                    display: inline-block;
                    font-size: 73px;
                }

                img {
                    width: 73px;
                    height: 73px;
                }

                span {
                    line-height: 1.7;
                }
            }
        }
    }

    .bg-brand-light-grey {
        height: 100%;

        p {
            position: relative;
            padding-left: 38px;
            margin-bottom: 6px;
            line-height: 22px;

            span {
                vertical-align: top;

                &[data-qa="email"] {
                    word-break: break-all;
                }
            }

            .crefo-brand-icon {
                position: absolute;
                left: 0;
                top: 3px;
            }
        }
    }
</style>

<template>
    <div>
        <div class="row">
            <div class="col-12 col-lg-6 mb">
                <div class="h3">
                    {{ $t("Components.Selbstauskunft.Show.TabAboReg.Header") }}
                </div>
                <div data-qa="reiter-mein-abonnement-content">
                    <div class="h4 mb-small">
                        {{ $t("Components.Selbstauskunft.Show.TabAboReg.Zahlungsturnus") }}
                    </div>
                    <p v-if="abonnement.turnus === 'jaehrlich'">
                        <i18n path="Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusJ.Satz1" :tag="false">
                            <span data-qa="mein-abonnement-start-datum">
                                {{ startDatum }}
                            </span>
                            <span class="nw" data-qa="mein-abonnement-betrag">
                                {{ betragNetto }}
                                {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusJ.Satz1.Euro") }}
                            </span>
                            <tag-trim
                                v-if="showUStBetragDE"
                                data-qa="mein-abonnement-betrag-brutto"
                                class="nw"
                            >
                                ({{ betragBrutto }}
                                {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusJ.Satz1.Euro") }}
                                {{
                                    $t("Components.Selbstauskunft.Show.TabAboReg.Zahlungsturnus.InklusiveUmsatzsteuer")
                                }})
                            </tag-trim>
                        </i18n>
                        <br />
                        <i18n path="Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusJ.Satz2" :tag="false">
                            <span data-qa="mein-abonnement-turnus">
                                {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusJ.Satz2.jaehrlich") }}
                            </span>
                            <span>
                                <span class="nw" data-qa="mein-abonnement-betrag2">
                                    {{ betragNetto }}
                                    {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusJ.Satz2.Euro") }}
                                </span>
                                <tag-trim
                                    v-if="showUStBetragDE"
                                    class="nw"
                                    data-qa="mein-abonnement-betrag2-brutto"
                                >
                                    ({{ betragBrutto }}
                                    {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusJ.Satz2.Euro") }}
                                    {{
                                        $t(
                                            "Components.Selbstauskunft.Show.TabAboReg.Zahlungsturnus.InklusiveUmsatzsteuer"
                                        )
                                    }})
                                </tag-trim>
                                {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusJ.Satz2.via") }}
                                <span data-qa="mein-abonnement-zahlungsart"> {{ zahlungsanbieter }}. </span>
                            </span>
                        </i18n>
                    </p>
                    <p v-if="abonnement.turnus === 'monatlich'">
                        <i18n path="Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusM.Satz1" :tag="false">
                            <span data-qa="mein-abonnement-start-datum">{{ startDatum }}</span>
                            <span class="nw" data-qa="mein-abonnement-betrag">
                                {{ betragNetto }}
                                {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusM.Satz1.Euro") }}
                            </span>
                            <tag-trim
                                v-if="showUStBetragDE"
                                class="nw"
                                data-qa="mein-abonnement-betrag-brutto"
                            >
                                ({{ betragBrutto }}
                                {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusM.Satz1.Euro") }}
                                {{
                                    $t("Components.Selbstauskunft.Show.TabAboReg.Zahlungsturnus.InklusiveUmsatzsteuer")
                                }})
                            </tag-trim>
                        </i18n>
                        <br />
                        <i18n path="Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusM.Satz2" :tag="false">
                            <span data-qa="mein-abonnement-turnus">
                                {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusM.Satz2.monatlich") }}
                            </span>
                            <span>
                                <span class="nw" data-qa="mein-abonnement-betrag2">
                                    {{ betragNetto }}
                                    {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusM.Satz2.Euro") }}
                                </span>
                                <tag-trim
                                    v-if="showUStBetragDE"
                                    class="nw"
                                    data-qa="mein-abonnement-betrag2-brutto"
                                >
                                    ({{ betragBrutto }}
                                    {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusJ.Satz2.Euro") }}
                                    {{
                                        $t(
                                            "Components.Selbstauskunft.Show.TabAboReg.Zahlungsturnus.InklusiveUmsatzsteuer"
                                        )
                                    }})
                                </tag-trim>
                                {{ $t("Components.Selbstauskunft.Show.TabAboReg.ZahlungsturnusM.Satz2.via") }}
                                <span data-qa="mein-abonnement-zahlungsart"> {{ zahlungsanbieter }}. </span>
                            </span>
                        </i18n>
                    </p>
                    <p v-if="!['jaehrlich', 'monatlich'].includes(abonnement.turnus)">
                        {{ $t("Components.Selbstauskunft.Show.TabAboReg.Zahlungsturnus.Unbekannt") }}
                    </p>
                    <p
                        v-if="abonnement.gesperrt === true"
                        class="text-brand-red text-bold"
                        data-qa="mein-abonnement-gesperrt"
                    >
                        {{ $t("Components.Selbstauskunft.Show.TabAboReg.Zahlungsturnus.AbonnementGeperrt") }}
                    </p>

                    <p
                        v-if="abonnement.rechnungsadresse.land !== 'DE'"
                        data-qa="mein-abonnement-content-zahlbetrag-anzeige-steuer"
                    >
                        {{ $t("Components.Selbstauskunft.Order.Landingpage.ProduktInformation.Steuer2") }}
                    </p>
                    <div class="h4 mb-small">
                        {{ $t("Components.Selbstauskunft.Show.TabAboReg.Fragen") }}
                    </div>
                    <i18n path="Components.Selbstauskunft.Show.TabAboReg.Fragen.Kontakt" tag="p">
                        <a href="https://www.creditreform.de/creditreform/kontakt/">{{
                            $t("Components.Selbstauskunft.Show.TabAboReg.Fragen.Kontakt.Formular")
                        }}</a>
                        <br />
                        <span class="nw">
                            {{ $t("Components.Selbstauskunft.Show.TabAboReg.Fragen.Kontakt.AboId") }}
                            <strong data-qa="mein-abonnement-aboid">{{ abonnement.aboId }}</strong>
                        </span>
                    </i18n>
                    <div class="h4 mb-small">
                        {{ $t("Components.Selbstauskunft.Show.TabAboReg.Mitglied") }}
                    </div>
                    <i18n path="Components.Selbstauskunft.Show.TabAboReg.Mitglied.Text" tag="p">
                        <br />
                        <a
                            class="btn btn-default mt-10px"
                            role="button"
                            target="_blank"
                            href="https://www.creditreform.de/mitgliedschaft/mitglied-werden"
                        >
                            {{ $t("Components.Selbstauskunft.Show.TabAboReg.Mitglied.Link.Text") }}
                        </a>
                    </i18n>
                    <div class="h4 mb-small">
                        {{ $t("Components.Selbstauskunft.Show.TabAboReg.Kuendigung") }}
                    </div>
                    <p>
                        <template v-if="abonnement.turnus === 'jaehrlich'">
                            <i18n path="Components.Selbstauskunft.Show.TabAboReg.Kuendigung.Jaehrlich" :tag="false">
                                <span data-qa="mein-abonnement-kuendigungsfrist">{{ $t("Components.Selbstauskunft.Show.TabAboReg.Kuendigung.Jaehrlich.Frist") }}</span>
                                <a
                                    v-if="!(stopAboIsDisabled && !stopRequestWasSent) && !stopAboShouldBeDisabled"
                                    @click.prevent="onClickAboStop"
                                    href="#"
                                    data-qa="abonnement_kuendigen_link_aktiv"
                                    >{{ $t("Components.Selbstauskunft.Show.TabAboReg.Kuendigung.Kündigen") }}</a
                                >
                                <span v-else data-qa="abonnement_kuendigen_link_inaktiv">{{
                                    $t("Components.Selbstauskunft.Show.TabAboReg.Kuendigung.Kündigen")
                                }}</span>
                            </i18n>
                            <br />
                        </template>
                        <template v-if="abonnement.turnus === 'monatlich'">
                            <i18n path="Components.Selbstauskunft.Show.TabAboReg.Kuendigung.Monatlich" :tag="false">
                                <span data-qa="mein-abonnement-kuendigungsfrist">{{ $t("Components.Selbstauskunft.Show.TabAboReg.Kuendigung.Monatlich.Frist") }}</span>
                                <a
                                    v-if="!(stopAboIsDisabled && !stopRequestWasSent) && !stopAboShouldBeDisabled"
                                    @click.prevent="onClickAboStop"
                                    href="#"
                                    data-qa="abonnement_kuendigen_link_aktiv"
                                    >{{ $t("Components.Selbstauskunft.Show.TabAboReg.Kuendigung.Kündigen") }}</a
                                >
                                <span v-else data-qa="abonnement_kuendigen_link_inaktiv">{{
                                    $t("Components.Selbstauskunft.Show.TabAboReg.Kuendigung.Kündigen")
                                }}</span>
                            </i18n>
                            <span v-if="!stopAboIsDisabled">
                                {{
                                    $t("Components.Selbstauskunft.Show.TabAboReg.Kuendigung.Monatlich.Laufzeit", {
                                        "0": naechstesMoeglichesAboEndeDatum,
                                    })
                                }}</span
                            >
                            <br />
                        </template>
                        <template v-if="!['jaehrlich', 'monatlich'].includes(abonnement.turnus)">
                            {{ $t("Components.Selbstauskunft.Show.TabAboReg.Kuendigung.Unbekannt") }}
                            <br />
                        </template>
                        <template v-if="stopAboIsDisabled && !stopRequestWasSent">
                            <span data-qa="mein-abonnement-status-text">
                                <template v-if="istBeendetesAbo">
                                    <i18n path="Components.Selbstauskunft.Show.TabAboReg.WurdeBeendet" :tag="false">
                                        <span data-qa="mein-abonnement-ende-datum">{{stopDatum}}</span>
                                    </i18n>
                                </template>
                                <template v-if="istGekuendigtesAbo">
                                    <i18n path="Components.Selbstauskunft.Show.TabAboReg.WurdeGekuendigt" :tag="false">
                                        <span data-qa="mein-abonnement-ende-datum">{{stopDatum}}</span>
                                    </i18n>
                                </template>
                            </span>
                        </template>
                        <template v-else>
                            <crefo-ring-loading v-if="stopInProgress" class="abo-stop-animation" />
                            <crefo-error-message v-if="stopRequestWasSent" class="margin-around" type="success">
                                <span data-qa="abonnement-kuendigen-erfolgsmeldung">
                                    {{ $t("Components.Selbstauskunft.Show.TabAboReg.Kuendigen.Success1") }}<br />
                                </span>
                                <i18n
                                    v-if="stopDatum"
                                    path="Components.Selbstauskunft.Show.TabAboReg.Kuendigen.Success2"
                                    tag="span"
                                    data-qa="abonnement-kuendigen-ende-datum"
                                >
                                    {{ stopDatum }}.
                                </i18n>
                                <crefo-ring-loading v-if="!stopDatum" color="crefo-white" />
                            </crefo-error-message>
                            <crefo-error-message
                                v-if="stopRequestAjaxError"
                                class="margin-around"
                                :ajax-error="stopRequestAjaxError"
                                error-prefix="AboStop"
                            />
                        </template>
                        <template v-if="restartAboIsEnabled">
                            <button
                                class="btn btn-default btn-min btn-small"
                                data-qa="abonnement_reaktivieren_button"
                                @click.prevent="onClickAboReactivate"
                            >
                                {{ $t("Components.Selbstauskunft.Show.TabAboReg.Reaktivieren.Button") }}
                            </button>
                        </template>
                    </p>
                    <div class="h4 mb-small">
                        {{ $t("Components.Selbstauskunft.Show.TabAboReg.AGB") }}
                    </div>
                    <i18n path="Components.Selbstauskunft.Show.TabAboReg.AGB.Text" tag="p">
                        <dynamic-a-tag
                            href-template="{PUBLIC_PATH}assets/doc/AGB-Meine-Bonitaet_V1.4.3-{LANG}.pdf"
                            target="_blank"
                            data-qa="agb-link"
                        >
                            {{ $t("Components.Selbstauskunft.Show.TabAboReg.AGB.Text.Link") }}
                        </dynamic-a-tag>
                    </i18n>
                </div>
            </div>
            <div class="col-12 col-lg-6 header">
                <h1 class="marketing">
                    <span>
                        {{ $t("Components.Selbstauskunft.Show.TabAboReg.BlaBla") }}
                    </span>
                </h1>
                <div class="h3 mb-small mt-16">
                    {{ $t("Components.Selbstauskunft.Show.TabAboReg.BlaBla.Header") }}
                </div>
                <ul class="list-unordered list-checked">
                    <li>{{ $t("Components.Selbstauskunft.Show.TabAboReg.BlaBla.Reason1") }}</li>
                    <li>{{ $t("Components.Selbstauskunft.Show.TabAboReg.BlaBla.Reason2") }}</li>
                    <li>{{ $t("Components.Selbstauskunft.Show.TabAboReg.BlaBla.Reason3") }}</li>
                    <li>{{ $t("Components.Selbstauskunft.Show.TabAboReg.BlaBla.Reason4") }}</li>
                    <li>{{ $t("Components.Selbstauskunft.Show.TabAboReg.BlaBla.Reason5") }}</li>
                    <li>{{ $t("Components.Selbstauskunft.Show.TabAboReg.BlaBla.Reason6") }}</li>
                </ul>
            </div>
        </div>
        <modal-dialog
            icon="info"
            :title="$t('Abonnement.Kuendigen.Headline')"
            :cancel-label="$t('Abonnement.Kuendigen.Abbrechen')"
            :confirm-label="$t('Abonnement.Kuendigen.Bestaetigung')"
            :confirm-callback="stopAbonnement"
            :visible="showModal"
            @update:visible="showModal = $event"
        >
            <i18n path="Components.Selbstauskunft.Show.TabAboReg.Modal.WirklichKuendigen" tag="p">
                <span data-qa="abonnement-kuendigen-moegliches-ende-datum">{{ naechstesMoeglichesAboEndeDatum }}</span>
            </i18n>
            <p>
                {{
                    $t("Components.Selbstauskunft.Show.TabAboReg.Modal.VerlustHeader", [
                        naechstesMoeglichesAboEndeDatum
                    ])
                }}<br />
                {{ $t("Components.Selbstauskunft.Show.TabAboReg.Modal.Verlust1") }}<br />
                {{ $t("Components.Selbstauskunft.Show.TabAboReg.Modal.Verlust2") }}
            </p>
        </modal-dialog>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useAbonnementStore } from '@/stores/abonnement'
    import { parseIso8601Date } from "@/helper/date"
    import ModalDialog from "@/components/helper/modal-dialog"
    import CrefoErrorMessage from "@dm/crefo-error-message"
    import CrefoRingLoading from "@dm/crefo-ring-loading"
    import DynamicATag from "@/components/helper/dynamic-a-tag.vue"
    import TagTrim from "@/components/helper/tag-trim.vue"
    import WrapperWebtrekk from "@/helper/webtrekk-wrapper"

    export default {
        name: "SelbstauskunftActionTabAbonnementFuerKunden",
        components: { ModalDialog, CrefoErrorMessage, CrefoRingLoading, DynamicATag, TagTrim },
        props: {
            abonnement: {
                type: Object,
                required: true
            },
            stopInProgress: {
                type: Boolean,
                required: false,
                default: false
            },
            stopRequestWasSent: {
                type: Boolean,
                required: false,
                default: false
            },
            stopRequestAjaxError: {
                type: Object,
                required: false,
                default: null
            }
        },
        data () {
            return {
                showModal: false,
                stopAboIsDisabled: [ "GEKUENDIGT", "STORNIERT", "BEENDET" ].includes(this.abonnement.status),
                restartAboIsEnabled: [ "BEENDET" ].includes(this.abonnement.status)
            }
        },
        computed: {
            ...mapStores(useUserStore, useAbonnementStore),
            istBeendetesAbo () {
                return this.abonnement.status === 'BEENDET'
            },
            istGekuendigtesAbo () {
                return this.abonnement.status === 'GEKUENDIGT'
            },
            startDatum () {
                return this.abonnement.aboBeginn ? parseIso8601Date(this.abonnement.aboBeginn) : ""
            },
            stopDatum () {
                return this.abonnement.aboEnde ? parseIso8601Date(this.abonnement.aboEnde) : ""
            },
            naechstesMoeglichesAboEndeDatum () {
                return this.abonnement.naechstesMoeglichesAboEndeDatum
                    ? parseIso8601Date(this.abonnement.naechstesMoeglichesAboEndeDatum)
                    : this.$t("Components.Selbstauskunft.Show.TabAboReg.WurdeGekuendigt.naechstmoeglich")
            },
            betragNetto () {
                switch (this.abonnement.turnus) {
                    case "jaehrlich":
                        return "240,00"
                    case "monatlich":
                        return "25,00"
                    default:
                        return "???,??"
                }
            },
            betragBrutto () {
                switch (this.abonnement.turnus) {
                    case "jaehrlich":
                        return "285,60"
                    case "monatlich":
                        return "29,75"
                    default:
                        return "???,??"
                }
            },
            zahlungsanbieter () {
                switch (this.abonnement.zahlungsart) {
                    case "kreditkarte":
                        return this.$t("Components.Selbstauskunft.Show.TabAboReg.Zahlungsturnus.Kreditkarte")
                    case "paypal":
                        return this.$t("Components.Selbstauskunft.Show.TabAboReg.Zahlungsturnus.PayPal")
                    default:
                        return "???"
                }
            },
            stopAboShouldBeDisabled: function () {
                return this.stopAboIsDisabled || this.stopInProgress || this.stopRequestWasSent || this.showModal;
            },
            showUStBetragDE () {
                return this.abonnement.rechnungsadresse.land === "DE"
            }
        },
        methods: {
            onClickAboStop: function () {
                if (this.stopAboShouldBeDisabled === false) {
                    this.showModal = true
                }
            },
            onClickAboReactivate: function () {
                WrapperWebtrekk.trackAction("meine_bonitaet_frontend_action_reactivateAbonnement_for_digitales_mitglied")
                this.$router.push({ name: "selbstauskunft-zahlungsturnus" })
            },
            stopAbonnement: function () {
                this.showModal = false
                this.abonnementStore.stopAbonnementForDigitalesMitglied(this.abonnement.aboId)
                    .then(() => {
                        this.abonnementStore.updateAbonnements({
                            crid: this.userStore.user.cr_id,
                            crefonummer: this.userStore.crefonummer
                        })
                    })
                    .catch(() => {})
            }
        }
    }
</script>

<style scoped lang="less">
    .header {
        background-image: url("../../../assets/img/950x503-selbstauskunft.jpg");
        background-repeat: no-repeat;
        background-position: right top;
        background-size: 80%;
        min-height: 250px;

        .marketing {
            margin-top: 11%;
            font-size: 26px;
            max-width: 285px;

            span {
                box-decoration-break: clone;
            }
        }
    }

    .nw {
        white-space: nowrap;
    }

    .btn-min {
        margin: 5px 0 6px 0 !important;
    }

    .margin-around {
        margin: 10px 0 20px 0 !important;
    }

    .abo-stop-animation {
        width: auto !important;
        position: relative;
        top: 8px;
        left: 5px;
    }

    .mt-16 {
        margin-top: 16%;
    }

    .mt-10px {
        margin-top: 10px;
    }
</style>

<template>
    <div>
        <div class="row">
            <div class="col-12 col-lg-6 order-lg-2 header mb">
                <h1 class="marketing">
                    <span>{{ $t('Components.Selbstauskunft.Show.TabAboMitglied.BlaBla') }}</span>
                </h1>
            </div>
            <div class="col-12 col-lg-6 order-lg-1">
                <div class="h3">
                    {{ $t('Components.Selbstauskunft.Show.TabAboMitglied.BlaBla.Header') }}
                </div>
                <ul class="list-unordered list-checked">
                    <li>{{ $t('Components.Selbstauskunft.Show.TabAboMitglied.BlaBla.Reason1') }}</li>
                    <li>{{ $t('Components.Selbstauskunft.Show.TabAboMitglied.BlaBla.Reason2') }}</li>
                    <li>{{ $t('Components.Selbstauskunft.Show.TabAboMitglied.BlaBla.Reason3') }}</li>
                    <li>{{ $t('Components.Selbstauskunft.Show.TabAboMitglied.BlaBla.Reason4') }}</li>
                    <li>{{ $t('Components.Selbstauskunft.Show.TabAboMitglied.BlaBla.Reason5') }}</li>
                    <li>{{ $t('Components.Selbstauskunft.Show.TabAboMitglied.BlaBla.Reason6') }}</li>
                </ul>
                <div class="row align-items-center">
                    <div class="col-auto">
                        <button
                            class="btn btn-default mb-not-lg"
                            @click.prevent="onClickAboStop"
                            data-qa="abonnement_kuendigen_button"
                            :disabled="stopAboShouldBeDisabled"
                        >
                            {{ $t('Components.Selbstauskunft.Show.TabAboMitglied.Kuendigen.Button') }}
                        </button>
                    </div>
                    <div class="col-auto">
                        <crefo-ring-loading v-if="stopInProgress" />
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="stopRequestAjaxError"
            class="row"
        >
            <div class="col-12">
                <crefo-error-message
                    class="mb"
                    :ajax-error="stopRequestAjaxError"
                    error-prefix="AboStop"
                />
            </div>
        </div>
        <div
            v-if="stopRequestWasSent"
            class="row"
        >
            <div class="col-12">
                <div class="crefo-ui-alert success">
                    <i18n
                        path="Components.Selbstauskunft.Show.TabAboMitglied.Kuendigen.Success1"
                        tag="p"
                        data-qa="abonnement-kuendigen-erfolgsmeldung"
                    >
                        <br />
                    </i18n>
                    <p data-qa="abonnement-kuendigen-erfolgsmeldung-kontakt">
                        <a href="mailto:call-ds@verband.creditreform.de">
                            {{ $t('Components.Selbstauskunft.Show.TabAboMitglied.Kuendigen.Success2') }}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useAbonnementStore } from '@/stores/abonnement'
    import CrefoErrorMessage from '@dm/crefo-error-message'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    export default {
        name: 'SelbstauskunftActionTabFuerMitglieder',
        components: { CrefoErrorMessage, CrefoRingLoading },
        props: {
            abonnement: {
                type: Object,
                required: true
            },
            stopInProgress: {
                type: Boolean,
                required: false,
                default: false
            },
            stopRequestWasSent: {
                type: Boolean,
                required: false,
                default: false
            },
            stopRequestAjaxError: {
                type: Object,
                required: false,
                default: null
            }
        },
        data () {
            return {
                stopAboIsDisabled: [ 'GEKUENDIGT', 'STORNIERT', 'BEENDET' ].includes(this.abonnement.status)
            }
        },
        computed: {
            ...mapStores(useAbonnementStore),
            stopAboShouldBeDisabled: function () {
                return this.stopAboIsDisabled || this.stopInProgress || this.stopRequestWasSent
            }
        },
        methods: {
            onClickAboStop: function () {
                if (this.stopAboShouldBeDisabled === false) {
                    this.abonnementStore.stopAbonnementForMitglied(this.abonnement.aboId)
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .header {
        background-image: url('../../../assets/img/950x503-selbstauskunft.jpg');
        background-repeat: no-repeat;
        background-position: right top;
        background-size: 80%;
        min-height: 250px;

        .marketing {
            margin-top: 11%;
            font-size: 26px;
            max-width: 285px;
        }
    }

    .mb-not-lg {
        margin-bottom: 12px !important;

        @media only screen and (max-width: 1199px) {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }

        @media only screen and (max-width: 767px) {
            margin-top: 3px !important;
            margin-bottom: 0 !important;
        }
    }

    .crefo-ui-alert {
        a {
            color: #ffffff;
            text-decoration: underline;
        }
    }
</style>

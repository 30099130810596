<template>
    <div v-if="isBeendet || isGekuendigt">
        <div
            v-if="isBeendet"
            class="crefo-ui-alert error"
            data-qa="abonnement-abgelaufen"
        >
            <span data-qa="abonnement-abgelaufen-datum">
                {{ $t('Abonnement.Status.Beendet', { aboEnde: aboEndeFormatted }) }}
            </span>
            <span
                v-if="userStore.isMemberUser"
                v-html="$t('Abonnement.Status.Beendet.ContactUs', { contactLinkBeendet })"
            />
            <i18n
                v-else
                path="Components.Abonnement.Status.RegUserAboBeendetNeuBeantragen"
                tag="span"
            >
                <router-link
                    :to="{ name: 'selbstauskunft-zahlungsturnus' }"
                    class="aboStatusContactLink"
                >{{ $t('Components.Abonnement.Status.RegUserAboBeendetNeuBeantragen.LinkText') }}</router-link>
            </i18n>
        </div>
        <div
            v-if="isGekuendigt"
            class="crefo-ui-alert warning"
            data-qa="abonnement-gekuendigt"
        >
            <span data-qa="abonnement-gekuendigt-datum">
                {{ $t('Abonnement.Status.Gekuendigt', { aboEnde: aboEndeFormatted }) }}
            </span>
            <span
                v-if="userStore.isMemberUser"
                v-html="$t('Abonnement.Status.Gekuendigt.ContactUs', { contactLinkGekuendigt })"
            />
            <span v-else>
                {{ $t('Components.Abonnement.Status.RegUserAboGekuendigtNeuBeantragen') }}
            </span>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useAbonnementStore } from '@/stores/abonnement'
    import * as dateHelper from '@/helper/date'
    export default {
        name: 'AbonnementStatus',
        computed: {
            ...mapStores(useUserStore, useAbonnementStore),
            contactLinkBeendet () {
                return '<a href="' + this.$t('URL.ContactForm') + '" class="aboStatusContactLink" data-qa="abonnement-abgelaufen-kontakt" >'
                    + this.$t('Abonnement.Status.Beendet.ContactUs.LinkText') + '</a>'
            },
            contactLinkGekuendigt () {
                return '<a href="' + this.$t('URL.ContactForm') + '" class="aboStatusContactLink" data-qa="abonnement-gekuendigt-kontakt" >'
                    + this.$t('Abonnement.Status.Gekuendigt.ContactUs.LinkText') + '</a>'
            },
            isBeendet () {
                return this.abonnementStore.getAboStatus === 'BEENDET'
            },
            isGekuendigt () {
                return this.abonnementStore.getAboStatus === 'GEKUENDIGT' || this.abonnementStore.getAboStatus === 'STORNIERT'
            },
            aboEndeFormatted () {
                if (this.isBeendet || this.isGekuendigt) {
                    return dateHelper.parseIso8601Date(this.abonnementStore.getAboEnde)
                } else {
                    return ''
                }
            }
        }
    }
</script>

<style scoped>
    h3 {
        margin-bottom: 0;
        font-size: 15px;
        line-height: 22px;
    }
</style>

<style lang="less">
    .crefo-ui-alert a.aboStatusContactLink {
        color: #ffffff;
        text-decoration: underline;

        &:hover {
            color: #ffffff;
            text-decoration: none;
        }
    }
</style>

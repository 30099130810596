<template>
    <div class="row">
        <div class="col-12">
            <crefo-ring-loading
                v-if="isLoading"
                additional-classes="mt"
            />
            <verifikation-mitglied
                v-if="userStore.isMemberUser && userStore.isUserStateDetermined"
                :verified="verified"
            />
            <verifikation-registrierter-nutzer
                v-if="!userStore.isMemberUser && userStore.isUserStateDetermined"
                :verified="verified"
            />
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import VerifikationMitglied from '../components/selbstauskunft/order/verifikation-mitglied'
    import VerifikationRegistrierterNutzer from '../components/selbstauskunft/order/verifikation-registrierter-nutzer'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    export default {
        name: 'SelbstauskunftVerifikation',
        components: { VerifikationMitglied, VerifikationRegistrierterNutzer, CrefoRingLoading },
        props: {
            verified: {
                type: Boolean,
                default: null
            }
        },
        computed: {
            ...mapStores(useUserStore),
            isLoading: function () {
                return this.userStore.isUserStateDetermined !== true
            }
        }
    }
</script>

import HelperUser from '@dm/helper-user'
import pinia from '@/stores'
import { useUserStore } from '@/stores/user'
import { useAbonnementStore } from '@/stores/abonnement'
import { useKurzprofilStore } from '@/stores/kurzprofil'

const stepsProcessed = {
    crefonummer: false,
    kurzprofil: false,
    abonnements: false,
    rechnungsadresse: false
}

/**
 * This will select a matching abonnement for the current user - if any.
 *
 * Following conditions must be met:
 *  - User is Vertretungsberechtigt for the specific abonnement
 *  - Active Crefonummer (Unternehmensnavigation) matches the abonnement
 *
 * Finally it will commit userModule/ON_SET_USER_STATE_DETERMINED (even with no
 * selected abonnement when nothing matches)
 *
 * @param {boolean} [referrerIsCrefoPay]
 * @param {boolean} [referrerIsCrefoTrust]
 */
export const checkUserStateForLoggedInUser = (referrerIsCrefoPay = false, referrerIsCrefoTrust = false) => {
    const userStore = useUserStore(pinia)
    const abonnementStore = useAbonnementStore(pinia)

    // Set all processing steps to false to be able to run multiple times
    Object.keys(stepsProcessed).forEach(key => stepsProcessed[key] = false)

    // Reset state user determined
    userStore.ON_SET_USER_STATE_DETERMINED(false)

    return new Promise(resolve => {
        // User is returned from CrefoPay or CrefoTrust - was in Checkout before
        if (referrerIsCrefoPay || referrerIsCrefoTrust) {
            abonnementStore.ON_CHANGE_CHECKOUT_STARTED(true)
            abonnementStore.getRechnungsadresse({ crid: userStore.user.cr_id })
                .then(() => checkProcessedStep('rechnungsadresse', resolve))
        } else {
            checkProcessedStep('rechnungsadresse', resolve)
        }

        // Call actions to fetch abonnements and current crefonumber
        userStore.setCrefonummerForCurrentUser()
            .then(() => checkProcessedStep('crefonummer', resolve))

        abonnementStore.getAbonnements({ crid: userStore.user.cr_id })
            .then(() => checkProcessedStep('abonnements', resolve))
    })
}

export const checkUserStateForNotLoggedInUser = () => {
    const userStore = useUserStore(pinia)
    const kurzprofilStore = useKurzprofilStore(pinia)
    const abonnementStore = useAbonnementStore(pinia)

    return new Promise(resolve => {
        userStore.ON_SET_USER_STATE_DETERMINED(false)
        userStore.ON_SET_CREFONUMMER_ERROR(null)
        userStore.ON_SET_VERTRETUNGSBERECHTIGT(false)
        kurzprofilStore.GET_KURZPROFIL_FAILED(null)
        abonnementStore.ON_SELECT_ABONNEMENT(null)
        abonnementStore.GET_ABONNEMENTS_FAILED(null)
        userStore.ON_SET_USER_STATE_DETERMINED(true)
        resolve()
    })
}

/**
 * Callback function that is called for every step/action/mutation.
 *
 * @param {string} step
 * @param {function} resolve
 */
function checkProcessedStep (step, resolve) {
    const userStore = useUserStore(pinia)
    const kurzprofilStore = useKurzprofilStore(pinia)

    switch (step) {
        case 'crefonummer':
            stepsProcessed.crefonummer = true
            if (userStore.crefonummer !== null) {
                kurzprofilStore.getKurzprofil(userStore.crefonummer)
                    .then(() => {})
                    .catch(error => {})
                    .finally(() => {
                        checkProcessedStep('kurzprofil', resolve)
                    })
            }
            break
        case 'crefonummer-fallback':
            if (userStore.crefonummer !== null) {
                checkProcessedStep('crefonummer', resolve) // Trigger fetch kurzprofil
            } else {
                checkProcessedStep('kurzprofil', resolve) // Mark kurzprofil as done
            }
            break
        case 'kurzprofil':
            stepsProcessed.kurzprofil = true
            break
        case 'abonnements':
            stepsProcessed.abonnements = true
            break
        case 'rechnungsadresse':
            stepsProcessed.rechnungsadresse = true
            break
    }

    // Alle Daten ermittelt?
    if (!Object.values(stepsProcessed).includes(false)) {
        setDeterminedUserState(resolve)
    } else {
        // Fallback für Crefonummer falls notwendig
        if (stepsProcessed.crefonummer && stepsProcessed.abonnements) {
            if (userStore.crefonummer === null && stepsProcessed.kurzprofil === false) {
                setCrefonummerByFirstAbonnementIfNotAlreadySet()
                    .then(() => checkProcessedStep('crefonummer-fallback', resolve))
            }
        }
    }
}

/**
 * Tries to find the users crefonummer.
 *
 * If both calls to /cbra/members and /api/selfcare/users/me dont deliver a crefonummer, but user has abonnements:
 * Then it falls back to the first abonnement with a crefonummer (abonnements where user is VB are preferred)
 */
function setCrefonummerByFirstAbonnementIfNotAlreadySet () {
    const userStore = useUserStore(pinia)
    const abonnementStore = useAbonnementStore(pinia)

    return new Promise(resolve => {
        if (abonnementStore.abonnements.length > 0) { // User has no abonnements
            let abonnement = null

            if (abonnementStore.abonnementsVertretungsberechtigt.length) { // Choose Crefonummer from VB abos
                abonnement = abonnementStore.abonnementsVertretungsberechtigt
                    .find(abonnement => typeof abonnement.crefonummer === 'string' && abonnement.crefonummer.length > 0)
            }
            if (abonnementStore.abonnementsNotVertretungsberechtigt.length && abonnement === null) { // Fallback to abos without VB
                abonnement = abonnementStore.abonnementsNotVertretungsberechtigt
                    .find(abonnement => typeof abonnement.crefonummer === 'string' && abonnement.crefonummer.length > 0)
            }
            if (abonnement) {
                userStore.ON_SET_CREFONUMMER_SUCCESS(abonnement.crefonummer)
            }
        }

        resolve()
    })
}

/**
 * Selects the abonnement.
 */
function setDeterminedUserState (resolve) {
    const userStore = useUserStore(pinia)
    const abonnementStore = useAbonnementStore(pinia)

    // Set state if user is vertretungsberechtigt for current active crefonummer
    userStore.ON_SET_VERTRETUNGSBERECHTIGT(HelperUser.hasRoleVertretungsberechtigung(userStore.crefonummer))

    // Find first matching abonnement where user is VB and crefonummer matches
    let selectedAbonnement = abonnementStore.abonnementsVertretungsberechtigt
        .find(abonnement => abonnement.crefonummer === userStore.crefonummer)

    // If no matching abo found, find first matching abonnement where user is not VB and crefonummer matches
    if (!selectedAbonnement) {
        selectedAbonnement = abonnementStore.abonnementsNotVertretungsberechtigt
            .find(abonnement => abonnement.crefonummer === userStore.crefonummer)
    }

    if (selectedAbonnement) {
        abonnementStore.ON_SELECT_ABONNEMENT(selectedAbonnement)
        if (selectedAbonnement.turnus) {
            switch (selectedAbonnement.turnus.toLowerCase()) {
                case 'monatlich':
                    abonnementStore.ON_SELECT_PAYMENT_CYCLE('MONATLICH')
                    break
                case 'jaehrlich':
                    abonnementStore.ON_SELECT_PAYMENT_CYCLE('JAEHRLICH')
                    break
            }
        }
    } else {
        abonnementStore.ON_SELECT_ABONNEMENT(null)
    }

    userStore.ON_SET_USER_STATE_DETERMINED(true)
    resolve()
}

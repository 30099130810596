import dayjs from 'dayjs'
import * as de from 'dayjs/locale/de'
import * as de_AT from 'dayjs/locale/de-at'
import * as en_GB from 'dayjs/locale/en-gb'
import * as fr from 'dayjs/locale/fr'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import pinia from '@/stores'
import { useUserStore } from '@/stores/user'

/**
 * TypeDef SpringBootDate
 *
 * @typedef {Object} SpringBootDate
 * @type {Object}
 * @property {number} year
 * @property {number} dayOfYear
 * @property {string} month
 * @property {number} monthValue
 * @property {string} dayOfWeek
 * @property {number} dayOfMonth
 * @property {number} hour
 * @property {number} minute
 * @property {number} second
 * @property {number} nano
 * @property {boolean} leapYear
 * @property {string} era
 * @property {object} chronology
 */

/**
 * Check if is valid spring boot date.
 *
 * @param {SpringBootDate} obj
 * @return {boolean}
 */
export const isValidIso8601SpringBootDate = (obj) => {
    if (obj && typeof obj.year === 'number' && typeof obj.monthValue === 'number' && typeof obj.dayOfMonth === 'number') {
        return true
    }

    return false
}

/**
 * Check if is valid spring boot time.
 *
 * @param {SpringBootDate} obj
 * @return {boolean}
 */
export const isValidIso8601SpringBootTime = (obj) => {
    if (obj && typeof obj.hour === 'number' && typeof obj.minute === 'number' && typeof obj.second === 'number') {
        return true
    }

    return false
}

/**
 * Get ISO 8601 date string from spring boot dateTime object.
 *
 * @param {SpringBootDate} obj
 * @return {string}
 */
export const getIso8601DateStringFromSpringBootDate = (obj) => {
    if (isValidIso8601SpringBootDate(obj)) {
        return `${obj.year}-${('0' + obj.monthValue).slice(-2)}-${('0' + obj.dayOfMonth).slice(-2)}`
    } else {
        return null
    }
}

/**
 * Get ISO 8601 time string from spring boot dateTime object.
 *
 * @param {SpringBootDate} obj
 * @return {string}
 */
export const getIso8601TimeStringFromSpringBootTime = (obj) => {
    if (isValidIso8601SpringBootTime(obj)) {
        return `${('0' + obj.hour).slice(-2)}:${('0' + obj.minute).slice(-2)}:${('0' + obj.second).slice(-2)}`
    } else {
        //return '00:00:00'
        return null
    }
}

/**
 * Get date formatted from spring boot dateTime object.
 *
 * @param {SpringBootDate} obj
 * @param {string} format
 * @return {string}
 */
export const parseSpringBootDate = (obj, format) => {
    const userStore = useUserStore(pinia)

    let result = ''
    const date = getIso8601DateStringFromSpringBootDate(obj)
    const time = getIso8601TimeStringFromSpringBootTime(obj)

    dayjs.extend(localizedFormat)
    dayjs.locale(userStore.dateLocale)

    if (typeof format !== 'string' || format.length === 0) {
        if (date) {
            result = dayjs(date, dayjs.ISO_8601).format('L')
        }
    } else if (!isValidIso8601SpringBootTime(obj)) {
        if (date) {
            result = dayjs(date, dayjs.ISO_8601).format(format)
        }
    } else {
        if (date && time) {
            result = dayjs(date + 'T' + time, 'YYYY-MM-DDTHH:mm:ss').format(format)
        }
    }
    return result
}

export const parseIso8601Date = (dateString, format) => {
    const userStore = useUserStore(pinia)

    if (typeof dateString !== 'string' || dateString.length === 0) {
        return ''
    }

    dayjs.extend(localizedFormat)
    dayjs.locale(userStore.dateLocale)

    if (typeof format !== 'string' || format.length === 0) {
        return dayjs(dateString, dayjs.ISO_8601).format('L')
    } else {
        return dayjs(dateString, dayjs.ISO_8601).format(format)
    }
}

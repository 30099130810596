<template>
    <div
        v-if="isLoading"
        class="container pt mb-large"
    >
        <crefo-breadcrumbs>
            <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
            <li>
                <router-link :to="{ name: 'index' }">
                    {{ $t('Appname') }}
                </router-link>
            </li>
        </crefo-breadcrumbs>
        <crefo-ring-loading additional-classes="mt" />
    </div>
    <div v-else>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
                <li>
                    <router-link :to="{ name: 'index' }">
                        {{ $t('Appname') }}
                    </router-link>
                </li>
            </crefo-breadcrumbs>
        </div>
        <div class="row">
            <div class="col-12 bg-brand-selbstauskunft-slider mb">
                <img
                    src="@/assets/img/selbstauskunft-slider.png"
                    alt=""
                >
            </div>
        </div>
        <div class="container">
            <div class="row mb">
                <div class="col-12">
                    <h1>{{ $t('Components.Selbstauskunft.Order.ContactAusland.Header') }}</h1>
                </div>
                <div class="col-12 col-lg-7 col-xl-8">
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.ContactAusland.Description') }}
                    </p>
                    <h3 class="mb-small pt">
                        {{ $t('Components.Selbstauskunft.Order.ContactAusland.Vorteile') }}
                    </h3>
                    <ul class="list-unordered list-checked ml">
                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.Vorteil1') }}</li>
                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.Vorteil2') }}</li>
                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.Vorteil3') }}</li>
                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.Vorteil4') }}</li>
                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.Vorteil5') }}</li>
                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.Vorteil6') }}</li>
                    </ul>
                    <h3 class="mb-small pt">
                        {{ $t('Components.Selbstauskunft.Order.ContactAusland.FokusHeader') }}
                    </h3>
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.ContactAusland.FokusPara1')}}
                    </p>
                    <i18n
                        path="Components.Selbstauskunft.Order.ContactAusland.FokusPara2"
                        tag="p"
                    >
                        <a
                            href="https://www.creditreform.de/selbstauskunft-zu-meinem-unternehmen"
                            target="_blank"
                        >{{ $t('Components.Selbstauskunft.Order.ContactAusland.FokusPara2.LinkText') }}</a>
                    </i18n>
                    <i18n
                        path="Components.Selbstauskunft.Order.ContactAusland.FokusPara3"
                        tag="p"
                    >
                        <router-link :to="`/portal/ubo/aktivierung?crefonummer=${userStore.crefonummer ? userStore.crefonummer : ''}`">
                            {{ $t('Components.Selbstauskunft.Order.ContactAusland.FokusPara3.LinkText') }}
                        </router-link>
                    </i18n>
                    <h3 class="mb-small pt">
                        {{ $t('Components.Selbstauskunft.Order.ContactAusland.BlickHeader') }}
                    </h3>
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.ContactAusland.BlickPara1') }}
                    </p>
                </div>
                <div class="col-12 col-lg-5 col-xl-4">
                    <div class="box-shadow-xy box-teaser">
                        <img
                            src="@/assets/img/bild_tablet-auskunft.jpg"
                            alt=""
                        >
                        <div class="box-spacing">
                            <h3 class="mb-small">
                                {{ $t('Components.Selbstauskunft.Order.ContactAusland.TeaserHeader') }}
                            </h3>
                            <p><slot /></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="bg-brand bg-brand-light-grey box-spacing">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Header') }}</h1>
                            <h2>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.SubHeader') }}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-7 col-xl-8">
                            <div class="box-teaser">
                                <img
                                    src="@/assets/img/selbstauskunft-snippet.png"
                                    alt=""
                                >
                            </div>
                        </div>
                        <div class="col-12 col-lg-5 col-xl-4">
                            <div class="row">
                                <div class="col-12">
                                    <h2>
                                        {{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason1Header') }}
                                    </h2>
                                    <ul class="list-unordered list-checked">
                                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason1Text1') }}</li>
                                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason1Text2') }}</li>
                                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason1Text3') }}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="h3">
                                        <p>
                                            <a
                                                href="https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/meine-bonitaet#c30202"
                                                class="btn btn-default"
                                                role="button"
                                                data-qa="forward-button"
                                            >
                                                <span>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason1Cta') }}</span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-brand box-spacing">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-5 col-xl-4">
                            <div class="row">
                                <div class="col-12">
                                    <h2>
                                        {{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason2Header') }}
                                    </h2>
                                    <ul class="list-unordered list-checked">
                                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason2Text1') }}</li>
                                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason2Text2') }}</li>
                                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason2Text3') }}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="h3">
                                        <p>
                                            <a
                                                href="https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/meine-bonitaet#c30202"
                                                class="btn btn-default"
                                                role="button"
                                                data-qa="forward-button"
                                            >
                                                <span>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason2Cta') }}</span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-7 col-xl-8">
                            <div class="box-teaser">
                                <img
                                    src="@/assets/img/monitoring-snippet.png"
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-brand bg-brand-light-grey box-spacing">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-7 col-xl-8">
                            <div class="box-teaser">
                                <img
                                    src="@/assets/img/daten-snippet.png"
                                    alt=""
                                >
                            </div>
                        </div>
                        <div class="col-12 col-lg-5 col-xl-4">
                            <div class="row">
                                <div class="col-12">
                                    <h2>
                                        {{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason3Header') }}
                                    </h2>
                                    <ul class="list-unordered list-checked">
                                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason3Text1') }}</li>
                                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason3Text2') }}</li>
                                        <li>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason3Text3') }}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="h3">
                                        <p>
                                            <a
                                                href="https://www.creditreform.de/loesungen/bonitaet-risikobewertung/bonitaet-unternehmen/meine-bonitaet#c30202"
                                                class="btn btn-default"
                                                role="button"
                                                data-qa="forward-button"
                                            >
                                                <span>{{ $t('Components.Selbstauskunft.Order.ContactAusland.ImageTeaser.Reason3Cta') }}</span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-brand bg-brand-light-blue box-spacing">
                <div class="container">
                    <div class="row row-reasons">
                        <div class="col-12">
                            <h3 class="mb-large">
                                {{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.Header') }}
                            </h3>
                        </div>
                        <div class="col-12 col-lg-4 mb text-center">
                            <i
                                class="crefo-brand-icon icon-user icon-color-white mb-small"
                                aria-hidden="true"
                            />
                            <p class="mb-small">
                                <strong>{{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V1FetterText') }}</strong>
                            </p>
                            <p>
                                {{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V1NormalerText') }}
                            </p>
                        </div>
                        <div class="col-12 col-lg-4 mb text-center">
                            <i
                                class="crefo-brand-icon icon-clock icon-color-white mb-small"
                                aria-hidden="true"
                            />
                            <p class="mb-small">
                                <strong>{{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V2FetterText') }}</strong>
                            </p>
                            <p>
                                {{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V2NormalerText') }}
                            </p>
                        </div>
                        <div class="col-12 col-lg-4 mb text-center">
                            <i
                                class="crefo-brand-icon icon-check-circle icon-color-white mb-small"
                                aria-hidden="true"
                            />
                            <p class="mb-small">
                                <strong>{{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V3FetterText') }}</strong>
                            </p>
                            <p>
                                {{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V3NormalerText') }}
                            </p>
                        </div>
                        <div class="col-12 col-lg-4 mb text-center">
                            <i
                                class="crefo-brand-icon icon-clock icon-color-white mb-small"
                                aria-hidden="true"
                            />
                            <p class="mb-small">
                                <strong>{{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V4FetterText') }}</strong>
                            </p>
                            <p>
                                {{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V4NormalerText') }}
                            </p>
                        </div>
                        <div class="col-12 col-lg-4 mb text-center">
                            <i
                                class="crefo-brand-icon icon-user icon-color-white mb-small"
                                aria-hidden="true"
                            />
                            <p class="mb-small">
                                <strong>{{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V5FetterText') }}</strong>
                            </p>
                            <p>
                                {{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V5NormalerText') }}
                            </p>
                        </div>
                        <div class="col-12 col-lg-4 mb text-center">
                            <i
                                class="crefo-brand-icon icon-check-circle icon-color-white mb-small"
                                aria-hidden="true"
                            />
                            <p class="mb-small">
                                <strong>{{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V6FetterText') }}</strong>
                            </p>
                            <p>
                                {{ $t('Components.Selbstauskunft.Order.ContactAusland.IhreVorteile.V6NormalerText') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import CrefoBreadcrumbs from '../../helper/crefo-breadcrumbs'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    export default {
        name: 'SelbstauskunftOrderContactAusland',
        components: {
            CrefoBreadcrumbs,
            CrefoRingLoading
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore),
            isLoading: function () {
                return this.kurzprofilStore.kurzprofilFetching !== false
            }
        }
    }
</script>

<style scoped lang="less">
    .bg-brand {
        &.bg-brand-light-blue {
            margin-bottom: -60px;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .row-reasons {
            h3 {
                margin-top: 0;
                padding: 15px 20px;
                color: #ffffff;
                background-color: #009ee2;
                text-transform: uppercase;
                display: inline-block;
            }

            .text-center {
                text-align: center;
            }

            i {
                display: block;
                font-size: 92px;
            }

            h2, p, span {
                line-height: 1.7;
                color: #ffffff;
            }
        }
    }
</style>

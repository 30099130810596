<template>
    <validation-provider
        v-slot="{ invalid, dirty }"
        :rules="{ required: { allowFalse: false } }"
    >
        <div class="crefo-input">
            <div
                class="input-group"
                :class="{ 'has-error': invalid && (dirty || abonnementSubmittingTried) }"
            >
                <div class="checkbox-group">
                    <div class="input-wrapper">
                        <div class="crefo-checkbox">
                            <input
                                id="payment-agb"
                                v-model="value.agbCheckboxChecked"
                                type="checkbox"
                                :disabled="abonnementSubmitting || abonnementSubmitted"
                                name="paymentAGB"
                                data-qa="bestaetigung-agb"
                            >
                            <i18n
                                path="Components.Selbstauskunft.Order.Partials.AgbDigimon.Text"
                                tag="label"
                                for="payment-agb"
                                data-qa="bestaetigung-agb-label"
                            >
                                <download-sample filename="AGB-Meine-Bonitaet_V1.4.3" download="agb" />
                            </i18n>
                        </div>
                    </div>
                </div>
                <div
                    v-if="invalid && (dirty || abonnementSubmittingTried)"
                    class="error-message pt-small"
                    data-qa="bestaetigung-agb-fehler"
                >
                    {{ $t('Components.Selbstauskunft.Order.Partials.AgbDigimon.ErrorText') }}
                </div>
            </div>
        </div>
    </validation-provider>
</template>

<script>
    import { ValidationProvider, extend } from 'vee-validate'
    import { required } from 'vee-validate/dist/rules'
    import DownloadSample from '@/components/helper/download-sample.vue'

    extend('required', required)
    export default {
        name: 'AgbDigimon',
        components: { ValidationProvider, DownloadSample },
        props: {
            value: {
                type: Object,
                default: true
            },
            abonnementSubmittingTried: {
                type: Boolean,
                default: false
            },
            abonnementSubmitting: {
                type: Boolean,
                default: false
            },
            abonnementSubmitted: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                publicPath: process.env.BASE_URL || ''
            }
        },
        watch: {
            value () {
                this.$emit('input', this.value)
            }
        }
    }
</script>

<style scoped lang="less">
    .crefo-input {
        .has-error {
            .crefo-checkbox {
                label {
                    color: #e05469;
                    &:before {
                        border-color: #e05469;
                    }
                }
            }
            .error-message {
                color: #e05469;
            }
        }
    }
</style>

import axios from 'axios'
import SparkMD5 from 'spark-md5'
import * as memoryCache from '@/helper/memory-cache'

/**
 * Generate a cache identifier based on given url.
 *
 * @param {string} url
 * @param {object} [config]
 * @return {string}
 */
function getGeneratedCacheIdentifier(url, config) {
    let identifier = String(url)

    try {
        if (config) {
            if (config.url) {
                identifier += JSON.stringify(config.url)
            }
            if (config.method) {
                identifier += JSON.stringify(config.method)
            }
            if (config.baseURL) {
                identifier += JSON.stringify(config.baseURL)
            }
            if (config.headers) {
                identifier += JSON.stringify(config.headers)
            }
            if (config.params) {
                identifier += JSON.stringify(config.params)
            }
            if (config.data) {
                identifier += JSON.stringify(config.data)
            }
            if (config.responseType) {
                identifier += JSON.stringify(config.responseType)
            }
        }
    } catch (e) {}

    return SparkMD5.hash(identifier)
}

/**
 * Generate cache configuration based on axios config.
 *
 * Sets 'cache' entry to null in config.cache if exists.
 *
 * @param {string} url
 * @param {object} [config]
 * @return {object}
 */
function getCacheConfiguration(url, config) {
    const cache = {
        ttl: null,
        identifier: null
    }

    const actForMemberId = sessionStorage.getItem('actForMemberId')

    if (config && config.cache && actForMemberId === null) {
        if (typeof config.cache.ttl === 'number' && config.cache.ttl > 0) {
            cache.ttl = config.cache.ttl
        }
        if (typeof config.cache.identifier === 'string' && config.cache.identifier.length) {
            cache.identifier = config.cache.identifier
        } else {
            cache.identifier = getGeneratedCacheIdentifier(url, config)
        }
        config.cache = null
    }

    return cache
}

/**
 * Executes an axios request and caches the result.
 *
 * @param {string} method
 * @param {object} cache
 * @param {string} url
 * @param {object} [config]
 * @return {Promise<AxiosResponse<T>>}
 */
function executeAxiosRequest(method, cache, url, config) {
    return new Promise((resolve, reject) => {
        axios[method](url, config)
            .then((response) => {
                if (response) {
                    if (cache.identifier === null) {
                        cache.identifier = getGeneratedCacheIdentifier(url, config)
                    }
                    memoryCache.set(cache.identifier, response, cache.ttl)
                }
                resolve(response)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

/**
 * Call Axios.Get and return current or cached result.
 *
 * @param {string} url
 * @param {object} [config]
 * @return {Promise<AxiosResponse<T>>}
 */
export const axiosGetCached = (url, config) => {
    const cache = getCacheConfiguration(url, config)

    if (cache.ttl > 0) {
        const cachedResult = memoryCache.get(cache.identifier)
        if (cachedResult !== null) {
            return Promise.resolve(cachedResult)
        }
    }

    return executeAxiosRequest('get', cache, url, config)
}

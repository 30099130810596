import { defineStore } from 'pinia'
import * as api from '@/services/index.js'
import HelperUser from '@dm/helper-user'
import { CrefopayHelper } from '@/helper/crefopay-helper'
import WrapperWebtrekk from '@/helper/webtrekk-wrapper'

export const useAbonnementStore = defineStore(
    'abonnement',
    {
        state: () => ({
            ajaxError: null,
            crefoPayErrorCode: null,
            abonnementsFetching: null,
            abonnementsAvailable: null,
            abonnements: [],
            abonnementsVertretungsberechtigt: [],
            abonnementsNotVertretungsberechtigt: [],
            selectedAbonnement: null,
            selectedAbonnementStopping: false,
            selectedAbonnementStopped: false,
            selectedAbonnementStopAjaxError: null,
            acceptTermOfService: false,
            checkoutStarted: false,
            paymentCycle: 'MONATLICH',
            rechnungsadresseFetching: null,
            rechnungsadresseAvailable: false,
            reportsprache: null,
            rechnungsadresse: {
                crid: null,
                name: null,
                strasse: null,
                hausnummer: null,
                plz: null,
                ort: null,
                land: null,
                email: null,
                umsatzsteuerId: null,
                kommunikationssprache: null
            }
        }),
        getters: {
            /**
             * Get specific abonnement by id, where user is VB.
             *
             * @param {AbonnementState} state
             * @return {function(string): ?Abonnement}
             */
            getAbonnementVertretungsberechtigtById (state) {
                return aboId => {
                    return state.abonnementsVertretungsberechtigt.find(abonnement => abonnement.aboId === aboId)
                }
            },
            /**
             * Get specific abonnement by id, where user is VB.
             *
             * @param {AbonnementState} state
             */
            getKommunikationssprache (state) {
                let result = null
                if (state.rechnungsadresse) {
                    if (state.rechnungsadresse.kommunikationssprache) {
                        result = state.rechnungsadresse.kommunikationssprache
                    }
                }
                return result;
            },
            /**
             * Get AboEnde date for selected abonnement.
             *
             * @param {AbonnementState} state
             * @return {?SpringBootDate}
             */
            getAboEnde (state) {
                if (state.selectedAbonnement && state.selectedAbonnement.aboEnde) {
                    return state.selectedAbonnement.aboEnde
                }

                return null
            },
            /**
             * Get AboStatus for selected abonnement.
             *
             * @param {AbonnementState} state
             * @return {?string}
             */
            getAboStatus (state) {
                if (state.selectedAbonnement && state.selectedAbonnement.status) {
                    return state.selectedAbonnement.status
                }

                return null
            },
            /**
             * Is selected abonnement gesperrt.
             *
             * @param {AbonnementState} state
             * @return {boolean}
             */
            isAboGesperrt (state) {
                if (state.selectedAbonnement && state.selectedAbonnement.gesperrt === true) {
                    return true
                }

                return false
            },
            /**
             * Get specific abonnement by id, where user is VB.
             *
             * @param {AbonnementState} state
             */
            getReportSprache (state) {
                let result = null
                if (state.reportsprache) {
                    if (state.reportsprache) {
                        result = state.reportsprache
                    }
                }
                return result
            }
        },
        actions: {
            /**
             * Should be called when abonnement should be removed or replaced for a different Mitglied.
             */
            RESET_ABONNEMENTS_STATE () {
                this.ajaxError = null
                this.crefoPayErrorCode = null
                this.abonnementsFetching = false
                this.abonnementsAvailable = false
                this.abonnements = []
                this.selectedAbonnement = null
                this.selectedAbonnementStopping = false
                this.selectedAbonnementStopped = false
                this.selectedAbonnementStopAjaxError = null
                this.acceptTermOfService = null
            },
            /**
             * Should be called before new abonnements are fetched.
             */
            GET_ABONNEMENTS_FETCHING () {
                this.abonnementsFetching = true
            },
            /**
             * Should be called with the fetched abonnements data.
             *
             * abonnementsVertretungsberechtigt and abonnementsNotVertretungsberechtigt will hold the abonnements
             * where the user is or is not VB if that information needs to be shown somewhere.
             *
             * @param {Array<Abonnement>} data
             */
            GET_ABONNEMENTS_SUCCESS (data) {
                this.abonnementsFetching = false
                this.ajaxError = null
                this.crefoPayErrorCode = null
                const abonnementsNotCanceled = data.filter(abonnement => isAbonnementStorniert(abonnement) === false)
                if (abonnementsNotCanceled.length) {
                    const abonnements = getAbonnementsSortedByPriority(abonnementsNotCanceled)
                    this.abonnements = abonnements
                    this.abonnementsVertretungsberechtigt = abonnements.filter(abonnement =>
                        HelperUser.hasRoleVertretungsberechtigung(abonnement.crefonummer))
                    this.abonnementsNotVertretungsberechtigt = abonnements.filter(abonnement => !HelperUser.hasRoleVertretungsberechtigung(abonnement.crefonummer))
                    this.abonnementsAvailable = true
                } else {
                    this.abonnementsAvailable = false
                    this.abonnements = []
                    this.abonnementsVertretungsberechtigt = []
                    this.abonnementsNotVertretungsberechtigt = []
                }
            },
            /**
             * Should be called when abonnements could not be fetched.
             *
             * @param {?AjaxError} error
             */
            GET_ABONNEMENTS_FAILED (error) {
                this.abonnementsFetching = false
                this.abonnementsAvailable = false
                this.abonnements = []
                this.abonnementsVertretungsberechtigt = []
                this.abonnementsNotVertretungsberechtigt = []
                this.ajaxError = error
                this.crefoPayErrorCode = CrefopayHelper.getMappedErrorCode(error)
            },
            /**
             * Should be called with the fetched rechnungsadressen data.
             *
             * @param {Rechnungsadresse} data
             */
            GET_RECHNUNGSADRESSEN_SUCCESS (data) {
                this.rechnungsadresseFetching = false
                this.ajaxError = null
                this.crefoPayErrorCode = null
                if (typeof data === 'object' && data !== null) {
                    this.rechnungsadresse = {
                        crid: data.crid || null,
                        name: data.name || null,
                        strasse: data.strasse || null,
                        hausnummer: data.hausnummer || null,
                        plz: data.plz || null,
                        ort: data.ort || null,
                        land: data.land || null,
                        email: data.email || null,
                        umsatzsteuerId: data.umsatzsteuerId || null,
                        kommunikationssprache: data.kommunikationssprache || null
                    }
                    this.rechnungsadresseAvailable = true
                } else {
                    this.rechnungsadresseAvailable = false
                    this.rechnungsadresse = {
                        crid: null,
                        name: null,
                        strasse: null,
                        hausnummer: null,
                        plz: null,
                        ort: null,
                        land: null,
                        email: null,
                        umsatzsteuerId: null,
                        kommunikationssprache: null
                    }
                }
            },
            /**
             * Should be called when rechnungsadressen could not be fetched.
             *
             * @param {?AjaxError} error
             */
            GET_RECHNUNGSADRESSEN_FAILED (error) {
                this.rechnungsadresseFetching = false
                this.rechnungsadresseAvailable = false
                this.rechnungsadresse = {
                    crid: null,
                    name: null,
                    strasse: null,
                    hausnummer: null,
                    plz: null,
                    ort: null,
                    land: null,
                    email: null,
                    umsatzsteuerId: null,
                    kommunikationssprache: null
                }
                this.ajaxError = error.response
                this.crefoPayErrorCode = null
            },
            /**
             * Should be called before new abonnements are created.
             */
            CREATE_BILLING_ADDRESS_FETCHING () {
                this.rechnungsadresseFetching = true
            },
            /**
             * Should be called with the fetched abonnements data.
             *
             * abonnementsVertretungsberechtigt and abonnementsNotVertretungsberechtigt will hold the abonnements
             * where the user is or is not VB if that information needs to be shown somewhere.
             */
            CREATE_BILLING_ADDRESS_SUCCESS () {
                this.rechnungsadresseFetching = false
                this.rechnungsadresseAvailable = true
                this.ajaxError = null
                this.crefoPayErrorCode = null
            },
            /**
             * Should be called when abonnements could not be fetched.
             *
             * @param {?AjaxError} error
             */
            CREATE_BILLING_ADDRESS_FAILED (error) {
                this.rechnungsadresseFetching = false
                this.rechnungsadresseAvailable = false
                this.ajaxError = error
                this.crefoPayErrorCode = null
            },
            UPDATE_SELECTED_ABONNEMENT () {
                const aboId = this.selectedAbonnement ? this.selectedAbonnement.aboId : null
                if (aboId && this.abonnements instanceof Array) {
                    const matchingAbonnement = this.abonnements.find(abonnement => abonnement.aboId === aboId)
                    if (matchingAbonnement) {
                        this.selectedAbonnement = matchingAbonnement
                    } else {
                        this.selectedAbonnement = null
                    }
                    if (matchingAbonnement && typeof matchingAbonnement.turnus === 'string') {
                        this.paymentCycle = matchingAbonnement.turnus.toLowerCase() === 'jaehrlich' ? 'JAEHRLICH' : 'MONATLICH'
                    } else {
                        this.paymentCycle = 'MONATLICH'
                    }
                }
            },
            /**
             * Should be called to set the current active abonnement.
             *
             * @param {Abonnement} data
             */
            ON_SELECT_ABONNEMENT (data) {
                this.selectedAbonnement = data
                this.selectedAbonnementStopping = false
                this.selectedAbonnementStopped = false
                this.selectedAbonnementStopAjaxError = null
                if (data && typeof data.turnus === 'string') {
                    this.paymentCycle = data.turnus.toLowerCase() === 'jaehrlich' ? 'JAEHRLICH' : 'MONATLICH'
                }
            },
            /**
             * Should be called to set the payment cycle
             *
             * @param {boolean} data
             */
            ON_SELECT_PAYMENT_CYCLE (data) {
                this.paymentCycle = data
            },
            /**
             * Should be called to set the agreement of term of service
             *
             * @param {boolean} data
             */
            ON_SELECT_TOS (data) {
                this.acceptTermOfService = data
            },
            /**
             * Should be called before selected abonnement is beeing canceled.
             *
             */
            STOP_ABONNEMENT_FETCHING () {
                this.selectedAbonnementStopping = true
            },
            /**
             * Sending request to cancel selected abonnement was successfull.
             *
             */
            STOP_ABONNEMENT_SUCCESS () {
                this.selectedAbonnementStopped = true
                this.selectedAbonnementStopping = false
                this.selectedAbonnementStopAjaxError = null
            },
            /**
             * Sending request to cancel selected abonnement was successfull.
             *
             * @param {?AjaxError} error
             */
            STOP_ABONNEMENT_FAILED (error) {
                this.selectedAbonnementStopped = false
                this.selectedAbonnementStopping = false
                this.selectedAbonnementStopAjaxError = error
            },
            /**
             * Updates billing address crid
             *
             * @param {String} data
             */
            ON_CHANGE_RECHNUNGSADRESSE_CRID (data) {
                this.rechnungsadresse.crid = data
            },
            /**
             * Updates billing address name
             *
             * @param {String} data
             */
            ON_CHANGE_RECHNUNGSADRESSE_NAME (data) {
                this.rechnungsadresse.name = data
            },
            /**
             * Updates billing address street
             *
             * @param {String} data
             */
            ON_CHANGE_RECHNUNGSADRESSE_STRASSE (data) {
                this.rechnungsadresse.strasse = data
            },
            /**
             * Updates billing address housenumber
             *
             * @param {String} data
             */
            ON_CHANGE_RECHNUNGSADRESSE_HAUSNUMMER (data) {
                this.rechnungsadresse.hausnummer = data
            },
            /**
             * Updates billing address zipcode
             *
             * @param {String} data
             */
            ON_CHANGE_RECHNUNGSADRESSE_PLZ (data) {
                this.rechnungsadresse.plz = data
            },
            /**
             * Updates billing address city
             *
             * @param {String} data
             */
            ON_CHANGE_RECHNUNGSADRESSE_ORT (data) {
                this.rechnungsadresse.ort = data
            },
            /**
             * Updates billing address country
             *
             * @param {String} data
             */
            ON_CHANGE_RECHNUNGSADRESSE_LAND (data) {
                this.rechnungsadresse.land = data
            },
            /**
             * Updates kommunikationssprache
             *
             * @param {String} data
             */
            ON_CHANGE_RECHNUNGSADRESSE_KOMMUNIKATIONSSPRACHE (data) {
                this.rechnungsadresse.kommunikationssprache = data
            },
            /**
             * Updates billing address email
             *
             * @param {String} data
             */
            ON_CHANGE_RECHNUNGSADRESSE_EMAIL (data) {
                this.rechnungsadresse.email = data
            },
            /**
             * Updates billing address umsatzsteuerId
             *
             * @param {String} data
             */
            ON_CHANGE_RECHNUNGSADRESSE_USTID (data) {
                this.rechnungsadresse.umsatzsteuerId = data
            },
            /**
             * Updates billing address zipcode
             *
             * @param {boolean} started
             */
            ON_CHANGE_CHECKOUT_STARTED (started) {
                this.checkoutStarted = started
            },
            /**
             * Updates report language
             *
             * @param {String} data
             */
            ON_CHANGE_ORDER_REPORTSPRACHE (data) {
                this.reportsprache = data
            },
            /**
             * Fetches abonnements for the current user.
             *
             * May be overridden with actForMemberId when Unternehmensnavigation is in use.
             *
             * @param {Object} payload
             * @param {string} payload.crid
             */
            async getAbonnements (payload) {
                this.GET_ABONNEMENTS_FETCHING()
                try {
                    const response = await api.getAbonnements(payload.crid)
                    this.GET_ABONNEMENTS_SUCCESS(response.data)
                } catch (error) {
                    this.GET_ABONNEMENTS_FAILED(error)
                }
            },
            /**
             * Updates the list of abonnements and selectedAbonnement.
             *
             * @param {Object} payload
             * @param {string} payload.crid
             */
            updateAbonnements (payload) {
                this.GET_ABONNEMENTS_FETCHING()
                api.getAbonnements(payload.crid)
                    .then(response => {
                        this.GET_ABONNEMENTS_SUCCESS(response.data)
                    })
                    .catch(err => {
                        this.GET_ABONNEMENTS_FAILED(err)
                    })
                    .finally(() => {
                        this.UPDATE_SELECTED_ABONNEMENT()
                    })
            },
            /**
             * Creates an abonnement for the given payload.
             *
             * @param {Object} payload
             * @param {string} payload.crid
             * @param {string} payload.crefonummer
             * @param {boolean} payload.agbsAkzeptiert
             * @param {boolean} payload.reportsprache
             */
            createAbonnementForMitglied (payload) {
                this.GET_ABONNEMENTS_FETCHING()
                api.createAbonnementForMitglied(payload.crid, payload.crefonummer, payload.agbsAkzeptiert, payload.reportsprache)
                    .then(response => {
                        this.GET_ABONNEMENTS_SUCCESS([ response.data ])
                        this.ON_SELECT_ABONNEMENT(response.data)
                        WrapperWebtrekk.trackAction("meine_bonitaet_frontend_action_createAbonnement_for_mitglied", {
                            8: payload.crefonummer
                        })
                    })
                    .catch(err => {
                        this.GET_ABONNEMENTS_FAILED(err)
                        this.ON_SELECT_ABONNEMENT(null)
                        WrapperWebtrekk.trackAction("meine_bonitaet_frontend_error_createAbonnement_for_mitglied", {
                            8: payload.crefonummer
                        })
                    })
            },
            /**
             * Creates an abonnement for the given payload.
             *
             * @param {Object} payload
             * @param {string} payload.crid
             * @param {string} payload.crefonummer
             * @param {boolean} payload.agbsAkzeptiert
             * @param {string} payload.turnus
             * @param {string} payload.zahlungsart
             * @param {string} payload.reportsprache
             * @param {object} payload.rechnungsadresse
             * @param {string} payload.rechnungsadresse.name
             * @param {string} payload.rechnungsadresse.strasse
             * @param {string} payload.rechnungsadresse.hausnummer
             * @param {string} payload.rechnungsadresse.plz
             * @param {string} payload.rechnungsadresse.ort
             * @param {string} payload.rechnungsadresse.land
             * @param {string} payload.rechnungsadresse.email
             * @param {string} payload.rechnungsadresse.umsatzsteuerId
             */
            createAbonnementForDigitalesMitglied (payload) {
                this.GET_ABONNEMENTS_FETCHING()
                return new Promise((resolve, reject) => {
                    sessionStorage.removeItem("meine_bonitaet_aboId")
                    api.createAbonnementForDigitalesMitglied(
                        payload.crid,
                        payload.crefonummer,
                        payload.agbsAkzeptiert,
                        payload.turnus,
                        payload.zahlungsart,
                        payload.rechnungsadresse,
                        payload.reportsprache
                    )
                        .then(response => {
                            if (response.data.abonnement) {
                                if (response.data.abonnement.aboId) {
                                    sessionStorage.setItem("meine_bonitaet_aboId", response.data.abonnement.aboId)
                                }
                                this.GET_ABONNEMENTS_SUCCESS([ response.data.abonnement ])
                                this.ON_SELECT_ABONNEMENT(response.data.abonnement)
                            } else {
                                this.GET_ABONNEMENTS_FAILED(null)
                                this.ON_SELECT_ABONNEMENT(null)
                            }
                            WrapperWebtrekk.trackAction("meine_bonitaet_frontend_action_createAbonnement_for_digitales_mitglied", {
                                8: payload.crefonummer
                            })
                            resolve(response.data.redirectUrl)
                        })
                        .catch(err => {
                            if (
                                err &&
                                err.response &&
                                err.response.headers &&
                                err.response.headers["digimon_status_code"] === "403"
                            ) {
                                err.response.status = 451
                            }
                            this.GET_ABONNEMENTS_FAILED(err)
                            this.ON_SELECT_ABONNEMENT(null)
                            reject(err)
                            WrapperWebtrekk.trackAction("meine_bonitaet_frontend_error_createAbonnement_for_digitales_mitglied", {
                                8: payload.crefonummer
                            })
                        })
                })
            },
            /**
             * Fetches Rechnungsadresse for the current user.
             *
             * May be overridden with actForMemberId when Unternehmensnavigation is in use.
             *
             * @param {Object} payload
             * @param {string} payload.crid
             */
            async getRechnungsadresse (payload) {
                this.CREATE_BILLING_ADDRESS_FETCHING()
                try {
                    const response = await api.getRechnungsadressen(payload.crid)
                    this.GET_RECHNUNGSADRESSEN_SUCCESS(response.data)
                } catch (error) {
                    this.GET_RECHNUNGSADRESSEN_FAILED(error)
                }
            },
            /**
             * Creates a billing address
             *
             * @param {Object} payload
             * @param {object} payload.rechnungsadresse
             * @param {string} payload.rechnungsadresse.crid
             * @param {string} payload.rechnungsadresse.name
             * @param {string} payload.rechnungsadresse.strasse
             * @param {string} payload.rechnungsadresse.hausnummer
             * @param {string} payload.rechnungsadresse.plz
             * @param {string} payload.rechnungsadresse.ort
             * @param {string} payload.rechnungsadresse.land
             * @param {string} payload.rechnungsadresse.email
             * @param {string} payload.rechnungsadresse.umsatzsteuerId
             */
            createRechnungsadresseForDigitalesMitglied (payload) {
                this.CREATE_BILLING_ADDRESS_FETCHING()
                return new Promise((resolve, reject) => {
                    api.createRechnungsadresseForDigitalesMitglied(payload.rechnungsadresse)
                        .then(response => {
                            this.CREATE_BILLING_ADDRESS_SUCCESS(response.data)
                            resolve(response.data)
                        })
                        .catch(err => {
                            this.CREATE_BILLING_ADDRESS_FAILED(err)
                            reject(err)
                        })
                })
            },
            /**
             * Stops an abonnement for the given aboId.
             *
             * @param {string} aboId
             */
            stopAbonnementForMitglied (aboId) {
                this.STOP_ABONNEMENT_FETCHING()
                api.stopAbonnementForMitglied(aboId)
                    .then(response => {
                        this.STOP_ABONNEMENT_SUCCESS()
                        WrapperWebtrekk.trackAction("meine_bonitaet_frontend_action_stopAbonnement_for_mitglied", { 7: aboId })
                    })
                    .catch(err => {
                        this.STOP_ABONNEMENT_FAILED(err)
                        WrapperWebtrekk.trackAction("meine_bonitaet_frontend_error_stopAbonnement_for_mitglied", { 7: aboId })
                    })
            },
            /**
             * Stops an abonnement for the given aboId.
             *
             * @param {string} aboId
             */
            stopAbonnementForDigitalesMitglied (aboId) {
                return new Promise((resolve, reject) => {
                    this.STOP_ABONNEMENT_FETCHING()
                    api.stopAbonnementForDigitalesMitglied(aboId)
                        .then(response => {
                            this.STOP_ABONNEMENT_SUCCESS()
                            WrapperWebtrekk.trackAction("meine_bonitaet_frontend_action_stopAbonnement_for_digitales_mitglied", {
                                7: aboId
                            })
                            resolve()
                        })
                        .catch(err => {
                            this.STOP_ABONNEMENT_FAILED(err)
                            WrapperWebtrekk.trackAction("meine_bonitaet_frontend_error_stopAbonnement_for_digitales_mitglied", {
                                7: aboId
                            })
                            reject()
                        })
                })
            },
            /**
             * Updates VB roles for existing abonnements.
             */
            updateAbonnementVertretungsBerechtigungsStatus () {
                if (this.abonnements.length) {
                    this.GET_ABONNEMENTS_SUCCESS(this.abonnements)
                }
            }
        }
    }
)

/**
 * Checks if an abonnement was canceled via STORNIERT.
 *
 * @param {Abonnement} abonnement
 * @return {boolean}
 */
function isAbonnementStorniert (abonnement) {
    if (abonnement.status === 'STORNIERT') {
        return true
    }
    if (abonnement.geschlossenAufgrund === 'STORNIERT') {
        return true
    }
    return false
}

/**
 * Prioritize active abonnements that the user can open and look at.
 *
 * @param {Array} abonnements
 * @returns {Array}
 */
function getAbonnementsSortedByPriority (abonnements) {
    const statusPriority = { AKTIV: 1, GEKUENDIGT: 2, BEENDET: 3, VORBEREITET: 4, NICHT_BEGONNEN: 5, STORNIERT: 6 }
    return abonnements.reverse().sort((a, b) => {
        if (a.status === b.status || (!a.status && !b.status)) {
            if (a.aboBeginn && b.aboBeginn) {
                if (a.aboBeginn > b.aboBeginn) {
                    return -1
                } else if (a.aboBeginn < b.aboBeginn) {
                    return 1
                }
            }
            return 0
        } else if (!a.status) {
            return 1
        } else if (!b.status) {
            return -1
        } else if (statusPriority[a.status] < statusPriority[b.status]) {
            return -1
        } else if (statusPriority[a.status] > statusPriority[b.status]) {
            return 1
        } else {
            return 0
        }
    })
}

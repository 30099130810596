// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/img/950x503-selbstauskunft.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ellipsis[data-v-75777c6b]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.header[data-v-75777c6b]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:100% 0;background-size:80%;min-height:250px}.header .marketing[data-v-75777c6b]{margin-top:11%;font-size:26px;max-width:285px}.mb-not-lg[data-v-75777c6b]{margin-bottom:12px!important}@media only screen and (max-width:1199px){.mb-not-lg[data-v-75777c6b]{margin-top:0!important;margin-bottom:0!important}}@media only screen and (max-width:767px){.mb-not-lg[data-v-75777c6b]{margin-top:3px!important;margin-bottom:0!important}}.crefo-ui-alert a[data-v-75777c6b]{color:#fff;text-decoration:underline}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
    <div>
        <validation-observer v-slot="{ invalid, handleSubmit }">
            <div class="container">
                <template v-if="isLoading">
                    <crefo-ring-loading additional-classes="mt" />
                </template>
                <template v-else>
                    <div class="row">
                        <div class="col-12">
                            <crefo-error-message
                                v-if="showError && currentKurzprofilAjaxError"
                                :ajax-error="currentKurzprofilAjaxError"
                                data-qa="abonnement-error"
                            />
                        </div>
                    </div>
                </template>
            </div>
            <div class="bg-brand bg-brand-light-grey big-box-spacing">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-7 col-xl-8">
                            <crefo-kurzprofil
                                :kurzprofil="kurzprofilStore.kurzprofil"
                                :crefonummer="userStore.crefonummer"
                                :show-header="false"
                                :show-country="true"
                                :translations="{ 'Kurzprofil.Label.Kurzprofil': $t('Kurzprofil.Label.Kurzprofil.Lang') }"
                                design="WhiteBoxVertical"
                                class="mb"
                            />

                            <div class="row">
                                <div class="col-12 col-lg-6 col-xl-7 mb">
                                    <span>{{ $t("InformationView.Label.FrageDatenUebernehmen") }}</span>
                                </div>
                                <div class="col-12 col-lg-6 col-xl-5 mb">
                                    <button
                                        class="btn btn-default btn-block btn-small"
                                        data-qa="transfer-button"
                                        @click.prevent="transferRechnungsadresse"
                                    >
                                        {{ $t("InformationView.Button.DatenUebernehmen") }}
                                    </button>
                                </div>
                            </div>

                            <div class="bg-white box-spacing box-shadow-xy mb">
                                <div class="row">
                                    <div class="col-12">
                                        <h2>{{ $t("InformationView.Label.IhreRechnungsadresse") }}</h2>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <validation-provider
                                            v-slot="{ errors, invalid, dirty }"
                                            name="billingAddressName"
                                            rules="required|max:100"
                                            :immediate="true"
                                        >
                                            <div class="crefo-input">
                                                <div
                                                    class="input-group"
                                                    :class="{ 'has-error': invalid && (dirty || buttonPressed) }"
                                                >
                                                    <label
                                                        for="billingAddressName"
                                                        data-qa="company-name-label"
                                                    >
                                                        {{ $t("InformationView.Label.CompanyName") }}
                                                    </label>
                                                    <div class="input-wrapper">
                                                        <input
                                                            id="billingAddressName"
                                                            v-model="firmierung"
                                                            data-qa="different-billing-address-company-name"
                                                        />
                                                    </div>
                                                    <span
                                                        class="error-msg"
                                                        data-qa="different-billing-address-company-name-error"
                                                    >
                                                        {{ errors[0] }}
                                                    </span>
                                                </div>
                                            </div>
                                        </validation-provider>
                                        <div class="row">
                                            <div class="col-12 col-lg-8">
                                                <validation-provider
                                                    v-slot="{ errors, invalid, dirty }"
                                                    name="billingAddressStreet"
                                                    rules="required|max:80"
                                                    :immediate="true"
                                                >
                                                    <div class="crefo-input">
                                                        <div
                                                            class="input-group"
                                                            :class="{
                                                                'has-error': invalid && (dirty || buttonPressed)
                                                            }"
                                                        >
                                                            <label
                                                                for="billingAddressStreet"
                                                                data-qa="street-label"
                                                            >
                                                                {{ $t("InformationView.Label.Street") }}
                                                            </label>
                                                            <div class="input-wrapper">
                                                                <input
                                                                    id="billingAddressStreet"
                                                                    v-model="strasse"
                                                                    data-qa="different-billing-address-street"
                                                                />
                                                            </div>
                                                            <span
                                                                class="error-msg"
                                                                data-qa="different-billing-address-street-error"
                                                            >
                                                                {{ errors[0] }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-12 col-lg-4">
                                                <validation-provider
                                                    v-slot="{ errors, invalid, dirty }"
                                                    name="billingAddressHousenumber"
                                                    rules="max:15"
                                                    :immediate="true"
                                                >
                                                    <div class="crefo-input">
                                                        <div
                                                            class="input-group"
                                                            :class="{
                                                                'has-error': invalid && (dirty || buttonPressed)
                                                            }"
                                                        >
                                                            <label
                                                                for="billingAddressHousenumber"
                                                                data-qa="housenumber-label"
                                                            >
                                                                {{ $t("InformationView.Label.Housenumber") }}
                                                            </label>
                                                            <div class="input-wrapper">
                                                                <input
                                                                    id="billingAddressHousenumber"
                                                                    v-model="hausnummer"
                                                                    data-qa="different-billing-address-housenumber"
                                                                />
                                                            </div>
                                                            <span
                                                                class="error-msg"
                                                                data-qa="-error"
                                                            >
                                                                {{ errors[0] }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <validation-provider
                                                    v-slot="{ errors, invalid, dirty }"
                                                    name="billingAddressPostcode"
                                                    rules="required|zip_code2:@country"
                                                    :immediate="true"
                                                >
                                                    <div class="crefo-input">
                                                        <div
                                                            class="input-group"
                                                            :class="{
                                                                'has-error':
                                                                    invalid &&
                                                                    (dirty || buttonPressed ||
                                                                        ($refs.countryValidationProvider.flags.dirty && typeof plz === 'string' && plz.length))
                                                            }"
                                                        >
                                                            <label
                                                                for="billingAddressPostcode"
                                                                data-qa="postcode-label"
                                                            >
                                                                {{ $t("InformationView.Label.Postcode") }}
                                                            </label>
                                                            <div class="input-wrapper">
                                                                <input
                                                                    id="billingAddressPostcode"
                                                                    v-model="plz"
                                                                    data-qa="different-billing-address-postcode"
                                                                />
                                                            </div>
                                                            <span
                                                                class="error-msg"
                                                                data-qa="different-billing-address-postcode-error"
                                                            >
                                                                {{ errors[0] }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-8">
                                                <div class="crefo-input">
                                                    <validation-provider
                                                        v-slot="{ errors, invalid, dirty }"
                                                        name="billingAddressCity"
                                                        rules="required|max:80"
                                                        :immediate="true"
                                                    >
                                                        <div
                                                            class="input-group"
                                                            :class="{ 'has-error': invalid && (dirty || buttonPressed) }"
                                                        >
                                                            <label
                                                                for="billingAddressCity"
                                                                data-qa="city-label"
                                                            >
                                                                {{ $t("InformationView.Label.City") }}
                                                            </label>
                                                            <div class="input-wrapper">
                                                                <input
                                                                    id="billingAddressCity"
                                                                    v-model="ort"
                                                                    data-qa="different-billing-address-city"
                                                                />
                                                            </div>
                                                            <span
                                                                class="error-msg"
                                                                data-qa="different-billing-address-city-error"
                                                            >
                                                                {{ errors[0] }}
                                                            </span>
                                                        </div>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                        </div>
                                        <validation-provider
                                            v-slot="{ errors, invalid, dirty }"
                                            ref="countryValidationProvider"
                                            name="country"
                                            rules="required"
                                            :immediate="true"
                                        >
                                            <div class="crefo-input crefo-ui-select">
                                                <div
                                                    class="input-group"
                                                    :class="{ 'has-error': invalid && (dirty || buttonPressed) }"
                                                    data-qa="different-billing-address-country-wrapper"
                                                >
                                                    <label
                                                        for="billingAddressCountry"
                                                        data-qa="country-label"
                                                    >
                                                        {{ $t("InformationView.Label.Country") }}
                                                    </label>
                                                    <div class="select-group">
                                                        <div class="input-wrapper">
                                                            <select
                                                                id="billingAddressCountry"
                                                                v-model="land"
                                                                name="billingAddressCountry"
                                                                data-qa="different-billing-address-country"
                                                                data-no-crefo-ui="true"
                                                            >
                                                                <option value="">
                                                                    {{ $t("InformationView.Label.BitteWaehlen") }}
                                                                </option>
                                                                <option
                                                                    v-for="country in countryList"
                                                                    :key="country.alpha2"
                                                                    :value="country.alpha2"
                                                                >
                                                                    {{ country.name }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="error-msg">
                                                        <span data-qa="different-billing-address-country-error">
                                                            {{ errors[0] }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider
                                            v-show="hasReverseCharge"
                                            v-slot="{ errors, invalid, dirty }"
                                            :rules="{
                                                required: hasReverseCharge,
                                                max: 30
                                            }"
                                            :immediate="true"
                                        >
                                            <div class="crefo-input">
                                                <div
                                                    class="input-group"
                                                    :class="{ 'has-error': invalid && (dirty || buttonPressed) }"
                                                >
                                                    <label
                                                        for="billingAddressUmsatzsteuerId"
                                                        data-qa="UmsatzsteuerId-label"
                                                    >
                                                        {{ $t("Rechnungsadresse.Label.UmsatzsteuerId") }}
                                                    </label>
                                                    <div class="input-wrapper">
                                                        <input
                                                            id="billingAddressUmsatzsteuerId"
                                                            v-model="umsatzsteuerId"
                                                            type="text"
                                                            name="billingAddressUmsatzsteuerId"
                                                            data-qa="different-billing-address-UmsatzsteuerId"
                                                            data-no-crefo-ui="true"
                                                        />
                                                    </div>
                                                    <div class="error-msg">
                                                        <span data-qa="different-billing-address-UmsatzsteuerId-error">
                                                            {{ errors[0] }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider
                                            v-slot="{ errors, invalid, dirty }"
                                            name="billingAddressEmail"
                                            rules="required|max:100|email"
                                            :immediate="true"
                                        >
                                            <div class="crefo-input">
                                                <div
                                                    class="input-group"
                                                    :class="{ 'has-error': invalid && (dirty || buttonPressed) }"
                                                >
                                                    <label
                                                        for="billingAddressEmail"
                                                        data-qa="email-label"
                                                    >
                                                        {{ $t("InformationView.Label.Email") }}
                                                    </label>
                                                    <div class="input-wrapper">
                                                        <input
                                                            id="billingAddressEmail"
                                                            v-model="emailAddress"
                                                            type="text"
                                                            data-no-crefo-ui="true"
                                                            data-qa="different-billing-address-email"
                                                        />
                                                    </div>
                                                    <span
                                                        class="error-msg"
                                                        data-qa="different-billing-address-email-error"
                                                    >
                                                        {{ errors[0] }}
                                                    </span>
                                                </div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider
                                            ref="languageProvider"
                                            v-slot="{ errors, invalid, dirty }"
                                            rules="required"
                                            name="languageProvider"
                                        >
                                            <div class="crefo-input crefo-ui-select">
                                                <div
                                                    class="input-group"
                                                    :class="{ 'has-error': invalid && (dirty || buttonPressed)}"
                                                    data-qa="billing-communication-language-country-wrapper"
                                                >
                                                    <label
                                                        for="billingCommunicationLanguage"
                                                        data-qa="different-billing-communication-language-label"
                                                    >
                                                        {{ $t("InformationView.Label.Kommunikationssprache") }}
                                                    </label>
                                                    <div class="select-group">
                                                        <div class="input-wrapper">
                                                            <select
                                                                id="billingCommunicationLanguage"
                                                                v-model="gewaehlteKommunikationsSprache"
                                                                name="billingCommunicationLanguage"
                                                                data-qa="different-billing-communication-language-country"
                                                                data-no-crefo-ui="false"
                                                            >
                                                                <option value="">
                                                                    {{ $t("InformationView.Label.BitteWaehlen") }}
                                                                </option>
                                                                <option
                                                                    v-for="language in sprachAuswahl"
                                                                    :key="language.code"
                                                                    :value="language.code"
                                                                >
                                                                    {{ $t(language.name) }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="error-msg">
                                                        <span data-qa="different-billing-communication-language-error">
                                                            {{ errors[0] }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-white box-spacing box-shadow-xy mb">
                                <div class="row">
                                    <div class="col-12">
                                        <h2>
                                            {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.Zahlungsturnus") }}
                                        </h2>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="crefo-input mb">
                                            <div class="input-group">
                                                <div class="radio-group">
                                                    <div class="input-wrapper">
                                                        <div class="crefo-radio">
                                                            <div
                                                                class="radio-btn"
                                                                data-qa="radio-payment-cycle"
                                                            >
                                                                <input
                                                                    id="true"
                                                                    v-model="paymentCycle"
                                                                    type="radio"
                                                                    name="paymentCycle"
                                                                    value="MONATLICH"
                                                                    data-no-crefo-ui="false"
                                                                    data-qa="monatlich-button"
                                                                />
                                                                <label for="true">
                                                                    <span class="radio-box">
                                                                        <span
                                                                            class="radio-box-circle"
                                                                            data-qa="monatlich-button-label"
                                                                        />
                                                                    </span>
                                                                    <i18n
                                                                        path="Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.MonatlichBetrag"
                                                                        tag="div"
                                                                        class="radio-label radio-label--meine-bonitaet"
                                                                    >
                                                                        <br>
                                                                    </i18n>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="crefo-radio">
                                                            <div
                                                                class="radio-btn"
                                                                data-qa="radio-payment-cycle"
                                                            >
                                                                <input
                                                                    id="false"
                                                                    v-model="paymentCycle"
                                                                    type="radio"
                                                                    name="paymentCycle"
                                                                    value="JAEHRLICH"
                                                                    data-no-crefo-ui="false"
                                                                    data-qa="jaehrlich-button"
                                                                />
                                                                <label for="false">
                                                                    <span class="radio-box">
                                                                        <span
                                                                            class="radio-box-circle"
                                                                            data-qa="jaehrlich-button-label"
                                                                        />
                                                                    </span>
                                                                    <i18n
                                                                        path="Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.JahrlichBetrag"
                                                                        tag="div"
                                                                        class="radio-label radio-label--meine-bonitaet"
                                                                    >
                                                                        <br>
                                                                    </i18n>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-5 col-xl-4">
                            <div class="box-shadow-xy box-teaser--profile">
                                <img
                                    src="@/assets/img/bild_tablet-auskunft.jpg"
                                    alt="Auskunftsbild"
                                />
                                <div
                                    class="box-spacing"
                                    data-qa="info-box"
                                >
                                    <i18n
                                        path="Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.BlaBlaHeader"
                                        tag="h3"
                                        class="mb-small"
                                    >
                                        <br>
                                    </i18n>
                                    <ul class="list-unordered list-checked">
                                        <li>
                                            {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.BlaBlaReason1") }}
                                        </li>
                                        <li>
                                            {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.BlaBlaReason2") }}
                                        </li>
                                        <li>
                                            {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.BlaBlaReason3") }}
                                        </li>
                                        <li>
                                            {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.BlaBlaReason4") }}
                                        </li>
                                        <li>
                                            {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.BlaBlaReason5") }}
                                        </li>
                                    </ul>
                                    <div
                                        v-show="paymentCycle === 'MONATLICH'"
                                        data-qa="payment-cycle-monthly"
                                    >
                                        <p>
                                            <strong data-qa="zahlungsturnus-anzeige-monatlich">
                                                {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.TeaserMonatlich") }}
                                            </strong>
                                        </p>
                                        <p
                                            class="h2 mb-small px-small"
                                            data-qa="zahlbetrag-anzeige-monatlich"
                                        >
                                            {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.TeaserMonatlichBetrag") }}<sup>*</sup>
                                        </p>
                                    </div>
                                    <div
                                        v-show="paymentCycle === 'JAEHRLICH'"
                                        data-qa="payment-cycle-annually"
                                    >
                                        <p>
                                            <strong data-qa="zahlungsturnus-anzeige-jährlich">
                                                {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.TeaserJaehrlich") }}
                                            </strong>
                                        </p>
                                        <p
                                            class="h2 mb-small px-small"
                                            data-qa="zahlbetrag-anzeige-jährlich"
                                        >
                                            {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.TeaserJaehrlichBetrag") }}<sup>*</sup>
                                        </p>
                                        <p>
                                            <strong data-qa="ersparnis-anzeige">
                                                {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.TeaserJaehrlichErsparnis") }}
                                            </strong>
                                        </p>
                                    </div>
                                    <p
                                        class="text-small"
                                        data-qa="zahlbetrag-anzeige-steuer"
                                    >
                                        <sup>*</sup>
                                        {{ $t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.TeaserBetragSternchen") }}
                                        <br>
                                        {{ $t("Components.Selbstauskunft.Order.Landingpage.ProduktInformation.Steuer2") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            v-if="showError && currentAbonnementAjaxError"
                            class="col-12 mb-small"
                        >
                            <crefo-error-message
                                :ajax-error="currentAbonnementAjaxError"
                                data-qa="abonnement-error"
                                error-prefix="Rechnungsadresse"
                            />
                        </div>
                        <div class="col-12">
                            <button
                                class="btn btn-default mb mt mt-lg-0"
                                data-qa="start-button"
                                type="submit"
                                :disabled="buttonDisabled(invalid)"
                                @click.prevent="
                                    buttonPressed = true;
                                    handleSubmit(start);
                                "
                            >
                                <span data-qa="start-button-label">
                                    {{ $t("Abonnement.Label.EinfachWeiter") }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12 mb">
                        <a
                            href="#"
                            class="link-icon-prev"
                            role="button"
                            data-qa="back-button"
                            @click.prevent="gotoStartpage"
                        >
                            <span>
                                {{ $t("Abonnement.Label.Zurueck") }}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <vorteile-meine-bonitaet-teaser />
        </validation-observer>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import { useAbonnementStore } from '@/stores/abonnement'
    import CrefoErrorMessage from "@dm/crefo-error-message"
    import CrefoRingLoading from "@dm/crefo-ring-loading"
    import CrefoKurzprofil from "@dm/crefo-kurzprofil"
    import VorteileMeineBonitaetTeaser from "./vorteile-meine-bonitaet-teaser"
    import i18n from "@/i18n"
    import { ValidationObserver, ValidationProvider, localeChanged, extend } from "vee-validate"
    import { required, max, email } from "vee-validate/dist/rules"
    import WrapperWebtrekk from "@/helper/webtrekk-wrapper"
    import countries from "i18n-iso-countries"
    import i18nZipcodes from "i18n-zipcodes"
    import GeneralUtilityHelper from "@/helper/general"
    import ISO6391 from "iso-639-1"

    extend("required", {
        ...required,
        message: (value, args) => {
            return i18n.t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.ValidateRequired")
        }
    })

    extend("max", {
        ...max,
        message: (value, args) => {
            return i18n.t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.ValidateMax", [
                args.length
            ])
        }
    }, {
        computesRequired: true
    })

    extend("email", {
        ...email,
        message: (value, args) => {
            return i18n.t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.ValidateEmail")
        }
    })

    extend("zip_code2", {
        params: [ "target" ],
        validate: (value, { target }) => {
            // PLZ Länge ist abhängig von der in der Länderauswahl inneliegenden PLZ
            if (typeof target === "string" && target.length) {
                // PLZ Länge
                if (typeof value === "string" && value.length && i18nZipcodes(target.toUpperCase(), value) === false) {
                    return false
                }
                return true
            } else if (typeof value !== "string" || value.length === 0) {
                // Land oder PLZ leer
                return true
            } else {
                // Land leer aber PLZ befüllt
                let result = false
                GeneralUtilityHelper.getAllowedCountries().forEach(countryCode => {
                    if (i18nZipcodes(countryCode.toUpperCase(), value)) {
                        result = true
                    }
                })
                return result
            }
        },
        message: (value, args) => {
            return i18n.t("Components.Selbstauskunft.Order.Partials.InformationZahlungsturnus.ValidateZip")
        }
    })

    export default {
        name: "InformationZahlungsturnus",
        components: {
            CrefoErrorMessage,
            CrefoRingLoading,
            CrefoKurzprofil,
            VorteileMeineBonitaetTeaser,
            ValidationObserver,
            ValidationProvider
        },
        data () {
            return {
                publicPath: process.env.BASE_URL || "",
                buttonPressed: false,
                gewaehlteKommunikationsSprache: null
            }
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore, useAbonnementStore),
            paymentCycle: {
                get () {
                    return this.abonnementStore.paymentCycle
                },
                set (newPaymentCycle) {
                    this.abonnementStore.ON_SELECT_PAYMENT_CYCLE(newPaymentCycle)
                }
            },
            firmierung: {
                get () {
                    return this.abonnementStore.rechnungsadresse.name
                },
                set (newFirmierung) {
                    this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_NAME(newFirmierung)
                }
            },
            strasse: {
                get () {
                    return this.abonnementStore.rechnungsadresse.strasse
                },
                set (newStrasse) {
                    this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_STRASSE(newStrasse)
                }
            },
            hausnummer: {
                get () {
                    return this.abonnementStore.rechnungsadresse.hausnummer
                },
                set (newHausnummer) {
                    this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_HAUSNUMMER(newHausnummer)
                }
            },
            plz: {
                get () {
                    return this.abonnementStore.rechnungsadresse.plz
                },
                set (newPlz) {
                    this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_PLZ(newPlz)
                }
            },
            ort: {
                get () {
                    return this.abonnementStore.rechnungsadresse.ort
                },
                set (newOrt) {
                    this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_ORT(newOrt)
                }
            },
            land: {
                get () {
                    return this.abonnementStore.rechnungsadresse.land
                },
                set (newLand) {
                    this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_LAND(newLand)
                }
            },
            umsatzsteuerId: {
                get () {
                    return this.abonnementStore.rechnungsadresse.umsatzsteuerId
                },
                set (umsatzsteuerId) {
                    this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_USTID(umsatzsteuerId)
                }
            },
            emailAddress: {
                get () {
                    return this.abonnementStore.rechnungsadresse.email || ""
                },
                set (newEmail) {
                    this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_EMAIL(newEmail)
                }
            },
            countryList () {
                const lang = this.$i18n.locale.split("_")[0].toLowerCase().replace("at", "de")
                return Object.entries(countries.getAlpha2Codes())
                    .filter(([ code, name ]) => GeneralUtilityHelper.isCountryAllowed(code.toUpperCase()))
                    .map(country => {
                        return {
                            alpha2: country[0],
                            name: countries.getName(country[0], lang || "de", { select: "official" })
                        }
                    })
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1
                        } else if (b.name > a.name) {
                            return 1
                        } else {
                            return 0
                        }
                    })
            },
            sprachAuswahl () {
                return ISO6391.getLanguages([ 'de', 'fr' ])
            },
            isLoading: function () {
                return this.abonnementsFetching === true
            },
            showError: function () {
                let result =
                    (this.currentAbonnementAjaxError !== null && this.currentAbonnementAjaxError.status !== 404) ||
                    this.kurzprofilStore.ajaxError !== null
                return result
            },
            currentKurzprofilAjaxError () {
                return this.kurzprofilStore.ajaxError
            },
            currentAbonnementAjaxError () {
                return this.abonnementStore.ajaxError
            },
            hasReverseCharge () {
                return this.abonnementStore.rechnungsadresse.land === "AT" || this.abonnementStore.rechnungsadresse.land === "LU"
            }
        },
        watch: {
            gewaehlteKommunikationsSprache (newValue, old) {
                this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_KOMMUNIKATIONSSPRACHE(newValue)
            },
            '$i18n.locale' (newValue) {
                localeChanged()
            }
        },
        mounted () {
            this.setPreselectedKommunikationsSprache()
        },
        methods: {
            isLanguageCodeAllowed (codeToCheck) {
                return this.sprachAuswahl.map(language => language.code).includes(codeToCheck)
            },
            setPreselectedKommunikationsSprache () {
                const selectedKommunikationssprache = this.abonnementStore.getKommunikationssprache
                const selectedWebseitenSprache = this.$i18n.locale.split('_')[0]

                if (this.isLanguageCodeAllowed(selectedKommunikationssprache)) {
                    this.gewaehlteKommunikationsSprache = selectedKommunikationssprache
                } else if (this.isLanguageCodeAllowed(selectedWebseitenSprache)) {
                    this.gewaehlteKommunikationsSprache = selectedWebseitenSprache
                } else {
                    this.gewaehlteKommunikationsSprache = 'de'
                }
            },
            gotoStartpage () {
                WrapperWebtrekk.trackAction("meine_bonitaet_frontend_action_zahlungsturnusGoBack_for_digitales_mitglied")
                this.abonnementStore.ON_CHANGE_CHECKOUT_STARTED(false)
                this.$router.push({ name: "selbstauskunft-registrierung" })
            },
            getCustomerEmail () {
                if (
                    typeof this.userStore.user.email === "string" &&
                    this.userStore.user.email.length
                ) {
                    return this.userStore.user.email
                }
                return this.kurzprofilStore.getEmail
            },
            buttonDisabled (invalid) {
                let result = false
                if (this.buttonPressed === true && invalid) {
                    result = true
                }
                return result
            },
            transferRechnungsadresse: function () {
                let land = this.kurzprofilStore.getLand
                if (GeneralUtilityHelper.isCountryAllowed(land) === false) {
                    land = ""
                }

                this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_NAME(this.kurzprofilStore.getFirmenname)
                this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_STRASSE(this.kurzprofilStore.getStrasse)
                this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_HAUSNUMMER(this.kurzprofilStore.getHausnummer)
                this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_PLZ(this.kurzprofilStore.getPlz)
                this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_ORT(this.kurzprofilStore.getOrt)
                this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_LAND(land)
                this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_USTID(this.kurzprofilStore.getUmsatzsteuerId)
                this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_EMAIL(this.getCustomerEmail())
                this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_KOMMUNIKATIONSSPRACHE(this.gewaehlteKommunikationsSprache)
            },
            async start () {
                await this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_CRID(this.userStore.user.cr_id)
                await this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_KOMMUNIKATIONSSPRACHE(this.gewaehlteKommunikationsSprache)
                const payload = { rechnungsadresse: this.abonnementStore.rechnungsadresse }

                await this.abonnementStore.createRechnungsadresseForDigitalesMitglied(payload)

                if (!this.abonnementStore.ajaxError) {
                    try {
                        WrapperWebtrekk.trackAction("meine_bonitaet_frontend_action_zahlungsturnusContinue_for_digitales_mitglied")
                        if (this.userStore.isVertretungsberechtigt) {
                            await this.$router.push({ name: "selbstauskunft-zahlung" })
                        } else {
                            await this.$router.push({ name: "selbstauskunft-verifikation" })
                        }
                    } catch (failure) {
                    // Do not log error, if navigation is redirected to verification
                    }
                }
                window.scroll(0, 0)
            }
        }
    }
</script>

<style scoped lang="less">
    .mt-lg-0 {
        @media only screen and (min-width: 993px) {
            margin-top: 0 !important;
        }
    }

    .radio-label--meine-bonitaet {
        display: inline-block;
        padding-left: 10px !important;
        max-width: calc(100% - 50px);
    }

    .crefo-input {
        .has-changed {
            color: #8acac1 !important;
        }

        .input-group {
            .input-wrapper {
                .crefo-radio {
                    display: block;
                    height: auto;
                }

                i {
                    top: 90% !important;
                    display: inline-block !important;
                    position: absolute !important;
                    font-size: 15px !important;
                    font-family: "Open Sans", Arial, sans-serif;
                }
            }

            .is-empty {
                border-color: #009ee2;
            }

            &.has-error {
                & > input[type="checkbox"] + label:before {
                    border-color: #e05469;
                }

                label {
                    color: #e05469;
                }

                .error-msg {
                    visibility: visible;
                }
            }
        }
    }

    .crefo-checkbox-error {
        & > input[type="checkbox"] + label:before {
            border-color: #e05469;
        }
    }

    .box-teaser--profile {
        background-color: white;

        img {
            width: 100%;
        }

        & > div {
            a.btn {
                margin-top: 3px;
            }
        }
    }

    .box-teaser {
        margin-top: 38px;

        img {
            width: 100%;
        }

        & > div {
            a.btn {
                margin-top: 3px;
            }
        }
    }

    .bg-brand-light-grey {
        margin-bottom: 15px;
        padding-bottom: 0 !important;
        height: 100%;

        @media only screen and (min-width: 768px) {
            margin-bottom: 20px;
        }
        @media only screen and (min-width: 1200px) {
            margin-bottom: 30px;
        }

        p {
            position: relative;
            padding-left: 2rem;
            margin-bottom: 6px;
            line-height: 22px;

            span {
                vertical-align: top;

                &[data-qa="email"] {
                    word-break: break-all;
                }
            }

            .crefo-brand-icon {
                position: absolute;
                left: 0;
                top: 3px;
            }
        }
    }
</style>

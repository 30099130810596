import Vue from 'vue'
import VueRouter from 'vue-router'
import pinia from '@/stores'
import { useUserStore } from '@/stores/user'
import { dispatchEvent } from '@/helper/polyfill'
import { isRouteAllowed, redirectToNextStep } from '@/helper/route-helper'
import Index from '@/views/index'
import SelbstauskunftErwerben from '@/views/selbstauskunft-erwerben'
import SelbstauskunftVerifikation from '@/views/selbstauskunft-verifikation'
import VerifikationPruefseite from '@/views/verifikation-pruefseite'
import SelbstauskunftAbonnement from '@/views/selbstauskunft-abonnement'
import SelbstauskunftNichtVerfuegbar from '@/views/selbstauskunft-nicht-verfuegbar'
import SelbstauskunftAusland from '@/views/selbstauskunft-ausland'
import SelbstauskunftShow from '@/views/selbstauskunft-show'
import SelbstauskunftRegistrierung from '@/views/selbstauskunft-registrierung'
import SelbstauskunftZahlungsturnus from '@/views/selbstauskunft-zahlungsturnus'
import SelbstauskunftZahlungsvorgang from '@/views/selbstauskunft-zahlungsvorgang'
import CrefopayErfolgsseite from '@/views/crefopay-erfolgsseite'
import CrefopayFehlerseite from '@/views/crefopay-fehlerseite'
import CrefopayMockPay from '@/views/crefopay-mock-pay'
import PageNotFound from '@/views/page-not-found'

Vue.use(VueRouter)

const BASE_PATH_MICROFRONTEND_CONTAINER = `/${process.env.VUE_APP_BASE_PATH}`
const PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP = process.env.VUE_APP_PUBLIC_ENTRY_ROUTE

const setIsLoading = (to, from, isLoading) => {
    if (from && from.meta) {
        from.meta.isLoading = isLoading
    }
    if (to && to.meta) {
        to.meta.isLoading = isLoading
    }
}

const isAllowed = (to, from, next) => {
    isRouteAllowed(from, to, true, 2000)
        .then(validRoutesAndNextSteps => {
            setIsLoading(to, from, false)
            next()
        })
        .catch(validRoutesAndNextSteps => {
            setIsLoading(to, from, false)
            redirectToNextStep(next, validRoutesAndNextSteps, from)
        })
}

const isAuthenticatedAndAllowed = (to, from, next) => {
    const userStore = useUserStore(pinia)

    const isAuthenticated = (to, from, next, timeout = 100, timeElapsed = 0) => {
        if (userStore.authenticated) {
            isAllowed(to, from, next)
        } else if (timeElapsed >= 4000 && (window.keycloak && window.keycloak.token)) {
            userStore.ON_VALID_TOKEN_EXISTS(window.keycloak)
            isAllowed(to, from, next)
        } else if (timeElapsed >= 4000) {
            dispatchEvent('redirectToLogin')
        } else {
            timeout = Math.max(Math.min(timeout, 4000 - timeElapsed), 1)
            setTimeout(() => {
                isAuthenticated(to, from, next, timeout * 2, timeElapsed + timeout)
            }, timeout)
        }
    }

    isAuthenticated(to, from, next)
}

const routes = [
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/`,
        name: 'index',
        component: Index,
        beforeEnter: isAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/unit-test`,
        name: 'unit-test',
        component: Index,
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/selbstauskunft-erwerben`,
        name: 'selbstauskunft-erwerben',
        component: SelbstauskunftErwerben,
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/selbstauskunft-erwerben/verifikation`,
        name: 'selbstauskunft-verifikation',
        component: SelbstauskunftVerifikation,
        props: route => ({
            verified: route.params.verified,
            zahlungsturnus: route.params.zahlungsturnus
        }),
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/verifikation/verifiziert/:zahlungsturnus?`,
        name: 'verifikation-pruefseite-success',
        component: VerifikationPruefseite,
        props: route => ({
            verified: true,
            zahlungsturnus: route.params.zahlungsturnus
        }),
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/verifikation/vb-verifikation-fehler/:zahlungsturnus?`,
        name: 'verifikation-pruefseite-error',
        component: VerifikationPruefseite,
        props: route => ({
            verified: false,
            zahlungsturnus: route.params.zahlungsturnus
        }),
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/selbstauskunft-erwerben/abonnement`,
        name: 'selbstauskunft-abonnement',
        component: SelbstauskunftAbonnement,
        props: route => ({
            verified: route.params.verified
        }),
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/selbstauskunft-nicht-verfuegbar`,
        name: 'selbstauskunft-nicht-verfuegbar',
        component: SelbstauskunftNichtVerfuegbar,
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/selbstauskunft-ausland`,
        name: 'selbstauskunft-ausland',
        component: SelbstauskunftAusland,
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/firmenprofil/:aboId?`,
        name: 'selbstauskunft-show',
        component: SelbstauskunftShow,
        props: route => ({
            verified: route.params.verified
        }),
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/selbstauskunft-erwerben/jetzt-loslegen`,
        name: 'selbstauskunft-registrierung',
        component: SelbstauskunftRegistrierung,
        props: route => ({
            preselectedCrefonummer: route.query.crefonummer
        }),
        beforeEnter: isAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/selbstauskunft-erwerben/zahlungsturnus`,
        name: 'selbstauskunft-zahlungsturnus',
        component: SelbstauskunftZahlungsturnus,
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/selbstauskunft-erwerben/zahlung`,
        name: 'selbstauskunft-zahlung',
        component: SelbstauskunftZahlungsvorgang,
        props: route => ({
            merchantId: route.query.merchantID,
            storeId: route.query.storeID,
            orderId: route.query.orderID,
            paymentMethod: route.query.paymentMethod,
            paymentInstrumentId: route.query.paymentInstrumentID
        }),
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/crefopay/zahlungsvorgang-erfolgreich`,
        name: 'crefopay-erfolgsseite',
        component: CrefopayErfolgsseite,
        props: route => ({
            merchantId: route.query.merchantID,
            storeId: route.query.storeID,
            orderId: route.query.orderID,
            paymentReference: route.query.paymentReference,
            merchantReference: route.query.merchantReference
        }),
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/crefopay/zahlungsvorgang-nicht-erfolgreich`,
        name: 'crefopay-fehlerseite',
        component: CrefopayFehlerseite,
        props: route => ({
            merchantId: route.query.merchantID,
            storeId: route.query.storeID,
            orderId: route.query.orderID,
            paymentReference: route.query.paymentReference,
            merchantReference: route.query.merchantReference,
            paymentInstrumentUrl: route.query.paymentInstrumentUrl,
            resultCode: route.query.resultCode
        }),
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/crefopay/mock-pay`,
        name: 'crefopay-mock-pay',
        component: CrefopayMockPay,
        beforeEnter: isAuthenticatedAndAllowed
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/*`,
        name: 'page-not-found',
        component: PageNotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: '/', // this is crucial in order to rewrite links to the single-spa container!
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (!to.hash) {
            window.scroll(0, 0)
        }
    }
})

// Pass through jwt query string and step for vertretungsberechtigung
router.beforeEach((to, from, next) => {
    setIsLoading(to, from, true)

    if (from && from.params && from.params.step) {
        to.params.step = from.params.step
    }

    next()
})

router.afterEach((to, from) => {
    setIsLoading(to, from, false)
})

export default router

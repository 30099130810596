<template>
    <div class="container">
        <div class="row mb-large">
            <div class="col-12 col-lg-7 col-xl-8">
                <div v-if="isMeineBonitaetAvailable">
                    <intro-text
                        class="mb-small"
                        :long-intro="false"
                    />
                    <p>
                        <strong>{{ $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedIn.JetztAbonnieren') }}</strong>
                    </p>
                    <crefo-kurzprofil
                        :kurzprofil="kurzprofil"
                        :crefonummer="crefonummer"
                        :show-header="false"
                        :show-country="true"
                        :translations="{ 'Kurzprofil.Label.Kurzprofil': $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedIn.KurzprofilHeader') }"
                        design="BlueBoxVertical"
                        class="mb"
                    />
                    <button
                        class="btn btn-default mb-large"
                        data-qa="start-checkout"
                        @click.prevent="onCheckout"
                    >
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedIn.AboButtonText') }}
                    </button>
                    <h3 class="mb-small">{{ $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedIn.HeaderFalscheFirma') }}</h3>
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedIn.TextFalscheFirma') }}
                    </p>
                    <a
                        href="https://www.creditreform.de/creditreform/kontakt"
                        role="button"
                        class="btn btn-default btn-min btn-small btn-purple mb-large"
                        data-qa="redirect-register-ubo"
                    >
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedIn.ButtonTextFalscheFirma') }}
                    </a>
                </div>
                <produkt-nicht-verfuegbar
                    v-else
                    :authenticated="true"
                    :crefonummer="crefonummer"
                    :kurzprofil="kurzprofil"
                    :box-spacing="false"
                />
            </div>
            <div class="col-12 col-lg-5 col-xl-4">
                <produkt-information>
                    <button
                        v-if="isMeineBonitaetAvailable"
                        class="btn btn-default mb"
                        data-qa="start-checkout"
                        @click.prevent="onCheckout"
                    >
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingLoggedIn.AboButtonText') }}
                    </button>
                </produkt-information>
            </div>
        </div>
    </div>
</template>

<script>
    import IntroText from './intro-text'
    import CrefoKurzprofil from '@dm/crefo-kurzprofil'
    import ProduktInformation from './produkt-information'
    import ProduktNichtVerfuegbar from './produkt-nicht-verfuegbar'
    import WrapperWebtrekk from '@/helper/webtrekk-wrapper'
    import GeneralUtilityHelper from '@/helper/general'
    export default {
        name: 'LandingLoggedIn',
        components: { IntroText, CrefoKurzprofil, ProduktInformation, ProduktNichtVerfuegbar },
        props: {
            crefonummer: {
                type: String,
                required: false,
                default: ''
            },
            kurzprofil: {
                required: false,
                default: null
            }
        },
        computed: {
            isMeineBonitaetAvailable () {
                return this.kurzprofil
                    && this.kurzprofil.adresse
                    && GeneralUtilityHelper.isCountryAllowed(this.kurzprofil.adresse.land)
                    && this.kurzprofil.selbstauskunftVerfuegbar
                    && this.crefonummer
            }
        },
        methods: {
            onCheckout () {
                WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_LandingpageContinue_for_digitales_mitglied')
                this.$router.push({ name: 'selbstauskunft-zahlungsturnus' })
            }
        }
    }
</script>

<style scoped lang="less">
    a {
        &.btn-purple {
            background-color: #a763a4!important;

            &:hover {
                background-color: #894d87!important;
            }
        }

        &.btn.btn-small,button.btn-small {
            font-size: 14px!important;
            padding: 0 15px;
            min-height: 32px;
            line-height: 32px
        }
    }
</style>

<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li>
                    <a href="/">{{ $t("shared.breadcrumbs.home") }}</a>
                </li>
                <li>
                    <router-link :to="{ name: 'index' }">
                        {{ $t("Appname") }}
                    </router-link>
                </li>
                <li data-qa="headline">
                    {{ $t("Breadcrumbs.Selbstauskunft") }}
                </li>
            </crefo-breadcrumbs>

            <div class="row">
                <div class="col-12">
                    <h1>{{ $t("Appname") }}</h1>
                    <crefo-error-message v-if="showVerifiedMessage && !isLoading" type="success" class="mb-large">
                        <span class="alert-close" @click.prevent="showVerifiedMessage = false" />
                        {{ $t('Verifikation.Message.Erfolgreich') }}
                    </crefo-error-message>
                    <crefo-ring-loading v-if="isLoading" additional-classes="mt" />
                    <crefo-kurzprofil
                        v-if="!isLoading && !kurzprofilStore.ajaxError"
                        :kurzprofil="kurzprofilStore.kurzprofil"
                        :crefonummer="userStore.crefonummer"
                        :show-body="false"
                        :ajax-error="kurzprofilStore.ajaxError"
                        design="WhiteBoxVertical"
                    />
                </div>
            </div>
        </div>

        <template v-if="!isLoading">
            <div class="bg-brand bg-brand-light-grey big-box-spacing mb-large">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <crefo-kurzprofil
                                :kurzprofil="kurzprofilStore.kurzprofil"
                                :crefonummer="userStore.crefonummer"
                                :show-header="false"
                                :show-country="true"
                                :ajax-error="kurzprofilStore.ajaxError"
                                design="WhiteBoxVertical"
                                class="mb"
                            />
                        </div>
                        <div class="col-12 col-lg-8">
                            <abonnement-status class="mb" />
                            <selbstauskunft-show-historie />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mb-large">
                <div class="row">
                    <div class="col-12">
                        <selbstauskunft-action-buttons :is-member-user="userStore.isMemberUser" />
                    </div>
                </div>
            </div>
            <div class="bg-brand bg-brand-light-grey big-box-spacing mb-large">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <selbstauskunft-action-tabs
                                :abonnement="abonnementStore.selectedAbonnement"
                                :stop-in-progress="abonnementStore.selectedAbonnementStopping"
                                :stop-request-was-sent="abonnementStore.selectedAbonnementStopped"
                                :stop-request-ajax-error="abonnementStore.selectedAbonnementStopAjaxError"
                                :is-member-user="userStore.isMemberUser"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <tripple-teaser />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    /**
     * This page show a selbstauskunft with historie for the given aboId.
     *
     *  - Redirects to startpage, when no aboId is set
     *  - Redirects to startpage, when user state is not determined (crefonummer, vb & abos)
     *  - Redirects to startpage, when user is not vertretungsberechtigt for given aboId
     *  - Redirects to startpage, if user has no abonnement at all or aboId was not found
     */
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import { useAbonnementStore } from '@/stores/abonnement'
    import { useSelbstauskunftStore } from '@/stores/selbstauskunft'
    import CrefoKurzprofil from "@dm/crefo-kurzprofil"
    import SelbstauskunftShowHistorie from "../components/selbstauskunft/show/historie.vue"
    import SelbstauskunftActionButtons from "../components/selbstauskunft/show/action-buttons"
    import SelbstauskunftActionTabs from "../components/selbstauskunft/show/action-tabs"
    import AbonnementStatus from "../components/abonnement/status.vue"
    import CrefoBreadcrumbs from "../components/helper/crefo-breadcrumbs"
    import CrefoRingLoading from "@dm/crefo-ring-loading"
    import CrefoErrorMessage from "@dm/crefo-error-message"
    import TrippleTeaser from "@/components/commons/tripple-teaser.vue"

    export default {
        name: "SelbstauskunftShow",
        components: {
            TrippleTeaser,
            CrefoKurzprofil,
            SelbstauskunftShowHistorie,
            SelbstauskunftActionButtons,
            SelbstauskunftActionTabs,
            AbonnementStatus,
            CrefoBreadcrumbs,
            CrefoRingLoading,
            CrefoErrorMessage
        },
        beforeRouteUpdate (to, from, next) {
            this.fetchSelbstauskunft(to.params.aboId)
            next()
        },
        props: {
            verified: {
                type: Boolean,
                default: null
            }
        },
        data () {
            const mappPageName = this.getMappPageName()
            return {
                webtrekk: {
                    page: {
                        name: mappPageName
                    }
                },
                showVerifiedMessage: this.verified === true
            }
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore, useAbonnementStore, useSelbstauskunftStore),
            isLoading () {
                return this.selbstauskunftStore.selbstauskuenfteFetching !== false
            }
        },
        created () {
            this.fetchSelbstauskunft(this.$route.params.aboId)
        },
        methods: {
            getMappPageName: function () {
                let hostname = "meine.creditreform.de"
                let path = "/portal/meine-bonitaet/firmenprofil"
                if (window.location && window.location.hostname) {
                    hostname = window.location.hostname
                }
                if (this.$router && this.$router.currentRoute && this.$router.currentRoute.path) {
                    path = this.$router.currentRoute.path.replace(/\/firmenprofil\/.+$/, "/firmenprofil")
                }
                return hostname + path
            },
            fetchSelbstauskunft: function (aboId) {
                if (aboId) {
                    const selectedAbonnementByAboId = this.abonnementStore.getAbonnementVertretungsberechtigtById(aboId)
                    if (selectedAbonnementByAboId) {
                        this.kurzprofilStore.getKurzprofil(selectedAbonnementByAboId.crefonummer)
                            .then(() => {})
                            .catch(e => {})
                        this.userStore.ON_SET_CREFONUMMER_SUCCESS(selectedAbonnementByAboId.crefonummer)
                        this.userStore.ON_SET_VERTRETUNGSBERECHTIGT(true)
                        this.abonnementStore.ON_SELECT_ABONNEMENT(selectedAbonnementByAboId)
                        this.selbstauskunftStore.fetchSelbstauskuenfte(selectedAbonnementByAboId.aboId)
                        return
                    }
                }
                if (this.abonnementStore.selectedAbonnement && this.abonnementStore.selectedAbonnement.aboId !== aboId) {
                    if (this.abonnementStore.getAbonnementVertretungsberechtigtById(this.abonnementStore.selectedAbonnement.aboId)) {
                        this.$router.push({
                            name: "selbstauskunft-show",
                            params: { aboId: this.abonnementStore.selectedAbonnement.aboId }
                        })
                        return
                    }
                }

                window.location.href = "/"
            }
        }
    }
</script>

<style scoped lang="less">
    @media only screen and (max-width: 1329px) {
        .big-box-spacing {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
</style>

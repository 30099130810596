<template>
    <div class="row">
        <div class="col-12">
            <div class="h2">
                {{ $t('Components.Selbstauskunft.Show.ActionButtons.Header') }}
            </div>
        </div>
        <div class="col-12">
            <button
                class="btn btn-default mb-small"
                data-qa="action-button-ubo"
                @click.prevent="onClickUboEdit"
            >
                {{ $t('Components.Selbstauskunft.Show.ActionButtons.Ubo') }}
            </button>
            <button
                class="btn btn-default mb-small"
                data-qa="action-button-bilanzen"
                style="display: none"
            >
                {{ $t('Components.Selbstauskunft.Show.ActionButtons.Bilanzen') }}
            </button>
            <button
                class="btn btn-default mb-small"
                data-qa="action-button-kontakt"
                @click.prevent="onClickContact"
            >
                {{ $t('Components.Selbstauskunft.Show.ActionButtons.Kontakt') }}
            </button>
        </div>
    </div>
</template>

<script>
    import WrapperWebtrekk from '@/helper/webtrekk-wrapper'
    export default {
        name: 'SelbstauskunftActionButtons',
        props: {
            isMemberUser: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            onClickUboEdit: function (event) {
                WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_clickUnternehmensbefragung')
                this.$router.push({ path: '/portal/ubo/' })
            },
            onClickContact: function (event) {
                if (this.isMemberUser) {
                    WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_clickContact_for_mitglied')
                    location.href = this.$t('URL.ContactForm')
                } else {
                    WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_clickContact_for_digitales_mitglied')
                    location.href = 'https://www.creditreform.de/creditreform/kontakt/'
                }
            }
        }
    }
</script>

<style scoped lang="less">
    button {
        width: 295px;
        margin-right: 15px;
        line-height: inherit;

        @media only screen and (max-width: 1199px) {
            margin-right: 10px;
        }

        @media only screen and (max-width: 768px) {
            margin-right: 7.5px;
        }
    }
</style>

<template>
    <div class="row">
        <div class="col-12">
            <div class="steps">
                <div class="step">
                    <div class="circle">
                        <span data-qa="step-1"
                            v-if="step < 1"
                            class="h3 text-white"
                        >1</span>
                        <i
                            v-else
                            class="crefo-ui-icon icon-check icon-color-white"
                            aria-hidden="true"
                        ></i>
                    </div>
                    <div class="description text-small">
                        {{ $t('Progress.Bar.Step.1') }}
                    </div>
                </div>
                <div class="step">
                    <div class="circle">
                        <span data-qa="step-2"
                            v-if="step < 2"
                            class="h3 text-white"
                        >2</span>
                        <i
                            v-else
                            class="crefo-ui-icon icon-check icon-color-white"
                            aria-hidden="true"
                        ></i>
                    </div>
                    <div class="description text-small">
                        {{ $t('Progress.Bar.Step.2') }}
                    </div>
                </div>
                <div class="step">
                    <div class="circle">
                        <span data-qa="step-3"
                            v-if="step < 3"
                            class="h3 text-white"
                        >3</span>
                        <i
                            v-else
                            class="crefo-ui-icon icon-check icon-color-white"
                            aria-hidden="true"
                        ></i>
                    </div>
                    <div class="description text-small">
                        {{ $t('Progress.Bar.Step.3') }}
                    </div>
                </div>
                <div class="step">
                    <div class="circle">
                        <span data-qa="step-4"
                            v-if="step < 4"
                            class="h3 text-white"
                        >4</span>
                        <i
                            v-else
                            class="crefo-ui-icon icon-check icon-color-white"
                            aria-hidden="true"
                        ></i>
                    </div>
                    <div class="description text-small">
                        {{ $t('Progress.Bar.Step.4') }}
                    </div>
                </div>
                <div class="line"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SelbstauskunftOrderProgressBar',
        props: {
            step: {
                type: String,
                default: '0'
            }
        }
    }
</script>

<style scoped lang="less">
    .steps {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;

        .step {
            width: 25%;
            z-index: 5;

            .circle {
                position: relative;
                margin-left: auto;
                margin-right: auto;
                width: 46px;
                height: 46px;
                border: 2px solid #009ee2;
                border-radius: 50%;
                background-color: #009ee2;

                .h3 {
                    margin: 5px 0 0 1px;
                    text-align: center;
                }

                .icon-check {
                    position: absolute;
                    width: 23px;
                    height: 23px;
                    left: 11px;
                    top: 11px;
                }
            }

            .description {
                text-align: center;
            }
        }

        .line {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 22px;
            width: 75%;
            margin-left: 12.5%;
            margin-right: 12.5%;
            border-top-width: 2px;
            border-top-style: solid;
            border-top-color: #009ee2;
        }
    }
</style>

/**
 * @type {object}
 *
 * A cached entry looks like:
 * [identifier] = {
 *     data: {*},
 *     ttl: {number}
 *     created: {number}
 * }
 */
const cache = {}

/**
 * Default TTL.
 *
 * @type {number}
 */
const defaultTtl = 60

/**
 * Get current microseconds.
 *
 * @return {number}
 */
function getCurrentTimestamp() {
    return new Date().getTime()
}

/**
 * Get age of cache entry in seconds.
 *
 * Has uses roundUpSeconds = true, Get uses roundUpSeconds = false
 * That should prevent that a call to has returns true, but the following get
 * would fail because ttl is reached.
 *
 * @param {object} entry
 * @param {boolean} addOneSecond
 * @return {number}
 */
function getAgeOfEntryInSeconds(entry, addOneSecond) {
    let age = Math.ceil((getCurrentTimestamp() - entry.created) / 1000)

    if (addOneSecond) {
        ++age
    }

    return age
}

/**
 * Returns a valid entry or null.
 *
 * @param {string} identifier
 * @param {boolean} [addOneSecond]
 * @return {null|*}
 */
function getValidCacheEntry(identifier, addOneSecond = true) {
    if (typeof identifier !== 'string' || identifier.length === 0) {
        return null
    }

    const entry = cache[identifier]
    if (typeof entry !== 'object' || entry === null) {
        return null
    }

    const ageInSeconds = getAgeOfEntryInSeconds(entry, addOneSecond)
    if (ageInSeconds <= entry.ttl) {
        return entry
    }

    remove(identifier)
    return null
}

/**
 * Remove entry from cache.
 *
 * @param {string} identifier
 * @return {boolean} - true if it was removed, false when entry did not exist
 */
export const remove = (identifier) => {
    if (typeof identifier !== 'string' || identifier.length === 0) {
        return false
    }

    if (typeof cache[identifier] !== 'object' || cache[identifier] === null) {
        return false
    }

    return delete cache[identifier]
}

/**
 * Check if entry is cached and valid.
 *
 * @param {string} identifier
 * @return {boolean}
 */
export const has = (identifier) => {
    return getValidCacheEntry(identifier) !== null
}

/**
 * Get cached entry.
 *
 * @param {string} identifier
 * @return {null|*}
 */
export const get = (identifier) => {
    const entry = getValidCacheEntry(identifier, false)
    let data
    try {
        data = entry === null ? null : JSON.parse(entry.data)
    } catch (e) {
        data = null
    }
    return data
}

/**
 * Puts an entry into the cache.
 *
 * @param {string} identifier
 * @param {*} data
 * @param {number} ttl - defaults to 60 seconds
 * @return {boolean}
 */
export const set = (identifier, data, ttl) => {
    if (typeof identifier !== 'string' || identifier.length === 0) {
        return false
    }
    if (typeof ttl !== 'number' || ttl <= 0) {
        ttl = defaultTtl
    }

    try {
        const entry = { data: JSON.stringify(data), ttl, created: getCurrentTimestamp() }
        cache[identifier] = entry
    } catch (e) {
        return false
    }

    return true
}

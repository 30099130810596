<template>
    <div>
        <div class="container">
            <template v-if="isLoading">
                <crefo-ring-loading additional-classes="mt" />
            </template>
            <template v-else>
                <div class="row">
                    <div class="col-12">
                        <crefo-error-message
                            v-if="showError && currentKurzprofilAjaxError"
                            :ajax-error="currentKurzprofilAjaxError"
                            data-qa="abonnement-error"
                        />
                        <crefo-error-message
                            v-if="showError && currentAbonnementAjaxError && currentKurzprofilAjaxError === null"
                            :ajax-error="currentAbonnementAjaxError"
                            data-qa="abonnement-error"
                            error-prefix="Abonnement"
                        />
                    </div>
                </div>
            </template>

            <div class="row">
                <div class="col-12">
                    <h1 data-qa="information">
                        {{ $t("Components.Selbstauskunft.Order.Partials.Information.Header") }}
                    </h1>
                    <h2
                        class="mb-small"
                        data-qa="corporate-data"
                    >
                        {{ $t("Components.Selbstauskunft.Order.Partials.Information.SubHeader") }}
                    </h2>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-7 col-xl-8 mb">
                    <div class="row mb">
                        <div class="col-12">
                            <div class="crefo-input">
                                <div class="input-group">
                                    <label
                                        for="companyName"
                                        data-qa="company-name-label"
                                    >
                                        {{ $t("InformationView.Label.CompanyName") }}
                                    </label>
                                    <div class="input-wrapper">
                                        <input
                                            id="companyName"
                                            v-model="kurzprofilStore.getFirmenname"
                                            disabled="disabled"
                                            data-qa="company-name"
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <div class="crefo-input">
                                        <div class="input-group">
                                            <label
                                                for="street"
                                                data-qa="street-label"
                                            >
                                                {{ $t("InformationView.Label.Street") }}
                                            </label>
                                            <div class="input-wrapper">
                                                <input
                                                    id="street"
                                                    v-model="kurzprofilStore.getStrasse"
                                                    disabled="disabled"
                                                    data-qa="street"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="crefo-input">
                                        <div class="input-group">
                                            <label
                                                for="housenumber"
                                                data-qa="housenumber-label"
                                            >
                                                {{ $t("InformationView.Label.Housenumber") }}
                                            </label>
                                            <div class="input-wrapper">
                                                <input
                                                    id="housenumber"
                                                    v-model="kurzprofilStore.getHausnummer"
                                                    disabled="disabled"
                                                    data-qa="housenumber"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="crefo-input">
                                <div class="input-group">
                                    <label
                                        for="email"
                                        data-qa="email-label"
                                    >
                                        {{ $t("InformationView.Label.Email") }}
                                    </label>
                                    <div class="input-wrapper">
                                        <input
                                            id="email"
                                            v-model="customerEmail"
                                            disabled="disabled"
                                            data-qa="email"
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="crefo-input">
                                        <div class="input-group">
                                            <label
                                                for="postcode"
                                                data-qa="postcode-label"
                                            >
                                                {{ $t("InformationView.Label.Postcode") }}
                                            </label>
                                            <div class="input-wrapper">
                                                <input
                                                    id="postcode"
                                                    v-model="kurzprofilStore.getPlz"
                                                    disabled="disabled"
                                                    data-qa="postcode"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="crefo-input">
                                        <div class="input-group">
                                            <label
                                                for="city"
                                                data-qa="city-label"
                                            >
                                                {{ $t("InformationView.Label.City") }}
                                            </label>
                                            <div class="input-wrapper">
                                                <input
                                                    id="city"
                                                    v-model="kurzprofilStore.getOrt"
                                                    disabled="disabled"
                                                    data-qa="city"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="crefo-input">
                                <div class="input-group">
                                    <label
                                        for="country"
                                        data-qa="country-label"
                                    >
                                        {{ $t("InformationView.Label.Country") }}
                                    </label>
                                    <div class="input-wrapper">
                                        <input
                                            id="country"
                                            disabled="disabled"
                                            :value="kurzprofilStore.getLandAsName"
                                            data-qa="country"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <report-language-selection
                        class="mb"
                        :preselected-report-language="abonnementStore.reportsprache"
                        :show-validation-error="abonnementStartTried"
                        @update:reportLanguage="updateReportLanguage"
                    />
                    <div class="row">
                        <div class="col-12">
                            <h2 class="mb-small">{{ $t("Components.Selbstauskunft.Order.Partials.Information.PaymentHeader") }}</h2>
                            <p>
                                {{ $t("Components.Selbstauskunft.Order.Partials.Information.PaymentText") }}
                            </p>
                            <div class="crefo-input">
                                <div
                                    class="input-group"
                                    :class="{ 'has-error': termOfServiceNotAccepted }"
                                >
                                    <div class="checkbox-group">
                                        <div class="input-wrapper">
                                            <div :class="{ 'crefo-checkbox-error': termOfServiceNotAccepted }">
                                                <input
                                                    id="tos"
                                                    v-model="acceptTermOfService"
                                                    type="checkbox"
                                                    data-qa="accept-term-of-service"
                                                >
                                                <i18n
                                                    path="Components.Selbstauskunft.Order.Partials.Information.AgbText"
                                                    tag="label"
                                                    for="tos"
                                                    data-qa="accept-term-of-service-text"
                                                >
                                                    <dynamic-a-tag
                                                        href-template="{PUBLIC_PATH}assets/doc/AGB-MB-{LANG}.pdf"
                                                        data-qa="agb-download-link"
                                                    >
                                                        <span>{{ $t("Components.Selbstauskunft.Order.Partials.Information.AgbLink") }}</span>
                                                    </dynamic-a-tag>
                                                </i18n>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="error-msg"
                                        data-qa="error-msg"
                                    >
                                        <span data-qa="error-msg-text">
                                            {{ $t("Abonnement.Error.AGBsNichtAkzepiert") }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="info-button-group pt-small">
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-12 col-xl-6 mb">
                                        <slot />
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-12 col-xl-6 mb back-link-holder">
                                        <router-link
                                            :to="{ name: 'index' }"
                                            class="link-icon-prev"
                                            role="button"
                                            data-qa="back-button"
                                        >
                                            <span>{{ $t("Abonnement.Label.Zurueck") }}</span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-5 col-xl-4">
                    <div class="box-shadow-xy box-teaser">
                        <img
                            src="@/assets/img/bild_tablet-auskunft.jpg"
                            alt=""
                        >
                        <div class="box-spacing">
                            <h3 class="mb-small">
                                {{ $t("Components.Selbstauskunft.Order.Partials.Information.TeaserHeader") }}
                            </h3>
                            <ul class="list-unordered list-checked">
                                <li>{{ $t("Components.Selbstauskunft.Order.Partials.Information.TeaserReason1") }}</li>
                                <li>{{ $t("Components.Selbstauskunft.Order.Partials.Information.TeaserReason2") }}</li>
                                <li>{{ $t("Components.Selbstauskunft.Order.Partials.Information.TeaserReason3") }}</li>
                            </ul>
                            <div>
                                <p>
                                    <strong>
                                        {{
                                            $t("Components.Selbstauskunft.Order.Partials.Information.TeaserMonatlich")
                                        }}
                                    </strong>
                                </p>
                                <p class="h2 mb-small px-small">
                                    {{ $t("Components.Selbstauskunft.Order.Partials.Information.TeaserMonatlichBetrag")
                                    }}<sup>*</sup>
                                </p>
                                <p>
                                    <sup>*</sup>
                                    {{
                                        $t(
                                            "Components.Selbstauskunft.Order.Partials.Information.TeaserMonatlichSternchen"
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-brand bg-brand-blue box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center">
                            {{ $t("Components.Selbstauskunft.Order.Partials.Information.GruendeHeader") }}
                        </h2>
                    </div>
                </div>
                <div class="row row-reasons">
                    <div class="col-12 col-lg-4 mb">
                        <img
                            src="@/assets/img/icon-24-7.png"
                            alt=""
                        >
                        <p class="h2 pt-small mb-small">
                            {{ $t("Components.Selbstauskunft.Order.Partials.Information.Gruende1Line1") }}
                        </p>
                        <p>
                            {{ $t("Components.Selbstauskunft.Order.Partials.Information.Gruende1Line2") }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-4 mb">
                        <img
                            src="@/assets/img/icon-contact.png"
                            alt=""
                        >
                        <p class="h2 pt-small mb-small">
                            {{ $t("Components.Selbstauskunft.Order.Partials.Information.Gruende2Line1") }}
                        </p>
                        <p>
                            {{ $t("Components.Selbstauskunft.Order.Partials.Information.Gruende2Line2") }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-4 mb">
                        <i
                            class="crefo-ui-icon icon-circle-success icon-color-white"
                            aria-hidden="true"
                        />
                        <p class="h2 pt-small mb-small">
                            {{ $t("Components.Selbstauskunft.Order.Partials.Information.Gruende3Line1") }}
                        </p>
                        <p>
                            {{ $t("Components.Selbstauskunft.Order.Partials.Information.Gruende3Line2") }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import { useAbonnementStore } from '@/stores/abonnement'
    import ReportLanguageSelection from "@/components/selbstauskunft/order/partials/report-language-selection.vue"
    import CrefoErrorMessage from "@dm/crefo-error-message"
    import CrefoRingLoading from "@dm/crefo-ring-loading"
    import DynamicATag from "@/components/helper/dynamic-a-tag.vue"

    export default {
        name: "SelbstauskunftOrderInformation",
        components: {
            ReportLanguageSelection,
            CrefoErrorMessage,
            CrefoRingLoading,
            DynamicATag
        },
        props: {
            abonnementStartTried: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore, useAbonnementStore),
            customerEmail: function () {
                if (
                    typeof this.userStore.user.email === "string" &&
                    this.userStore.user.email.length
                ) {
                    return this.userStore.user.email
                }
                return this.kurzprofilStore.getEmail
            },
            acceptTermOfService: {
                get () {
                    return this.abonnementStore.acceptTermOfService
                },
                set (newAcceptTermOfService) {
                    this.abonnementStore.ON_SELECT_TOS(newAcceptTermOfService)
                }
            },
            termOfServiceNotAccepted: function () {
                return this.acceptTermOfService === false
            },
            isLoading: function () {
                return this.abonnementStore.abonnementsFetching === true
            },
            showError: function () {
                let result =
                    (this.abonnementStore.ajaxError !== null && this.abonnementStore.ajaxError.statusCode !== 404) ||
                    this.kurzprofilStore.ajaxError !== null
                return result
            },
            currentKurzprofilAjaxError () {
                return this.kurzprofilStore.ajaxError
            },
            currentAbonnementAjaxError () {
                return this.abonnementStore.ajaxError
            }
        },
        methods: {
            updateReportLanguage (newLanguage) {
                this.abonnementStore.ON_CHANGE_ORDER_REPORTSPRACHE(newLanguage)
            }
        }
    }
</script>

<style scoped lang="less">
    .info-button-group {
        .back-link-holder {
            text-align: right;
            align-self: center;

            @media only screen and (min-width: 992px) and (max-width: 1199px) {
                text-align: left;
            }
            @media only screen and (max-width: 767px) {
                text-align: left;
            }
        }
    }

    .crefo-checkbox-error {
        & > input[type="checkbox"] + label:before {
            border-color: #e05469;
        }
    }

    .box-teaser {
        margin-top: 38px;

        img {
            width: 100%;
        }

        & > div {
            a.btn {
                margin-top: 3px;
            }
        }
    }

    .bg-brand {
        margin-top: 60px;
        margin-bottom: -60px;
        padding-bottom: 0 !important;

        .text-center {
            text-align: center;
        }

        h2,
        p,
        span {
            color: #ffffff;
        }

        @media only screen and (max-width: 1199px) {
            h2 {
                margin-bottom: 30px;
            }
        }

        .row-reasons {
            & > div {
                text-align: center;

                i {
                    display: inline-block;
                    font-size: 73px;
                }

                img {
                    width: 73px;
                    height: 73px;
                }

                span {
                    line-height: 1.7;
                }
            }
        }
    }

    .bg-brand-light-grey {
        height: 100%;

        p {
            position: relative;
            padding-left: 38px;
            margin-bottom: 6px;
            line-height: 22px;

            span {
                vertical-align: top;
                &[data-qa="email"] {
                    word-break: break-all;
                }
            }

            .crefo-brand-icon {
                position: absolute;
                left: 0;
                top: 3px;
            }
        }
    }
</style>

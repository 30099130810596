<template>
    <span>
        <a
            v-if="download === 'sample'"
            :href="`${publicPath}assets/doc/${filename}-${languageVariant}.pdf`"
            class="example-download"
            data-qa="example-download-button"
        >
            <i class="crefo-brand-icon icon-download" />
            <span>{{ $t('Components.Selbstauskunft.Order.Landingpage.ExampleDownload.ButtonText') }}</span>
        </a>

        <a
            v-if="download === 'agb'"
            :href="`${publicPath}assets/doc/${filename}-${languageVariant}.pdf`"
            target="_blank"
            data-qa="agb-link"
        >
            <i class="crefo-brand-icon icon-download" />
            <span>{{ $t('Components.Selbstauskunft.Order.Partials.AgbDigimon.Agb') }}</span>
        </a>
    </span>
</template>

<script>
    export default {
        name: 'DownloadSample',
        props: {
            filename: {
                type: String,
                default: null
            },
            download: {
                type: String,
                default: null
            },
            defaultLanguage: {
                type: String,
                default: 'de'
            },
            allowedLanguages: {
                type: Array,
                default: () => [ 'de', 'de-at', 'en', 'fr', 'fr-lu' ]
            }
        },
        data () {
            return {
                publicPath: process.env.BASE_URL || ''
            }
        },
        computed: {
            languageVariant () {
                let websiteLanguage = this.$i18n.locale.toLowerCase().split('_')
                if (websiteLanguage.length === 2 && websiteLanguage[0] !== websiteLanguage[1] && websiteLanguage[0] !== 'en') {
                    websiteLanguage = websiteLanguage[0] + '-' + websiteLanguage[1]
                } else {
                    websiteLanguage = websiteLanguage[0]
                }

                if (this.allowedLanguages.includes(websiteLanguage)) {
                    return websiteLanguage
                } else {
                    return this.defaultLanguage
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .example-download {
        display: block;

        .crefo-brand-icon {
            position: relative;
            top: 5px;
        }

        span {
            padding-left: 10px;
        }
    }
</style>

<template>
    <div class="bg-brand bg-brand-blue box-spacing">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-center">
                        {{ $t('Components.Selbstauskunft.Order.Partials.VorteileMBTeaser.NichtUeberzeugt') }}
                    </h2>
                </div>
            </div>
            <div class="row row-reasons">
                <div class="col-12 col-lg-4 mb">
                    <img src="@/assets/img/icon-24-7.png" alt="" />
                    <p class="h2 pt-small mb-small">
                        {{ $t('Components.Selbstauskunft.Order.Partials.VorteileMBTeaser.NichtsVerpassen') }}
                    </p>
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.Partials.VorteileMBTeaser.NichtsVerpassenText') }}
                    </p>
                </div>
                <div class="col-12 col-lg-4 mb">
                    <img src="@/assets/img/icon-contact.png" alt="" />
                    <p class="h2 pt-small mb-small">
                        {{ $t('Components.Selbstauskunft.Order.Partials.VorteileMBTeaser.24Service') }}
                    </p>
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.Partials.VorteileMBTeaser.24ServiceText') }}
                    </p>
                </div>
                <div class="col-12 col-lg-4 mb">
                    <i class="crefo-ui-icon icon-circle-success icon-color-white" aria-hidden="true"></i>
                    <p class="h2 pt-small mb-small">
                        {{ $t('Components.Selbstauskunft.Order.Partials.VorteileMBTeaser.Update')}}
                    </p>
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.Partials.VorteileMBTeaser.UpdateText') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VorteileMeineBonitaetTeaser'
    }
</script>

<style scoped lang="less">
    .bg-brand {
        margin-bottom: -60px;
        padding-bottom: 0 !important;

        .text-center {
            text-align: center;
        }

        h2, p, span {
            color: #ffffff;
        }

        @media only screen and (max-width: 1199px) {
            h2 {
                margin-bottom: 30px;
            }
        }

        .row-reasons {
            & > div {
                text-align: center;

                i {
                    display: inline-block;
                    font-size: 73px;
                }

                img {
                    width: 73px;
                    height: 73px;
                }

                span {
                    line-height: 1.7;
                }
            }
        }
    }
</style>

<template>
    <div>
        <div class="row mb">
            <div class="col-12 col-lg-10 col-xl-8">
                <p class="h3">
                    {{ $t('Components.Selbstauskunft.Show.TabFeedback.Header') }}
                </p>
                <i18n
                    path="Components.Selbstauskunft.Show.TabFeedback.Text"
                    tag="p"
                >
                    <br>
                </i18n>
            </div>
        </div>
        <div class="row mb-small">
            <div class="col-12 col-xl-10">
                <!-- im i18n Ordner befinden sich Übersetzungen die die Übersetzungen des npm Paketes crefo-feedback überschreiben,
                die man an dieser Stelle nicht sehen kann -->
                <crefo-feedback-form
                    design="White"
                    context="meine-bonitaet"
                    betreff="Meine Bonität"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import CrefoFeedbackForm from '@dm/crefo-feedback-form'
    export default {
        name: 'SelbstauskunftActionTabFeedback',
        components: { CrefoFeedbackForm }
    }
</script>

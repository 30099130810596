<template>
    <div>
        <h3 class="mb-small">
            {{ $t('Components.Selbstauskunft.Order.Landingpage.UnternehmenNichtGefunden.Header') }}
        </h3>
        <p>
            {{ $t('Components.Selbstauskunft.Order.Landingpage.UnternehmenNichtGefunden.Text1') }}
        </p>
        <p>
            {{ $t('Components.Selbstauskunft.Order.Landingpage.UnternehmenNichtGefunden.Text2') }}
        </p>
        <p>
            <a
                href="https://www.creditreform.de/creditreform/kontakt"
                data-qa="company-not-found-contact-link"
            >
                <i
                    class="crefo-ui-icon icon-beratung"
                    style="position: relative; top: 5px;"
                    aria-hidden="true"
                />
                {{ $t('Components.Selbstauskunft.Order.Landingpage.UnternehmenNichtGefunden.Link') }}
            </a>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'UnternehmenNichtGefunden'
    }
</script>

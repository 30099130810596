var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container pt"},[_c('crefo-breadcrumbs',[_c('li',[_c('a',{attrs:{"href":"/"}},[_vm._v(_vm._s(_vm.$t('shared.breadcrumbs.home')))])]),_c('li',[_c('router-link',{attrs:{"to":{ name: 'index' }}},[_vm._v(" "+_vm._s(_vm.$t('Appname'))+" ")])],1),_c('li',{attrs:{"data-qa":"headline"}},[_vm._v(" "+_vm._s(_vm.$t('View.CrefopayFehlerseite.BreadcrumbTitle'))+" ")])]),_c('div',{staticClass:"row mb"},[_c('div',{staticClass:"col-12"},[_vm._m(0),_c('h1',{staticClass:"h2 mb-small",attrs:{"data-qa":"crefopay-bestellung-fehler"}},[(_vm.currentAboId)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('View.CrefopayFehlerseite.AboIdPrefix'))+" "),_c('span',{attrs:{"data-qa":"aboId"}},[_vm._v(_vm._s(_vm.currentAboId))]),_vm._v(" "+_vm._s(_vm.$t('View.CrefopayFehlerseite.AboIdPostfix'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('View.CrefopayFehlerseite.AboIdFehlt'))+" ")])])])])],1),_c('div',{staticClass:"bg-brand bg-brand-blue box-spacing"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-12 col-lg-5 col-xl-4"},[_c('div',{staticClass:"row pt"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t('View.CrefopayFehlerseite.TeaserText'))+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"text-white mb-small"},[_c('span',[_vm._v(_vm._s(_vm.$t('View.CrefopayFehlerseite.ProductTitle'))+" ")]),_c('span',{attrs:{"data-qa":"firmenname"}},[_vm._v(_vm._s(_vm.kurzprofilStore.getFirmenname))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-bold text-brand-yellow",attrs:{"data-qa":"crefopay-error-message"},domProps:{"innerHTML":_vm._s(_vm.errorMessage)}}),_c('div',{staticClass:"h3"},[_c('p',[_c('router-link',{staticClass:"btn btn-default bg-brand-purple",attrs:{"to":{ name: 'selbstauskunft-zahlung', query: {
                                            merchantID: _vm.merchantId,
                                            storeID: _vm.storeId,
                                            orderID: _vm.orderId
                                        }},"role":"button","data-qa":"show-button"}},[_c('span',[_vm._v(_vm._s(_vm.$t('View.CrefopayFehlerseite.ButtonText')))])])],1)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb"},[_c('i',{staticClass:"crefo-brand-icon icon-cross icon-color-red",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-7 col-xl-8 mb"},[_c('div',{staticClass:"box-teaser"},[_c('img',{attrs:{"src":require("@/assets/img/MeineBonitaet-Mockup-Freisteller.png"),"alt":""}})])])
}]

export { render, staticRenderFns }
import HelperWebtrekk from '@dm/helper-webtrekk'

function maskAboId (aboId) {
    return aboId.substr(0, 3)
        + 'XXXXX'
}

function maskCrefonumber (crefonumber) {
    return crefonumber.substr(0, 3)
        + 'XXXX'
        + crefonumber.substr(-3, 3)
            .replace(new RegExp('[0-4]', 'g'), '-')
            .replace(new RegExp('[5-9]', 'g'), '+')
}

const WrapperWebtrekk = {
    trackAction: (name = 'action', parameters = {}) => {
        if (parameters) {
            if (typeof parameters[7] === 'string' && parameters[7].length >= 3) {
                parameters[7] = maskAboId(parameters[7])
            }
            if (typeof parameters[8] === 'string' && parameters[8].length >= 3) {
                parameters[8] = maskCrefonumber(parameters[8])
            }
        }
        HelperWebtrekk.trackAction(name, parameters)
    },
    trackGoal: (name = 'goal', goalParameters = {}, actionParameters = null) => {
        if (actionParameters) {
            if (typeof actionParameters[7] === 'string' && actionParameters[7].length >= 3) {
                actionParameters[7] = maskAboId(actionParameters[7])
            }
            if (typeof actionParameters[8] === 'string' && actionParameters[8].length >= 3) {
                actionParameters[8] = maskCrefonumber(actionParameters[8])
            }
        }
        HelperWebtrekk.trackGoal(name, goalParameters, actionParameters)
    }
}

export default WrapperWebtrekk

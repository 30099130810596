export const CrefopayHelper = (() => {
    let defaultJQueryUrl = 'https://code.jquery.com/jquery-3.6.0.slim.min.js'
    let defaultCrefoPaySecureFieldsUrl = 'https://api.crefopay.de/libs/3.0/secure-fields.js'
    let defaultShopPublicKey = '5f09e93f2c1e3c2371340598438dbeb3a503df89265a9a260a946f3ccc1080ca'
    let defaultOrderID = 'orderID'
    let defaultConfiguration = {
        url: 'https://api.crefopay.de/secureFields/',
        placeholders: {
            accountHolder: '',
            number: '',
            cvv: ''
        }
    }
    let initLibrariesDone = false
    let secureFieldsClientInstance = null

    function initLibraries(loadScript, jQueryUrl, crefoPaySecureFieldsUrl) {
        return new Promise((resolve, reject) => {
            let loadedCount = 0
            let failedCount = 0

            const handleLoading = (result, error = null) => {
                if (loadedCount >= 2 || failedCount > 0) {
                    return
                } else if (result) {
                    ++loadedCount
                    if (loadedCount === 2) {
                        console.log('CrefoPay loading libraries success')
                        initLibrariesDone = true
                        resolve()
                    }
                } else {
                    console.log('CrefoPay loading libraries failed')
                    ++failedCount
                    reject(error)
                }
            }

            if (typeof loadScript !== 'function') {
                return reject(null)
            }

            if (typeof jQuery === 'function') {
                handleLoading(true)
            } else {
                loadScript(jQueryUrl)
                    .then(() => handleLoading(true))
                    .catch((error) => handleLoading(false, error))
            }
            if (typeof SecureFieldsClient === 'function') {
                handleLoading(true)
            } else {
                loadScript(crefoPaySecureFieldsUrl)
                    .then(() => handleLoading(true))
                    .catch((error) => handleLoading(false, error))
            }

            setTimeout(() => {
                if (!initLibrariesDone) {
                    console.log('CrefoPay loading libraries timeout')
                    handleLoading(false)
                }
            }, 120000)
        })
    }

    function unloadLibraries (unloadScript, jQueryUrl, crefoPaySecureFieldsUrl) {
        if (typeof unloadScript === 'function') {
            if (typeof SecureFieldsClient === 'function') {
                unloadScript(crefoPaySecureFieldsUrl).then(() => {}).catch(() => {})
            }
            if (typeof jQuery === 'function') {
                unloadScript(jQueryUrl).then(() => {}).catch(() => {})
            }
            console.log('CrefoPay libraries unloaded')
            initLibrariesDone = false
        }
    }

    function initializeSecureFieldsClient (shopPublicKey, orderID, paymentRegisteredCallback, initializationCompleteCallback, configuration) {
        if (typeof SecureFieldsClient !== 'function') {
            console.log('CrefoPay initialization failed because of missing SecureFieldsClient')
            if (typeof initializationCompleteCallback === 'function') {
                initializationCompleteCallback({ resultCode: 1000 })
            }
            return null
        } else {
            let initCallbackCalled = false
            secureFieldsClientInstance = new SecureFieldsClient(
                shopPublicKey,
                orderID,
                (response) => {
                    if (typeof response !== 'object' || response === null) {
                        console.log('CrefoPay payment registration failed without response')
                    } else if (response.resultCode !== 0) {
                        console.log(`CrefoPay payment registration failed with result code ${response.resultCode}`)
                    } else {
                        console.log('CrefoPay payment successfully registered')
                        console.log(`CrefoPay payment instrument id is: ${response.paymentInstrumentId}`)
                    }
                    if (typeof paymentRegisteredCallback === 'function') {
                        paymentRegisteredCallback(response)
                    }
                },
                (response) => {
                    initCallbackCalled = true
                    if (typeof response !== 'object' || response === null) {
                        console.log('CrefoPay initialization failed with no response')
                    } else if (response.resultCode !== 0) {
                        console.log(`CrefoPay initialization failed with result code ${response.resultCode}`)
                    } else {
                        console.log('CrefoPay initialization complete')
                        // console.log(`Session created with token ${response.token}`)
                    }
                    if (typeof initializationCompleteCallback === 'function') {
                        initializationCompleteCallback(response)
                    }
                },
                configuration
            )
            setTimeout(() => {
                if (!initCallbackCalled) {
                    initCallbackCalled = true
                    console.log('CrefoPay initialization possibly failed because of missing/delayed response from CrefoPay')
                    if (typeof initializationCompleteCallback === 'function') {
                        initializationCompleteCallback({ resultCode: 1000 })
                    }
                }
            }, 15000)
            return secureFieldsClientInstance
        }
    }

    function initializeSecureFields (loadScript, jQueryUrl, crefoPaySecureFieldsUrl, shopPublicKey, orderID, paymentRegisteredCallback, initializationCompleteCallback, configuration) {
        if (typeof shopPublicKey === 'string' && shopPublicKey.length) {
            defaultShopPublicKey = shopPublicKey
        }
        if (typeof orderID === 'string' && orderID.length) {
            defaultOrderID = orderID
        }
        if (typeof configuration === 'object' && configuration !== null) {
            defaultConfiguration = configuration
        }

        if (initLibrariesDone) {
            return new Promise(resolve => {
                resolve(initializeSecureFieldsClient(defaultShopPublicKey, defaultOrderID, paymentRegisteredCallback, initializationCompleteCallback, defaultConfiguration))
            })
        } else {
            if (typeof jQueryUrl === 'string' && jQueryUrl.length) {
                defaultJQueryUrl = jQueryUrl
            }
            if (typeof crefoPaySecureFieldsUrl === 'string' && crefoPaySecureFieldsUrl.length) {
                defaultCrefoPaySecureFieldsUrl = crefoPaySecureFieldsUrl
            }
            return new Promise((resolve, reject) => {
                initLibraries(loadScript, defaultJQueryUrl, defaultCrefoPaySecureFieldsUrl)
                    .then(() => resolve(initializeSecureFieldsClient(defaultShopPublicKey, defaultOrderID, paymentRegisteredCallback, initializationCompleteCallback, defaultConfiguration)))
                    .catch(error => {
                        console.log('CrefoPay SecureFields initialization failed while trying loading js libraries')
                        reject(error)
                    })
            })
        }
    }

    function decodeMappedErrorCode (ajaxError) {
        if (ajaxError && ajaxError.response && ajaxError.response.headers) {
            const errorStatusCode = ajaxError.response.headers['errorstatuscode']
            if (typeof errorStatusCode === 'string' && errorStatusCode.length) {
                return errorStatusCode.toUpperCase()
            }
        }

        return null
    }

    return {
        init: (secureFieldsParams = {}) => initializeSecureFields(
            secureFieldsParams.loadScript,
            secureFieldsParams.jQueryUrl,
            secureFieldsParams.crefoPaySecureFieldsUrl,
            secureFieldsParams.shopPublicKey,
            secureFieldsParams.orderID,
            secureFieldsParams.paymentRegisteredCallback,
            secureFieldsParams.initializationCompleteCallback,
            secureFieldsParams.configuration
        ),
        destruct: (unloadScript) => unloadLibraries(unloadScript, defaultJQueryUrl, defaultCrefoPaySecureFieldsUrl),
        registerPayment: () => secureFieldsClientInstance ? secureFieldsClientInstance.registerPayment() : false,
        getMappedErrorCode: (ajaxError) => decodeMappedErrorCode(ajaxError)
    }
})()

<template>
    <div class="row">
        <div class="col-12">
            <h1 data-qa="teaser-intro">{{ $t("Components.TrippleTeaser.Unternehmensbefragung.Intro") }}</h1>
        </div>
        <div class="col-12 col-lg-4 mb">
            <div class="box-shadow-xy" data-qa="teaser-ubo">
                <div class="image-box">
                    <img src="@/assets/svg/service-ubo.png" width="293" height="204" alt="" />
                </div>
                <div class="box-spacing bg-white">
                    <div class="content-box">
                        <p class="h2 mb-small" data-qa="teaser-ubo-headline">
                            {{ $t("Components.TrippleTeaser.Unternehmensbefragung.Headline") }}
                        </p>
                        <p>
                            {{ $t("Components.TrippleTeaser.Unternehmensbefragung.Text") }}
                        </p>
                        <router-link to="/portal/ubo" class="link-icon-next" data-qa="teaser-ubo-link">
                            <span>{{ $t("Components.TrippleTeaser.Link.Text") }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4 mb">
            <div class="box-shadow-xy" data-qa="teaser-my-esg">
                <div class="image-box">
                    <img
                        src="@/assets/svg/service-my-esg.svg"
                        width="270"
                        height="236"
                        alt=""
                        style="position: relative; top: 15px"
                    />
                </div>
                <div class="box-spacing bg-white">
                    <div class="content-box">
                        <p class="h2 mb-small" data-qa="teaser-myesg-headline">
                            {{ $t("Components.TrippleTeaser.MyESG.Headline") }}
                        </p>
                        <p>
                            {{ $t("Components.TrippleTeaser.MyESG.Text") }}
                        </p>
                        <router-link to="/portal/myesg" class="link-icon-next" data-qa="teaser-myesg-link">
                            <span>{{ $t("Components.TrippleTeaser.Link.Text") }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4 mb">
            <div class="box-shadow-xy" data-qa="teaser-firmeneintraege">
                <div class="image-box">
                    <img src="@/assets/svg/service-firmeneintraege.png" width="700" height="352" alt="" />
                </div>
                <div class="box-spacing bg-white">
                    <div class="content-box">
                        <p class="h2 mb-small" data-qa="teaser-firmeneintraege-headline">
                            {{ $t("Components.TrippleTeaser.Firmeneintraege.Headline") }}
                        </p>
                        <p>
                            {{ $t("Components.TrippleTeaser.Firmeneintraege.Text") }}
                        </p>
                        <a
                            href="https://firmeneintrag.creditreform.de/"
                            class="link-icon-next"
                            target="_blank"
                            data-qa="teaser-firmeneintraege-link"
                        >
                            <span>{{ $t("Components.TrippleTeaser.Link.Text") }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TrippleTeaser"
    }
</script>

<style scoped lang="less">
    .image-box {
        display: flex;
        height: 175px;
        max-height: 175px;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        background-color: #f6f6f2;
    }

    .content-box {
        position: relative;
        min-height: 275px;
        max-height: 500px;

        p:last-of-type {
            margin-bottom: 30px;
        }

        .link-icon-next {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
</style>

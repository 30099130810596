<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li>
                    <a href="/">{{ $t("shared.breadcrumbs.home") }}</a>
                </li>
                <li>
                    <router-link :to="{ name: 'index' }">
                        {{ $t("Appname") }}
                    </router-link>
                </li>
                <li data-qa="headline">
                    {{ $t("View.SelbstauskunftZahlungsvorgang.BreadcrumbTitle") }}
                </li>
            </crefo-breadcrumbs>

            <div class="row">
                <div class="col-12">
                    <h1 data-qa="kopfzeile-firmenname">
                        {{ kurzprofilStore.getFirmenname }}
                    </h1>
                </div>
            </div>
        </div>

        <div class="bg-brand-light-grey big-box-spacing mb">
            <div class="container">
                <crefo-ring-loading
                    v-if="isLoading"
                    :wrap-in-row="true"
                    class="mb"
                />

                <div class="row">
                    <div class="col-12">
                        <preis-digimon
                            v-if="!isLoading"
                            :turnus="abonnementStore.paymentCycle"
                            class="mb"
                            :land="abonnementStore.rechnungsadresse.land"
                        />
                    </div>
                    <div class="col-12">
                        <div class="bg-white box-shadow-xy box-spacing mb">
                            <report-language-selection
                                :preselected-report-language="abonnementStore.reportsprache"
                                :show-validation-error="abonnementSubmittingTried"
                                @update:reportLanguage="updateReportLanguage"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <zahlungsvorgang
                            v-if="!isLoading"
                            :rechnungsadresse="abonnementStore.rechnungsadresse"
                            :accept-terms-and-conditions="abonnementStore.acceptTermOfService"
                            :turnus="abonnementStore.paymentCycle"
                            :crefonummer="userStore.crefonummer"
                            :crid="userStore.user.cr_id"
                            :secure-fields-enabled="false"
                            @update:abonnementSubmittingTried="abonnementSubmittingTried = $event"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <a
                        href="#"
                        class="link-icon-prev"
                        role="button"
                        data-qa="abo-bestellen-backLink"
                        @click.prevent="gotoStartpage"
                    >
                        <span>{{ $t("View.SelbstauskunftZahlungsvorgang.ButtonText") }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import { useAbonnementStore } from '@/stores/abonnement'
    import PreisDigimon from "../components/selbstauskunft/order/partials/preis-digimon"
    import Zahlungsvorgang from "../components/selbstauskunft/order/zahlungsvorgang"
    import CrefoBreadcrumbs from "../components/helper/crefo-breadcrumbs"
    import CrefoRingLoading from "@dm/crefo-ring-loading"
    import WrapperWebtrekk from "@/helper/webtrekk-wrapper"
    import ReportLanguageSelection from "@/components/selbstauskunft/order/partials/report-language-selection.vue"

    export default {
        name: "SelbstauskunftZahlungsvorgang",
        components: { CrefoBreadcrumbs, CrefoRingLoading, PreisDigimon, Zahlungsvorgang, ReportLanguageSelection },
        props: {
            merchantId: {
                type: String,
                required: false,
                default: ""
            },
            storeId: {
                type: String,
                required: false,
                default: ""
            },
            orderId: {
                type: String,
                required: false,
                default: ""
            },
            paymentMethod: {
                type: String,
                required: false,
                default: ""
            },
            paymentInstrumentId: {
                type: String,
                required: false,
                default: ""
            }
        },
        data () {
            return {
                abonnementSubmittingTried: false
            }
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore, useAbonnementStore),
            isLoading: function () {
                return !this.userStore.crefonummer
            }
        },
        methods: {
            updateReportLanguage (newLanguage) {
                this.abonnementStore.ON_CHANGE_ORDER_REPORTSPRACHE(newLanguage)
            },
            gotoStartpage () {
                WrapperWebtrekk.trackAction("meine_bonitaet_frontend_action_zahlungsseiteGoBack_for_digitales_mitglied")
                this.abonnementStore.ON_CHANGE_CHECKOUT_STARTED(false)
                this.$router.push({ name: "selbstauskunft-registrierung" })
            }
        }
    }
</script>

<style scoped lang="less">
    .big-box-spacing.mb {
        padding-bottom: 15px !important;

        @media only screen and (min-width: 768px) {
            padding-bottom: 20px !important;
        }

        @media only screen and (min-width: 1200px) {
            padding-bottom: 30px !important;
        }
    }
</style>

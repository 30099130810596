<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
                <li>
                    <router-link :to="{name: 'index'}">
                        {{ $t('Appname') }}
                    </router-link>
                </li>
                <li data-qa="headline">
                    {{ $t('Breadcrumbs.Bestellen.Abonnement') }}
                </li>
            </crefo-breadcrumbs>
            <div class="row">
                <div class="col-12 mb-large">
                    <h1 data-qa="kopfzeile-firmenname">
                        {{ kurzprofilStore.getFirmenname }}
                    </h1>
                    <crefo-error-message
                        v-if="showVerifiedMessage"
                        type="success"
                        class="mb-large"
                    >
                        <span
                            class="alert-close"
                            @click.prevent="showVerifiedMessage = false"
                        />
                        {{ $t('Verifikation.Message.Erfolgreich') }}
                    </crefo-error-message>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12 col-lg-11 col-xl-10 mb-large">
                    <selbstauskunft-order-progress-bar :step="step" />
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb">
                    <hr>
                </div>
            </div>
        </div>
        <div v-if="step === '2'">
            <selbstauskunft-order-information :abonnement-start-tried="abonnementStartTried">
                <button
                    class="btn btn-default btn-min abonnement-button"
                    data-qa="start-button"
                    :disabled="isButtonDisabled"
                    @click.prevent="start"
                >
                    <span data-qa="start-button-label">{{ $t('Abonnement.Label.Bestellen') }}</span>
                </button>
            </selbstauskunft-order-information>
        </div>
        <div v-if="step === '3'">
            <selbstauskunft-order-success :step="step" />
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import { useAbonnementStore } from '@/stores/abonnement'
    import SelbstauskunftOrderInformation from './partials/information'
    import SelbstauskunftOrderSuccess from './partials/success'
    import SelbstauskunftOrderProgressBar from './partials/progress-bar'
    import CrefoBreadcrumbs from '../../helper/crefo-breadcrumbs'
    import CrefoErrorMessage from "@dm/crefo-error-message"

    export default {
        name: 'SelbstauskunftOrderAbonnement',
        components: {
            SelbstauskunftOrderSuccess,
            SelbstauskunftOrderInformation,
            SelbstauskunftOrderProgressBar,
            CrefoBreadcrumbs,
            CrefoErrorMessage
        },
        props: {
            verified: {
                type: Boolean,
                default: null
            }
        },
        data () {
            return {
                showVerifiedMessage: this.verified === true,
                abonnementStartTried: false
            }
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore, useAbonnementStore),
            isReportSpracheEmpty () {
                return typeof this.abonnementStore.reportsprache !== 'string' || this.abonnementStore.reportsprache === ''
            },
            isFormValid () {
                return this.abonnementStore.acceptTermOfService === true && !this.isReportSpracheEmpty
            },
            isButtonDisabled: function () {
                return this.abonnementStore.abonnementsFetching === true || (this.abonnementStartTried && !this.isFormValid)
            },
            step: function (){
                let currentStep = '2'
                if (this.abonnementStore.abonnementsAvailable === true){
                    currentStep = '3'
                }
                return currentStep
            }
        },
        methods: {
            start () {
                this.abonnementStartTried = true
                if (this.isFormValid) {
                    const payload = {
                        "crid": this.userStore.user.cr_id,
                        "crefonummer": this.userStore.crefonummer,
                        "agbsAkzeptiert": this.abonnementStore.acceptTermOfService,
                        "reportsprache": this.abonnementStore.reportsprache
                    }
                    this.abonnementStore.RESET_ABONNEMENTS_STATE()
                    this.abonnementStore.createAbonnementForMitglied(payload)
                    window.scroll(0, 0)
                } else {
                    this.abonnementStore.ON_SELECT_TOS(false)
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .abonnement-button {
        text-align: center;
        display: block;
        line-height: normal;
        padding-top: 13.5px;
        padding-bottom: 13.5px;
    }
</style>

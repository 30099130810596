<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
                <li data-qa="headline">
                    {{ $t('Appname') }}
                </li>
            </crefo-breadcrumbs>
        </div>

        <div class="container">
            <crefo-ring-loading additional-classes="mt" />
        </div>
    </div>
</template>

<script>
    /**
     * The startpage only detects the user state and then decides for forwarding him to:
     *  - Show the existing selbstauskunft for the current crefonummer
     *  - Give the user a chance to order a new selbstauskunft for the current crefonummer
     *  - Give the user a chance to get in contact with his VC either Germany or not supported Countries
     */
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import CrefoBreadcrumbs from '../components/helper/crefo-breadcrumbs'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    import { getValidRoutesAndRedirectToNextStep } from '../helper/route-helper'

    export default {
        name: 'Index',
        components: { CrefoBreadcrumbs, CrefoRingLoading },
        beforeRouteUpdate (to, from, next) {
            this.redirect()
            next()
        },
        computed: {
            ...mapStores(useUserStore)
        },
        created () {
            this.redirect()
        },
        methods: {
            redirect: function () {
                getValidRoutesAndRedirectToNextStep(
                    newRoute => this.$router.push(newRoute),
                    null,
                    this.$router.currentRoute,
                    true,
                    this.userStore.isUserStateDetermined ? 50 : 4000
                )
            }
        }
    }
</script>

<template>
    <div class="row">
        <div class="col-12">
            <div class="crefo-ui-tabbedcontent" data-crefo-ui="true">
                <div class="tabs-wrapper">
                    <span
                        class="tab"
                        :class="{ active: activeTab === 1 }"
                        data-qa="tab-abonnement-kuendigen"
                        @click.prevent="activateTab(1)"
                    >
                        {{ $t('Components.Selbstauskunft.Show.ActionTabs.Abonnement') }}
                    </span>
                    <span
                        v-if="!isMemberUser"
                        class="tab"
                        :class="{ active: activeTab === 2 }"
                        data-qa="tab-rechnungsadresse"
                        @click.prevent="activateTab(2)"
                    >
                        {{ $t('Components.Selbstauskunft.Show.ActionTabs.Rechnungsadresse') }}
                    </span>
                    <span
                        class="tab"
                        :class="{ active: activeTab === 3 }"
                        data-qa="tab-feedback-form"
                        @click.prevent="activateTab(3)"
                    >
                        {{ $t('Components.Selbstauskunft.Show.ActionTabs.Feedback') }}
                    </span>
                </div>
                <div class="content-wrapper">
                    <div
                        class="tab-content"
                        :class="{ active: activeTab === 1 }"
                        data-qa="tab-content-abonnement-kuendigen"
                    >
                        <selbstauskunft-action-tab-fuer-mitglieder
                            v-if="isMemberUser && abonnement"
                            :abonnement="abonnement"
                            :stop-in-progress="stopInProgress"
                            :stop-request-was-sent="stopRequestWasSent"
                            :stop-request-ajax-error="stopRequestAjaxError"
                        />
                        <selbstauskunft-action-tab-abonnement-fuer-kunden
                            v-if="!isMemberUser && abonnement"
                            :abonnement="abonnement"
                            :stop-in-progress="stopInProgress"
                            :stop-request-was-sent="stopRequestWasSent"
                            :stop-request-ajax-error="stopRequestAjaxError"
                        />
                    </div>
                    <div
                        v-if="!isMemberUser"
                        class="tab-content"
                        :class="{ active: activeTab === 2 }"
                        data-qa="tab-content-rechnungsadresse"
                    >
                        <selbstauskunft-action-tab-rechnungsadresse
                            :abonnement="abonnement"
                        />
                    </div>
                    <div
                        class="tab-content"
                        :class="{ active: activeTab === 3 }"
                        data-qa="tab-content-feedback-form"
                    >
                        <selbstauskunft-action-tab-feedback />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SelbstauskunftActionTabFuerMitglieder from './action-tab-abonnement-mitglieder'
    import SelbstauskunftActionTabAbonnementFuerKunden from './action-tab-abonnement-kunden'
    import SelbstauskunftActionTabRechnungsadresse from '@/components/selbstauskunft/show/action-tab-rechnungsadresse'
    import SelbstauskunftActionTabFeedback from './action-tab-feedback'
    export default {
        name: 'SelbstauskunftActionTabs',
        components: {
            SelbstauskunftActionTabFuerMitglieder,
            SelbstauskunftActionTabAbonnementFuerKunden,
            SelbstauskunftActionTabRechnungsadresse,
            SelbstauskunftActionTabFeedback
        },
        props: {
            abonnement: {
                type: Object,
                required: true
            },
            stopInProgress: {
                type: Boolean,
                required: false,
                default: false
            },
            stopRequestWasSent: {
                type: Boolean,
                required: false,
                default: false
            },
            stopRequestAjaxError: {
                type: Object,
                required: false,
                default: null
            },
            isMemberUser: {
                type: Boolean,
                required: true
            }
        },
        data () {
            return {
                activeTab: 1
            }
        },
        methods: {
            activateTab (index) {
                this.activeTab = index
            }
        }
    }
</script>

<style scoped lang="less">
    .content-wrapper {
        padding-right: 10px;
    }

    @media only screen and (max-width: 991px) {
        .crefo-ui-tabbedcontent {
            .tabs-wrapper {
                .tab {
                    white-space: normal;
                }
            }
        }
    }
</style>

let mock
try {
    const axios = require('axios')
    const MockAdapter = require('axios-mock-adapter')
    mock = new MockAdapter(axios, { onNoMatch: 'passthrough' })
} catch (e) {}

mock.onGet(/^\/cbra\/members$/).reply(200, {
    memberId: '411000019',
    businessId: '4112013025',
    memberName: 'Katrin Lustig AG',
    naturalPerson: false,
    memberAddress: {
        addressLine1: 'Grüner Weg 13',
        addressLine2: '58095 Hagen'
    },
    invoiceAddress: {
        addressLine1: 'Postfach 411000019',
        addressLine2: '58095 Hagen'
    },
    iban: 'DE17411100000000010789',
    bic: 'BELADEBEXXX',
    entryDate: 1426374000000,
    exitDate: 1656540000000
})

mock.onGet(/^\/cbra\/memberstructure.*/).reply(200, {
    mainMember: {
        memberId: '615000107',
        displayName: 'Porzer Kaffeerösterei',
        address: '34123 Kassel',
        lastUpdate: 1610011144466,
        collectionSelected: false,
        reportSelected: false,
        navigationSelected: true
    },
    subMembers: [
        {
            memberId: '615040657',
            displayName: 'Baumschule Rotbuche',
            address: '34123 Kassel',
            lastUpdate: 1610011144624,
            collectionSelected: false,
            reportSelected: false,
            navigationSelected: true
        }, {
            memberId: '615000191',
            displayName: 'Kassler Cafe',
            address: '34123 Kassel',
            lastUpdate: 1610011144586,
            collectionSelected: true,
            reportSelected: false,
            navigationSelected: true
        }
    ],
    lastUpdateTime: 1593007425585
})

mock.onGet(/^\/api\/selfcare\/users\/feature-finux$/).reply(200, true)

mock.onGet(/^\/api\/meine-bonitaet\/feature-switch$/).reply(200, { isActive: true })

mock.onGet(/^\/api\/kundenportfolio\/feature-switch$/).reply(200, { isActive: false })

mock.onGet(/^\/api\/meine-bonitaet\/kurzprofile\/.+/).reply(200, {
    selbstauskunftVerfuegbar: true,
    firmenname: 'Firmenname - Meine Bonität GmbH',
    crefonummer: '4112013025',
    adresse: {
        land: 'DE',
        plz: '11111',
        ort: 'Ort - MB',
        ortsteil: 'Ortsteil - MB',
        strasse: 'Straße - MB',
        hausnummer: '1',
        hausnummerZusatz: ''
    },
    firmenstatus: {
        bezeichnung: 'aktiv',
        fachlicheId: '001'
    },
    telefonnummer: '+49 2131 109 0',
    website: 'www.meine-bonitaet.de',
    email: 'email@meine.bonitaet.de',
    handelsregisternummer: 'HR/A: 982137-MB',
    branchenbezeichnung: 'Branchenbezeichnung - MB'
})

mock.onGet(/^\/api\/meine-bonitaet\/portal\/abonnements$/).reply(200, [
    {
        crid: '411100000000010',
        crefonummer: '4112013025',
        aboId: 'aboId_14',
        status: 'AKTIV',
        historie: [
            {
                aboBeginn: {
                    year: 2020,
                    month: 'DECEMBER',
                    monthValue: 12,
                    dayOfMonth: 15,
                    chronology: { id: 'ISO', calendarType: 'iso8601' },
                    dayOfWeek: 'TUESDAY',
                    dayOfYear: 350,
                    era: 'CE',
                    leapYear: true
                },
                aboEnde: null,
                status: 'AKTIV'
            },
            {
                aboBeginn: {
                    year: 2020,
                    month: 'DECEMBER',
                    monthValue: 12,
                    dayOfMonth: 8,
                    chronology: { id: 'ISO', calendarType: 'iso8601' },
                    dayOfWeek: 'TUESDAY',
                    dayOfYear: 343,
                    era: 'CE',
                    leapYear: true
                },
                aboEnde: {
                    year: 2020,
                    month: 'DECEMBER',
                    monthValue: 12,
                    dayOfMonth: 15,
                    chronology: { id: 'ISO', calendarType: 'iso8601' },
                    dayOfWeek: 'TUESDAY',
                    dayOfYear: 350,
                    era: 'CE',
                    leapYear: true
                },
                status: 'BEENDET'
            },
            {
                aboBeginn: {
                    year: 2020,
                    month: 'NOVEMBER',
                    monthValue: 11,
                    dayOfMonth: 23,
                    chronology: { id: 'ISO', calendarType: 'iso8601' },
                    dayOfWeek: 'MONDAY',
                    dayOfYear: 328,
                    era: 'CE',
                    leapYear: true
                },
                aboEnde: {
                    year: 2020,
                    month: 'DECEMBER',
                    monthValue: 12,
                    dayOfMonth: 3,
                    chronology: { id: 'ISO', calendarType: 'iso8601' },
                    dayOfWeek: 'THURSDAY',
                    dayOfYear: 338,
                    era: 'CE',
                    leapYear: true
                },
                status: 'BEENDET'
            },
            {
                aboBeginn: {
                    year: 2019,
                    month: 'DECEMBER',
                    monthValue: 12,
                    dayOfMonth: 23,
                    chronology: { id: 'ISO', calendarType: 'iso8601' },
                    dayOfWeek: 'MONDAY',
                    dayOfYear: 357,
                    era: 'CE',
                    leapYear: false
                },
                aboEnde: {
                    year: 2020,
                    month: 'MARCH',
                    monthValue: 3,
                    dayOfMonth: 23,
                    chronology: { id: 'ISO', calendarType: 'iso8601' },
                    dayOfWeek: 'MONDAY',
                    dayOfYear: 83,
                    era: 'CE',
                    leapYear: true
                },
                status: 'BEENDET'
            }
        ]
    }
])

mock.onGet(/^\/api\/meine-bonitaet\/selbstauskuenfte$/).reply(200, [
    {
        nachtragsgruende: [{
            fachlicheId: "A114",
            bezeichnung: "Adresse geändert4"
        }, {
            fachlicheId: "A159",
            bezeichnung: "Rechtsformwechsel4"
        }],
        auskunftsdatum: {
            year: 2020,
            month: "SEPTEMBER",
            monthValue: 9,
            dayOfMonth: 4,
            leapYear: true,
            chronology: {id: "ISO", calendarType: "iso8601"},
            dayOfWeek: "THURSDAY",
            dayOfYear: 245,
            era: "CE"
        },
        auskunftsId: 4
    },
    {
        nachtragsgruende: [{
            fachlicheId: "A114",
            bezeichnung: "Adresse geändert3"
        }, {
            fachlicheId: "A159",
            bezeichnung: "Rechtsformwechsel3"
        }],
        auskunftsdatum: {
            year: 2020,
            month: "SEPTEMBER",
            monthValue: 9,
            dayOfMonth: 3,
            leapYear: true,
            chronology: {id: "ISO", calendarType: "iso8601"},
            dayOfWeek: "THURSDAY",
            dayOfYear: 247,
            era: "CE"
        },
        auskunftsId: 3
    },
    {
        nachtragsgruende: [{
            fachlicheId: "A114",
            bezeichnung: "Adresse geändert2"
        }, {
            fachlicheId: "A159",
            bezeichnung: "Rechtsformwechsel2"
        }],
        auskunftsdatum: {
            year: 2020,
            month: "SEPTEMBER",
            monthValue: 9,
            dayOfMonth: 2,
            leapYear: true,
            chronology: {id: "ISO", calendarType: "iso8601"},
            dayOfWeek: "THURSDAY",
            dayOfYear: 247,
            era: "CE"
        },
        auskunftsId: 2
    },
    {
        nachtragsgruende: [{
            fachlicheId: "A114",
            bezeichnung: "Adresse geändert1"
        }],
        auskunftsdatum: {
            year: 2020,
            month: "SEPTEMBER",
            monthValue: 9,
            dayOfMonth: 1,
            leapYear: true,
            chronology: {id: "ISO", calendarType: "iso8601"},
            dayOfWeek: "THURSDAY",
            dayOfYear: 247,
            era: "CE"
        },
        auskunftsId: 1
    }
])

mock.onGet(/^\/cbra\/activities\/unreadCount$/).reply(200, {
    count: 0
})

mock.onGet(/^\/cbra\/watchlistentries.*/).reply(200, {
    watchlistEntries: [
        {
            id: '411029',
            memberUserId: '411000019001',
            memberId: '411000019',
            businessId: '10114000',
            businessName: '0000029',
            zipCode: '75002',
            city: 'Paris',
            countryCode: 'FR',
            transactionId: '411128',
            observationStateEnum: 'MONITORING_ACTIVE',
            createdTimestamp: 1455667200000,
            supplementEndDate: null,
            monitoringEndDate: null,
            eventEndDate: null,
            lastEventTimestamp: 1455753600000,
            lastSupplementReasonIdList: [
                'LNAGR-10021', 'LNAGR-10022', 'LNAGR-10023', 'LNAGR-10024', 'LNAGR-10025'
            ],
            lastSignalReasonIdList: null,
            monitoring: true
        }
    ],
    listSize: 29
})

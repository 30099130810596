<template>
    <a
        href="#"
        @click.prevent="download"
        data-qa="download"
    >
        <i class="crefo-brand-icon icon-download"></i>
        <slot></slot>
    </a>
</template>

<script>
    import * as api from '@/services/index.js'
    import { saveAs } from 'file-saver'
    import WrapperWebtrekk from '@/helper/webtrekk-wrapper'
    export default {
        name: 'SelbstauskunftShowDownloadButton',
        props: {
            auskunftsId: {
                type: [ String, Number ],
                default: 1
            },
            isAbonnementGesperrt: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            download: function () {
                if (this.isAbonnementGesperrt) {
                    return
                }

                const filename = this.$t('Components.Selbstauskunft.Show.DownloadButton.Filename')
                try {
                    const isFileSaverSupported = !!new Blob
                } catch (e) {
                    this.showDownloadErrorModal()
                    return
                }
                api.getPdf(this.auskunftsId)
                    .then((response) => {
                        if (response.data instanceof Blob) {
                            try {
                                const file = new File([response.data], filename, { type: 'application/octet-stream' })
                                saveAs(file, filename)
                            } catch (e) {
                                saveAs(response.data, filename)
                            }
                            WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_downloadAuskunft')
                        } else {
                            this.showDownloadErrorModal()
                        }
                    })
                    .catch(() => {
                        this.showDownloadErrorModal()
                    })
            },
            showDownloadErrorModal: function () {
                WrapperWebtrekk.trackAction('meine_bonitaet_frontend_error_downloadAuskunft')
                CrefoUI.Modal.show({
                    title: this.$i18n.t('CompanyProfileDownload.Label.Failed.Title'),
                    content: this.$i18n.t('CompanyProfileDownload.Label.Failed.Content'),
                    buttons: {
                        confirm: this.$t('Components.Selbstauskunft.Show.DownloadButton.OnErrorOkayButton')
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .btn-block {
        position: relative;
        text-align: left;
        padding-left: 76px;
        padding-right: 15px;

        i {
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 30px;
        }
    }

    .btn-icon {
        i {
            float: right;
            margin-top: 1px;
        }
    }

    .btn-icon.btn-disabled {
        cursor: not-allowed;

        .icon-download, li > span {
            color: #b5b5b5;
        }
    }
</style>

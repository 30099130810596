<template>
    <validation-provider :rules="{ required: { allowFalse: false } }" v-slot="{ invalid, dirty }">
        <div class="crefo-input">
            <div
                class="input-group"
                :class="{ 'has-error': invalid && (dirty || abonnementSubmittingTried) }"
            >
                <label>
                    {{ $t('Components.Selbstauskunft.Order.Partials.Zahlungsart.IhreOptionen') }}
                </label>
                <div class="radio-group">
                    <div class="input-wrapper">
                        <div class="crefo-radio mb-small">
                            <div class="radio-btn">
                                <input
                                    type="radio"
                                    id="payment-paypal"
                                    :disabled="abonnementSubmitting || abonnementSubmitted"
                                    value="PAYPAL"
                                    name="paymentMethodSelect"
                                    v-model="value.methode"
                                    data-crefopay="paymentMethod"
                                    data-qa="paymentMethod-paypal"
                                />
                                <label
                                    for="payment-paypal"
                                    data-qa="paypal-button-label"
                                >
                                    <span class="radio-box"><span class="radio-box-circle"></span></span>
                                    <span
                                        class="radio-label"
                                        data-qa="paypal-label"
                                    >
                                        {{ $t('Components.Selbstauskunft.Order.Partials.Zahlungsart.PayPal') }}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div
                            v-show="secureFieldsEnabled"
                            class="crefo-radio mb-small"
                        >
                            <div class="radio-btn">
                                <input
                                    type="radio"
                                    id="payment-creditcard"
                                    :disabled="abonnementSubmitting || abonnementSubmitted || !crefoPayInitializationComplete"
                                    value="CC3D"
                                    name="paymentMethodSelect"
                                    v-model="value.methode"
                                    data-crefopay="paymentMethod"
                                    data-qa="paymentMethod-creditcard"
                                />
                                <label
                                    for="payment-creditcard"
                                    data-qa="kreditkarten-button-label"
                                >
                                    <span class="radio-box"><span class="radio-box-circle"></span></span>
                                    <span
                                        class="radio-label"
                                        data-qa="kreditkarten-label"
                                    >
                                        {{ $t('Components.Selbstauskunft.Order.Partials.Zahlungsart.Kreditkarte') }}
                                    </span>
                                </label>
                            </div>
                            <div
                                v-show="value.methode === 'CC3D'"
                                class="row"
                            >
                                <div
                                    v-once
                                    class="col-12 col-md-6"
                                >
                                    <label
                                        for="payment-creditcard-accountHolder"
                                        class="data-crefopay-placeholder-label"
                                    >
                                        {{ $t('Components.Selbstauskunft.Order.Partials.Zahlungsart.CCInhaber') }}
                                    </label>
                                    <div class="input-wrapper">
                                        <div data-crefopay-placeholder="paymentInstrument.accountHolder"></div>
                                    </div>
                                    <label
                                        for="payment-creditcard-number"
                                        class="data-crefopay-placeholder-label"
                                    >
                                        {{ $t('Components.Selbstauskunft.Order.Partials.Zahlungsart.CCNummer') }}
                                    </label>
                                    <div class="input-wrapper">
                                        <div data-crefopay-placeholder="paymentInstrument.number"></div>
                                    </div>
                                    <label
                                        for="payment-creditcard-validity"
                                        class="data-crefopay-placeholder-validity"
                                    >
                                        {{ $t('Components.Selbstauskunft.Order.Partials.Zahlungsart.CCGueltigkeit') }}
                                    </label>
                                    <div class="input-wrapper">
                                        <div data-crefopay-placeholder="paymentInstrument.validity"></div>
                                    </div>
                                    <label
                                        for="payment-creditcard-cvv"
                                        class="data-crefopay-placeholder-cvv"
                                    >
                                        {{ $t('Components.Selbstauskunft.Order.Partials.Zahlungsart.CCPruefziffer') }}
                                    </label>
                                    <div class="input-wrapper">
                                        <div data-crefopay-placeholder="paymentInstrument.cvv"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </validation-provider>
</template>

<script>
    import { ValidationProvider, extend } from 'vee-validate'
    import { required } from 'vee-validate/dist/rules'
    extend('required', required)
    export default {
        name: 'Zahlungsart',
        components: { ValidationProvider },
        props: {
            value: {
                type: Object,
                required: true
            },
            abonnementSubmittingTried: {
                type: Boolean,
                default: false
            },
            abonnementSubmitting: {
                type: Boolean,
                default: false
            },
            abonnementSubmitted: {
                type: Boolean,
                default: false
            },
            crefoPayInitializationComplete: {
                type: Boolean,
                default: false
            },
            secureFieldsEnabled: {
                type: Boolean,
                default: true
            }
        },
        watch: {
            value () {
                this.$emit('input', this.value)
            }
        }
    }
</script>

<style scoped lang="less">
    .crefo-input {
        .input-group {
            .input-wrapper {
                .crefo-radio {
                    display: block;
                    height: auto;
                }
            }
        }
        .has-error {
            .radio-group {
                .input-wrapper {
                    .radio-btn {
                        .radio-box {
                            border-color: #e05469;
                        }
                        .radio-label {
                            color: #e05469 !important;
                        }
                    }
                }
            }
        }
    }
</style>

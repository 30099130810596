<template>
    <div class="box-shadow-xy box-teaser bg-white">
        <img src="@/assets/img/tablet-wi-auskunft.jpg" alt="" />
        <div class="box-spacing">
            <h3 class="mb-small" data-qa="preis-digimon-titel">
                {{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.Header") }}
            </h3>
            <ul class="list-unordered list-checked" data-qa="preis-digimon-beschreibung">
                <li>{{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.Reason1") }}</li>
                <li>{{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.Reason2") }}</li>
                <li>{{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.Reason3") }}</li>
                <li>{{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.Reason4") }}</li>
                <li>{{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.Reason5") }}</li>
            </ul>
            <div v-if="turnus === 'MONATLICH'" class="mb" data-qa="preis-digimon-zahlungsturnus-beschreibung">
                <p>
                    <strong data-qa="preis-digimon-zahlungsturnus">{{
                        $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.MonatlichHeader")
                    }}</strong>
                </p>
                <template v-if="showUStBetragDE">
                    <p class="mb-small">
                        {{
                            $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.MonatlichDescription1InklUSt", [
                                currentDate,
                            ])
                        }}
                    </p>
                    <p class="mb-small">
                        {{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.MonatlichDescription2InklUSt") }}
                    </p>
                </template>
                <template v-else>
                    <p class="mb-small">
                        {{
                            $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.MonatlichDescription1OhneUSt", [
                                currentDate,
                            ])
                        }}
                    </p>
                    <p class="mb-small">
                        {{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.MonatlichDescription2OhneUSt") }}
                    </p>
                    <p class="mb-small">
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktInformation.Steuer2') }}
                    </p>
                </template>

                <p class="mb-small">
                    {{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.MonatlichDescription3") }}
                </p>
                <p class="mb-small">
                    {{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.MonatlichDescription4") }}
                </p>
            </div>
            <div v-else class="mb" data-qa="preis-digimon-zahlungsturnus-beschreibung">
                <p>
                    <strong data-qa="preis-digimon-zahlungsturnus">{{
                        $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.JaehrlichHeader")
                    }}</strong>
                </p>
                <template v-if="showUStBetragDE">
                    <p class="mb-small">
                        {{
                            $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.JaehrlichDescription1InklUSt", [
                                currentDate,
                            ])
                        }}
                    </p>
                    <p class="mb-small">
                        {{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.JaehrlichDescription2InklUSt") }}
                    </p>
                </template>
                <template v-else>
                    <p class="mb-small">
                        {{
                            $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.JaehrlichDescription1OhneUSt", [
                                currentDate
                            ])
                        }}
                    </p>
                    <p class="mb-small">
                        {{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.JaehrlichDescription2OhneUSt") }}
                    </p>
                    <p class="mb-small">
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.ProduktInformation.Steuer2') }}
                    </p>
                </template>

                <p class="mb-small">
                    {{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.JaehrlichDescription3") }}
                </p>
            </div>
            <div>
                <router-link
                    :to="{ name: 'selbstauskunft-zahlungsturnus' }"
                    class="link-icon-prev"
                    data-qa="preis-digimon-backLink"
                >
                    <span>{{ $t("Components.Selbstauskunft.Order.Partials.PreisDigimon.Aendern") }}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { parseIso8601Date } from "@/helper/date"

    export default {
        name: "PreisDigimon",
        props: {
            turnus: {
                type: String,
                default: "MONATLICH"
            },
            land: {
                type: String,
                default: "DE"
            }
        },
        computed: {
            currentDate () {
                return parseIso8601Date(new Date().toISOString())
            },
            showUStBetragDE () {
                return this.land === "DE"
            }
        }
    }
</script>

<style scoped lang="less">
    .box-teaser {
        img {
            width: 100%;
        }

        p {
            &.mb-small {
                margin-bottom: 5px !important;
            }
        }
    }
</style>

import { render, staticRenderFns } from "./text-about-meine-bonitaet.vue?vue&type=template&id=172fc581&scoped=true"
import script from "./text-about-meine-bonitaet.vue?vue&type=script&lang=js"
export * from "./text-about-meine-bonitaet.vue?vue&type=script&lang=js"
import style0 from "./text-about-meine-bonitaet.vue?vue&type=style&index=0&id=172fc581&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "172fc581",
  null
  
)

export default component.exports
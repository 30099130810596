<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li>
                    <a href="/">{{ $t("shared.breadcrumbs.home") }}</a>
                </li>
                <li>
                    <router-link :to="{ name: 'index' }">
                        {{ $t("Appname") }}
                    </router-link>
                </li>
                <li data-qa="headline">
                    {{ $t("Breadcrumbs.Bestellen.Vertretungsberechtigung") }}
                </li>
            </crefo-breadcrumbs>

            <div class="row">
                <div class="col-12">
                    <h1 data-qa="kopfzeile-firmenname">
                        {{ kurzprofilStore.getFirmenname }}
                    </h1>
                </div>
            </div>
        </div>

        <div class="bg-brand-light-grey big-box-spacing">
            <div class="container">
                <div v-if="verifyAjaxError" class="row mt">
                    <div class="col-12">
                        <crefo-error-message :ajax-error="verifyAjaxError" error-prefix="Verifikation" />
                    </div>
                </div>
                <div v-if="showVerifyFailure" class="row">
                    <div class="col-12">
                        <crefo-error-message>
                            <p class="mb-0">
                                {{ $t('Verifikation.Message.Fehler') }}<br>
                                {{ $t('Verifikation.Message.Fehler.Tipp') }}
                            </p>
                        </crefo-error-message>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-7 mb">
                        <div class="box-spacing box-shadow-xy bg-white">
                            <h2>{{ $t("Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.Header") }}</h2>
                            <p>
                                {{ $t("Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.Subline") }}
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5">
                        <div class="box-shadow-xy box-teaser box-info bg-white">
                            <img src="@/assets/img/bild_tablet-auskunft.jpg" />
                            <div class="box-spacing" data-qa="info-box">
                                <i18n path="Components.Selbstauskunft.Order.VerifikationRegUser.Preis.Header" tag="h3">
                                    <br />
                                </i18n>
                                <ul class="list-unordered list-checked">
                                    <li>
                                        {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.Preis.Reason1") }}
                                    </li>
                                    <li>
                                        {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.Preis.Reason2") }}
                                    </li>
                                    <li>
                                        {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.Preis.Reason3") }}
                                    </li>
                                    <li>
                                        {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.Preis.Reason4") }}
                                    </li>
                                    <li>
                                        {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.Preis.Reason5") }}
                                    </li>
                                </ul>
                                <div v-show="abonnementStore.paymentCycle === 'MONATLICH'" data-qa="payment-cycle-monthly">
                                    <p>
                                        <strong data-qa="zahlungsturnus-anzeige-monatlich">
                                            {{
                                                $t(
                                                    "Components.Selbstauskunft.Order.VerifikationRegUser.Preis.Monatlich"
                                                )
                                            }}
                                        </strong>
                                    </p>
                                    <p class="h2 mb-small px-small" data-qa="zahlbetrag-anzeige-monatlich">
                                        {{
                                            $t(
                                                "Components.Selbstauskunft.Order.VerifikationRegUser.Preis.MonatlichBetrag"
                                            )
                                        }}<sup>*</sup>
                                    </p>
                                </div>
                                <div v-show="abonnementStore.paymentCycle === 'JAEHRLICH'" data-qa="payment-cycle-annually">
                                    <p>
                                        <strong data-qa="zahlungsturnus-anzeige-jährlich">
                                            {{
                                                $t(
                                                    "Components.Selbstauskunft.Order.VerifikationRegUser.Preis.Jaehrlich"
                                                )
                                            }}
                                        </strong>
                                    </p>
                                    <p class="h2 mb-small px-small" data-qa="zahlbetrag-anzeige-jährlich">
                                        {{
                                            $t(
                                                "Components.Selbstauskunft.Order.VerifikationRegUser.Preis.JaehrlichBetrag"
                                            )
                                        }}<sup>*</sup>
                                    </p>
                                    <p>
                                        <strong data-qa="ersparnis-anzeige">
                                            {{
                                                $t(
                                                    "Components.Selbstauskunft.Order.VerifikationRegUser.Preis.Ersparnis"
                                                )
                                            }}
                                        </strong>
                                    </p>
                                </div>
                                <p class="text-small" data-qa="zahlbetrag-anzeige-steuer">
                                    <sup>*</sup>
                                    {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.Preis.Sternchen") }}
                                    <br />
                                    {{ $t("Components.Selbstauskunft.Order.Landingpage.ProduktInformation.Steuer2") }}
                                </p>
                                <button
                                    class="btn btn-default btn-block mt"
                                    :class="{ 'btn-disabled': verifySubmitting }"
                                    :disabled="verifySubmitting"
                                    data-qa="authorize-button"
                                    @click.prevent="authorize"
                                >
                                    {{ $t("Verifikation.StartButton") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-brand bg-brand-blue box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center">
                            {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.NichtUeberzeugt") }}
                        </h2>
                    </div>
                </div>
                <div class="row row-reasons">
                    <div class="col-12 col-lg-4 mb">
                        <img src="@/assets/img/icon-24-7.png" alt="" />
                        <p class="h2 pt-small mb-small">
                            {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.NichtsVerpassen") }}
                        </p>
                        <p>
                            {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.NichtsVerpassenText") }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-4 mb">
                        <img src="@/assets/img/icon-contact.png" alt="" />
                        <p class="h2 pt-small mb-small">
                            {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.24Service") }}
                        </p>
                        <p>
                            {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.24ServiceText") }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-4 mb">
                        <i class="crefo-ui-icon icon-circle-success icon-color-white" aria-hidden="true" />
                        <p class="h2 pt-small mb-small">
                            {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.Update") }}
                        </p>
                        <p>
                            {{ $t("Components.Selbstauskunft.Order.VerifikationRegUser.UpdateText") }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import { useAbonnementStore } from '@/stores/abonnement'
    import HelperCrefotrust from "@dm/helper-crefotrust"
    import CrefoBreadcrumbs from "@/components/helper/crefo-breadcrumbs"
    import WrapperWebtrekk from "@/helper/webtrekk-wrapper"
    import CrefoErrorMessage from "@dm/crefo-error-message"
    export default {
        name: "VerifikationRegistrierterNutzer",
        components: { CrefoBreadcrumbs, CrefoErrorMessage },
        props: {
            verified: {
                type: Boolean,
                default: null
            }
        },
        data () {
            return {
                verifySubmitting: false,
                verifyErrorCode: null,
                verifyAjaxError: null
            }
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore, useAbonnementStore),
            showVerifyFailure () {
                return this.verified === false && this.verifyAjaxError === null && this.verifySubmitting === false
            }
        },
        methods: {
            authorize () {
                if (this.verifySubmitting) {
                    return
                }
                this.verifySubmitting = true
                this.verifyErrorCode = null
                this.verifyAjaxError = null

                WrapperWebtrekk.trackAction("meine_bonitaet_frontend_action_VerifikationContinue_for_digitales_mitglied")
                HelperCrefotrust.verify({
                    clientId: this.abonnementStore.paymentCycle === "JAEHRLICH" ? "meine-bonitaet-jaehrlich" : "meine-bonitaet-monatlich",
                    crefonummer: this.userStore.crefonummer,
                    crefonummerUnchangeable: true,
                    packageName: "@portal-microfrontend/meine-bonitaet-portal"
                })
                    .then(result => {
                        console.log("Redirecting to CrefoTrust")
                    })
                    .catch(reason => {
                        this.verifyErrorCode = reason.errorCode
                        this.verifyAjaxError = reason.ajaxError
                        this.verifySubmitting = false
                        window.scroll(0, 0)
                    })
            }
        }
    }
</script>

<style scoped lang="less">
    .crefo-ui-alert {
        p:last-child {
            margin-bottom: 0;
        }
    }

    .box-teaser {
        img {
            width: 100%;
        }

        & > div {
            a.btn {
                margin-top: 3px;
            }
        }
    }

    .bg-brand {
        margin-bottom: -60px;
        padding-bottom: 0 !important;

        .text-center {
            text-align: center;
        }

        h2,
        p,
        span {
            color: #ffffff;
        }

        @media only screen and (max-width: 1199px) {
            h2 {
                margin-bottom: 30px;
            }
        }

        .row-reasons {
            & > div {
                text-align: center;

                i {
                    display: inline-block;
                    font-size: 73px;
                }

                img {
                    width: 73px;
                    height: 73px;
                }

                span {
                    line-height: 1.7;
                }
            }
        }
    }

    .box-info {
        p {
            padding-left: 2rem;
            margin-bottom: 6px;
            line-height: 22px;
        }
    }
</style>

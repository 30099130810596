import i18n from '@/i18n'
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))

const GeneralUtilityHelper = (() => {
    const defaultAllowedCountries = [ 'DE' ]
    const defaultAllowedCountriesForVerification = [ 'DE' ]

    function allowedCountries () {
        let allowed = []

        if (process && process.env && process.env.VUE_APP_ALLOWED_COUNTRIES) {
            const configuredCountries = process.env.VUE_APP_ALLOWED_COUNTRIES
            if (
                typeof configuredCountries === 'string'
                && configuredCountries.length
                && configuredCountries.indexOf('ALLOWED_COUNTRIES') === -1
            ) {
                configuredCountries.trim().split(',')
                    .forEach(country => {
                        allowed.push(country.trim().toUpperCase())
                    })
            }
        }

        return allowed.length ? allowed : defaultAllowedCountries
    }

    function allowedCountriesForVerification () {
        let allowed = []

        if (process && process.env && process.env.VUE_APP_ALLOWED_COUNTRIES_FOR_VERIFICATION) {
            const configuredCountries = process.env.VUE_APP_ALLOWED_COUNTRIES_FOR_VERIFICATION
            if (
                typeof configuredCountries === 'string'
                && configuredCountries.length
                && configuredCountries.indexOf('ALLOWED_COUNTRIES_FOR_VERIFICATION') === -1
            ) {
                configuredCountries.trim().split(',')
                    .forEach(country => {
                        allowed.push(country.trim().toUpperCase())
                    })
            }
        }

        return allowed.length ? allowed : defaultAllowedCountriesForVerification
    }

    function allowedCountriesByName (lang) {
        const allowed = []

        allowedCountries().forEach(country => {
            const name = countries.getName(country, lang.toLowerCase())
            if (name) {
                allowed.push(name)
            } else {
                allowed.push(country)
            }
        })

        return allowed
    }

    function allowedCountriesAsText (lang) {
        const countries = allowedCountriesByName(lang)
        if (countries.length === 0) {
            return '???'
        } else if (countries.length === 1) {
            return countries[0]
        }

        const lastCountry = countries.pop()
        return countries.join(', ') + ' ' + i18n.t('Common.or') + ' ' + lastCountry
    }

    return {
        getAllowedCountries: () => {
            return allowedCountries()
        },
        getAllowedCountriesByName: (lang = 'en') => {
            return allowedCountriesByName(lang)
        },
        getAllowedCountriesAsText: (lang = 'en') => {
            return allowedCountriesAsText(lang)
        },
        isCountryAllowed: country => {
            if (typeof country !== 'string' || country.length === 0) {
                return false
            }

            return allowedCountries().includes(country.toUpperCase())
                || allowedCountries().includes('ALL')
        },
        getAllowedCountriesForVerification: () => {
            return allowedCountriesForVerification()
        },
        isCountryAllowedForVerification: country => {
            if (typeof country !== 'string' || country.length === 0) {
                return false
            }

            return allowedCountriesForVerification().includes(country.toUpperCase())
                || allowedCountriesForVerification().includes('ALL')
        }
    }
})()

export default GeneralUtilityHelper

<template>
    <div class="bg-white box-spacing box-shadow-xy box-example">
        <div class="row">
            <div class="col-12">
                <div class="h2">
                    {{ $t('Components.Selbstauskunft.Order.Partials.ExampleDownload.Header') }}
                </div>
                <a
                    :href="`${publicPath}assets/doc/Meine-Bonitaet-Muster-${languageVariant}.pdf`"
                    class="example-download"
                    data-qa="kontaktseite-download-button"
                >
                    <i class="crefo-brand-icon icon-download" />
                    <span>{{ $t('Components.Selbstauskunft.Order.Partials.ExampleDownload.DownloadLink') }}</span>
                </a>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SelbstauskunftOrderExampleDownload',
        data () {
            return {
                publicPath: process.env.BASE_URL || '',
                defaultLanguage: 'de',
                allowedLanguages: [ 'de', 'de-at', 'en', 'fr', 'fr-lu' ]
            }
        },
        computed: {
            languageVariant () {
                let websiteLanguage = this.$i18n.locale.toLowerCase().split('_')
                if (websiteLanguage.length === 2 && websiteLanguage[0] !== websiteLanguage[1] && websiteLanguage[0] !== 'en') {
                    websiteLanguage = websiteLanguage[0] + '-' + websiteLanguage[1]
                } else {
                    websiteLanguage = websiteLanguage[0]
                }

                if (this.allowedLanguages.includes(websiteLanguage)) {
                    return websiteLanguage
                } else {
                    return this.defaultLanguage
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .box-example {
        min-height: 100%;

        .example-download {
            margin-bottom: 55px;
            display: block;

            .crefo-brand-icon {
                position: relative;
                top: 5px;
            }

            span {
                padding-left: 10px;
            }
        }
    }
</style>

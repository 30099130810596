import { defineStore } from 'pinia'
import * as api from '@/services/index.js'
import i18n from '@/i18n'
import countries from "i18n-iso-countries"

export const useKurzprofilStore = defineStore(
    'kurzprofil',
    {
        state: () => ({
            kurzprofilFetching: null,
            kurzprofilAvailable: false,
            kurzprofil: null,
            ajaxError: null
        }),
        getters: {
            getFirmenstatus (state) {
                if (state.kurzprofilAvailable && state.kurzprofil.firmenstatus) {
                    return getContent(state.kurzprofil.firmenstatus.bezeichnung)
                } else {
                    return ''
                }
            },
            getCrefonummer (state) {
                if (state.kurzprofilAvailable) {
                    return getContent(state.kurzprofil.crefonummer)
                } else {
                    return ''
                }
            },
            getFirmenname (state) {
                if (state.kurzprofilAvailable) {
                    return getContent(state.kurzprofil.firmenname)
                } else {
                    return ''
                }
            },
            getAdressZeile1 (state) {
                let result = ''

                if (state.kurzprofilAvailable) {
                    if (state.kurzprofil.adresse) {
                        result = getContent(state.kurzprofil.adresse.strasse)
                        if (
                            state.kurzprofil.adresse.strasse &&
                            (state.kurzprofil.adresse.hausnummer || state.kurzprofil.adresse.hausnummerZusatz)
                        ) {
                            result += " "
                        }
                        result += getContent(state.kurzprofil.adresse.hausnummer)
                        result += getContent(state.kurzprofil.adresse.hausnummerZusatz)
                    }
                }

                return result
            },
            getStrasse (state) {
                let result = ''

                if (state.kurzprofilAvailable) {
                    if (state.kurzprofil.adresse) {
                        result = getContent(state.kurzprofil.adresse.strasse)
                    }
                }

                return result
            },
            getHausnummer (state) {
                let result = ''

                if (state.kurzprofilAvailable) {
                    if (state.kurzprofil.adresse) {
                        result = getContent(state.kurzprofil.adresse.hausnummer)
                        result += getContent(state.kurzprofil.adresse.hausnummerZusatz)
                    }
                }

                return result
            },
            getAdressZeile2 (state) {
                let result = ''

                if (state.kurzprofilAvailable) {
                    if (state.kurzprofil.adresse) {
                        if (state.kurzprofil.adresse.plz && state.kurzprofil.adresse.ort) {
                            if (state.kurzprofil.adresse.land && state.kurzprofil.adresse.land !== 'DE') {
                                result += getContent(state.kurzprofil.adresse.land)
                                result += ' - '
                            }
                            result += getContent(state.kurzprofil.adresse.plz)
                            result += ' '
                        }
                        result += getContent(state.kurzprofil.adresse.ort)
                    }
                }

                return result
            },
            getTelefonnummer (state) {
                if (state.kurzprofilAvailable) {
                    return getContent(state.kurzprofil.telefonnummer)
                } else {
                    return ''
                }
            },
            getWebsite (state) {
                if (state.kurzprofilAvailable) {
                    return getContent(state.kurzprofil.website)
                } else {
                    return ''
                }
            },
            getWebsiteAsLink () {
                const website = this.getWebsite
                if (website.length) {
                    if (website.indexOf('http://') === 0 || website.indexOf('https://') === 0) {
                        return website
                    } else if (website.indexOf(':') === -1 && website.indexOf('/') !== 0) {
                        return 'http://' + website
                    }
                }
                return ''
            },
            getEmail (state) {
                if (state.kurzprofilAvailable) {
                    return getContent(state.kurzprofil.email)
                } else {
                    return ''
                }
            },
            getUmsatzsteuerId (state) {
                if (state.kurzprofilAvailable) {
                    return getContent(state.kurzprofil.umsatzsteuerId)
                } else {
                    return ''
                }
            },
            getEmailAsLink (state) {
                const email = this.getEmail
                if (email.length && email.indexOf('@') !== -1) {
                    if (email.indexOf('mailto:') === 0) {
                        return email
                    } else if (email.indexOf(':') === -1) {
                        return 'mailto:' + email
                    }
                }
                return ''
            },
            getPlz (state) {
                let result = ''
                if (state.kurzprofilAvailable) {
                    if (state.kurzprofil.adresse) {
                        if (state.kurzprofil.adresse.plz) {
                            result = getContent(state.kurzprofil.adresse.plz)
                        }
                    }
                }
                return result
            },
            getOrt (state) {
                let result = ''
                if (state.kurzprofilAvailable) {
                    if (state.kurzprofil.adresse) {
                        if (state.kurzprofil.adresse.ort) {
                            result = getContent(state.kurzprofil.adresse.ort)
                        }
                    }
                }
                return result
            },
            getLand (state) {
                let result = ''
                if (state.kurzprofilAvailable) {
                    if (state.kurzprofil.adresse) {
                        if (state.kurzprofil.adresse.land) {
                            result = getContent(state.kurzprofil.adresse.land)
                        }
                    }
                }
                return result
            },
            getLandAsName (state) {
                let result = ''
                if (state.kurzprofilAvailable) {
                    if (state.kurzprofil.adresse) {
                        if (state.kurzprofil.adresse.land) {
                            result = getContent(state.kurzprofil.adresse.land)
                            if (typeof result === 'string' && result.length >= 2 && result.length <= 3) {
                                if (countries.isValid(result)) {
                                    countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
                                    const lang = i18n.locale.split('_')[0].toLowerCase().replace('at', 'de')
                                    result = countries.getName(result, lang, { select: 'official' })
                                }
                            }
                        }
                    }
                }
                return result
            },
            getHandelsregisternummer (state) {
                if (state.kurzprofilAvailable) {
                    return getContent(state.kurzprofil.handelsregisternummer)
                } else {
                    return ''
                }
            },
            getBranchenbezeichnung (state) {
                if (state.kurzprofilAvailable) {
                    return getContent(state.kurzprofil.branchenbezeichnung)
                } else {
                    return ''
                }
            }
        },
        actions: {
            /**
             * Should be called when userModule.crefonummer changes to null.
             */
            RESET_KURZPROFIL () {
                this.kurzprofilFetching = false
                this.kurzprofilAvailable = false
                this.kurzprofil = null
                this.ajaxError = null
            },
            /**
             * Should be called, before fetching a new kurzprofil.
             */
            GET_KURZPROFIL_FETCHING () {
                this.kurzprofilFetching = true
            },
            /**
             * Should be called with the fetched kurzprofil data.
             *
             * @param {Kurzprofil} payload
             */
            GET_KURZPROFIL_SUCCESS (payload) {
                this.kurzprofilFetching = false
                this.kurzprofil = payload
                this.kurzprofilAvailable = true
                this.ajaxError = null
            },
            /**
             * Should be called when kurzprofil could not be fetched.
             *
             * @param {?AjaxError} error
             */
            GET_KURZPROFIL_FAILED (error) {
                this.kurzprofilFetching = false
                this.kurzprofilAvailable = false
                this.kurzprofil = null
                this.ajaxError = error
            },
            /**
             * Fetches the kurzprofil for the given crefonummer.
             *
             * @param {string} crefonummer
             */
            getKurzprofil (crefonummer) {
                return new Promise((resolve, reject) => {
                    this.GET_KURZPROFIL_FETCHING()
                    api.getKurzprofil(crefonummer, i18n.locale.split('_')[0])
                        .then(response => {
                            this.GET_KURZPROFIL_SUCCESS(response.data)
                            resolve()
                        })
                        .catch(error => {
                            this.GET_KURZPROFIL_FAILED(error)
                            reject()
                        })
                })
            },
            /**
             * Updates kurzprofil if language changes
             */
            updateKurzprofil () {
                if (this.kurzprofilAvailable !== true) {
                    return
                }
                if (typeof this.kurzprofil?.crefonummer !== 'string' || this.kurzprofil.crefonummer.length === 0) {
                    return
                }

                api.getKurzprofil(this.kurzprofil.crefonummer, i18n.locale.split('_')[0])
                    .then(response => {
                        this.GET_KURZPROFIL_SUCCESS(response.data)
                    })
                    .catch(error => {
                    })
            }
        }
    }
)

function getContent (property) {
    if (typeof property === 'string' && property.length > 0) {
        return property
    } else if (typeof property === 'number' && property > 0) {
        return property
    }
    return ''
}

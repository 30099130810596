<template>
    <div>
        <h1 class="mb-small">
            {{ $t('Components.Selbstauskunft.Order.Landingpage.IntroText.Headline') }}
        </h1>
        <i18n
            path="Components.Selbstauskunft.Order.Landingpage.IntroText.ShortText"
            tag="p"
        >
            <strong>{{ $t('Components.Selbstauskunft.Order.Landingpage.IntroText.ShortText.MB') }}</strong>
            <strong>{{ $t('Components.Selbstauskunft.Order.Landingpage.IntroText.ShortText.Visitenkarte') }}</strong>
        </i18n>
        <p v-if="longIntro">
            {{ $t('Components.Selbstauskunft.Order.Landingpage.IntroText.LongText') }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'IntroText',
        props: {
            longIntro: {
                type: Boolean,
                required: false,
                default: true
            }
        }
    }
</script>

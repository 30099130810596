<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
                <li>
                    <router-link :to="{ name: 'index' }">
                        {{ $t('Appname') }}
                    </router-link>
                </li>
                <li data-qa="headline">
                    {{ $t('View.CrefopayFehlerseite.BreadcrumbTitle') }}
                </li>
            </crefo-breadcrumbs>

            <div class="row mb">
                <div class="col-12">
                    <div class="mb">
                        <i
                            class="crefo-brand-icon icon-cross icon-color-red"
                            aria-hidden="true"
                        />
                    </div>
                    <h1
                        class="h2 mb-small"
                        data-qa="crefopay-bestellung-fehler"
                    >
                        <span v-if="currentAboId">
                            {{ $t('View.CrefopayFehlerseite.AboIdPrefix') }}
                            <span data-qa="aboId">{{ currentAboId }}</span>
                            {{ $t('View.CrefopayFehlerseite.AboIdPostfix') }}
                        </span>
                        <span v-else>
                            {{ $t('View.CrefopayFehlerseite.AboIdFehlt') }}
                        </span>
                    </h1>
                </div>
            </div>
        </div>

        <div class="bg-brand bg-brand-blue box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-7 col-xl-8 mb">
                        <div class="box-teaser">
                            <img
                                src="@/assets/img/MeineBonitaet-Mockup-Freisteller.png"
                                alt=""
                            >
                        </div>
                    </div>

                    <div class="col-12 col-lg-5 col-xl-4">
                        <div class="row pt">
                            <div class="col-12">
                                <h2 class="text-white">
                                    {{ $t('View.CrefopayFehlerseite.TeaserText') }}
                                </h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <h3 class="text-white mb-small">
                                    <span>{{ $t('View.CrefopayFehlerseite.ProductTitle') }} </span>
                                    <span data-qa="firmenname">{{ kurzprofilStore.getFirmenname }}</span>
                                </h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p
                                    class="text-bold text-brand-yellow"
                                    data-qa="crefopay-error-message"
                                    v-html="errorMessage"
                                />
                                <div class="h3">
                                    <p>
                                        <router-link
                                            :to="{ name: 'selbstauskunft-zahlung', query: {
                                                merchantID: merchantId,
                                                storeID: storeId,
                                                orderID: orderId
                                            }}"
                                            class="btn btn-default bg-brand-purple"
                                            role="button"
                                            data-qa="show-button"
                                        >
                                            <span>{{ $t('View.CrefopayFehlerseite.ButtonText') }}</span>
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import CrefoBreadcrumbs from '@/components/helper/crefo-breadcrumbs'

    export default {
        name: 'CrefopayFehlerseite',
        components: { CrefoBreadcrumbs },
        props: {
            merchantId: {
                type: String,
                required: false,
                default: ''
            },
            storeId: {
                type: String,
                required: false,
                default: ''
            },
            orderId: {
                type: String,
                required: false,
                default: ''
            },
            paymentReference: {
                type: String,
                required: false,
                default: ''
            },
            merchantReference: {
                type: String,
                required: false,
                default: ''
            },
            paymentInstrumentUrl: {
                type: String,
                required: false,
                default: ''
            },
            resultCode: {
                type: String,
                required: false,
                default: '0'
            }
        },
        data () {
            return {
                currentAboId: sessionStorage.getItem('meine_bonitaet_aboId')
            }
        },
        computed: {
            ...mapStores(useKurzprofilStore),
            errorMessage () {
                const GENERAL_ERROR = [ 2003, 2004, 2005, 2008, 2024, 2048, 2049, 2050, 2051, 2060 ]
                const GENERAL_ERROR_SUPPORT = [ 1000, 1001, 1002, 2026, 2027, 2031, 2052 ]
                const ERROR_PAYMENT_METHOD = [ 2000, 2001, 2017, 2033, 2039, 2040 ]
                const ERROR_PAYMENT_INFORMATION = [ 2012, 2013, 2046, 2055 ]
                const ERROR_CREDIT_CARD_PAYMENT = [ 2010, 2011, 2041, 2042, 2043, 2044 ]
                const CANCELED_BY_USER = [ 6005 ]
                const resultCodeNumber = this.resultCode ? parseInt(this.resultCode, 10) : 0
                if (resultCodeNumber <= 0) {
                    return this.$t('CrefoPayErrorCode.NOT_SET')
                } else if (GENERAL_ERROR.includes(resultCodeNumber)) {
                    return this.$t('CrefoPayErrorCode.GENERAL_ERROR')
                } else if (GENERAL_ERROR_SUPPORT.includes(resultCodeNumber)) {
                    return this.$t('CrefoPayErrorCode.GENERAL_ERROR_SUPPORT')
                } else if (ERROR_PAYMENT_METHOD.includes(resultCodeNumber)) {
                    return this.$t('CrefoPayErrorCode.ERROR_PAYMENT_METHOD')
                } else if (ERROR_PAYMENT_INFORMATION.includes(resultCodeNumber)) {
                    return this.$t('CrefoPayErrorCode.ERROR_PAYMENT_INFORMATION')
                } else if (ERROR_CREDIT_CARD_PAYMENT.includes(resultCodeNumber)) {
                    return this.$t('CrefoPayErrorCode.ERROR_CREDIT_CARD_PAYMENT')
                } else if (CANCELED_BY_USER.includes(resultCodeNumber)) {
                    return this.$t('CrefoPayErrorCode.CANCELED_BY_USER')
                } else {
                    return this.$t('CrefoPayErrorCode.NOT_MAPPED_ERROR')
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .icon-cross {
        font-size: 73px;

        &.icon-color-red::before {
            color: #e05469
        }
    }

    span.text-semibold {
        font-weight: normal;
        text-transform: uppercase;
    }

    .bg-brand-blue {
        margin-bottom: -60px;

        @media only screen and (min-width: 992px) {
            padding-bottom: 0 !important;
        }

        .box-teaser {
            margin-top: 15px;

            img {
                width: 70%;
            }

            & > div {
                a.btn {
                    margin-top: 3px;
                }
            }
        }

        .text-brand-yellow {
            &::v-deep a {
                color: #dddf4a;
                text-decoration: underline;
            }
            &::v-deep a:hover {
                text-decoration: none;
            }
        }
    }
</style>

<template>
    <div>
        <div class="container">
            <div class="row mb">
                <div class="col-12 col-lg-10">
                    <intro-text />
                </div>
            </div>
            <suche-unternehmen @company-selected="onCompanySelected" />
        </div>
        <div
            v-if="crefonummer"
            class="bg-brand bg-brand-light-grey box-spacing big-box-spacing mb"
        >
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-7 col-xl-8">
                        <suggested-kurzprofil
                            :crefonummer="crefonummer"
                            :show-header="false"
                            v-on:crefo-kurzprofil-fetching-success="onKurzprofilAvailable"
                            v-on:crefo-kurzprofil-fetching-error="onKurzprofilNoData"
                            v-on:crefo-kurzprofil-fetching="onKurzprofilFetching"
                        />
                        <div v-if="kurzprofilLoaded">
                            <div
                                v-if="isMeineBonitaetAvailable"
                                class="row"
                            >
                                <div class="col-12 col-lg-10">
                                    <div class="box-spacing">
                                        <i18n
                                            path="Components.Selbstauskunft.Order.Landingpage.LandingNotLoggedIn.TeaserText"
                                            tag="p"
                                        >
                                            <strong>
                                                {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingNotLoggedIn.TeaserText.Headline') }}
                                            </strong>
                                            <br>
                                        </i18n>
                                        <button
                                            class="btn btn-default btn-default--register mb"
                                            data-qa="redirect-register"
                                            @click.prevent="onRegister"
                                        >
                                            <span class="d-xl-none">
                                                {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingNotLoggedIn.ButtonTextMobile') }}
                                            </span>
                                            <span class="d-none d-xl-inline">
                                                {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingNotLoggedIn.ButtonTextDesktop') }}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <produkt-nicht-verfuegbar
                                v-else
                                :authenticated="false"
                                :crefonummer="crefonummer"
                                :kurzprofil="kurzprofilData"
                                :box-spacing="true"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-lg-5 col-xl-4">
                        <produkt-information />
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mb-large">
                <div class="col-12 col-lg-6 col-xl-5">
                    <unternehmen-nicht-gefunden />
                </div>
                <div class="col-12 col-lg-6 col-xl-5">
                    <h3 class="mb-small">
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingNotLoggedIn.HeaderSchonRegistriert') }}
                    </h3>
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingNotLoggedIn.TextSchonRegistriert') }}
                    </p>
                    <button
                        class="btn btn-default btn-min btn-small btn-purple"
                        data-qa="redirect-login"
                        @click.prevent="onLogin"
                    >
                        {{ $t('Components.Selbstauskunft.Order.Landingpage.LandingNotLoggedIn.ButtonSchonRegistriert') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SucheUnternehmen from './suche-unternehmen'
    import SuggestedKurzprofil from './suggested-kurzprofil'
    import IntroText from './intro-text'
    import ProduktInformation from './produkt-information'
    import ProduktNichtVerfuegbar from './produkt-nicht-verfuegbar'
    import UnternehmenNichtGefunden from './unternehmen-nicht-gefunden'
    import { dispatchEvent } from '@/helper/polyfill'
    import WrapperWebtrekk from '@/helper/webtrekk-wrapper'
    import GeneralUtilityHelper from '@/helper/general'
    export default {
        name: 'LandingNotLoggedIn',
        components: {
            SucheUnternehmen,
            SuggestedKurzprofil,
            IntroText,
            ProduktInformation,
            ProduktNichtVerfuegbar,
            UnternehmenNichtGefunden
        },
        props: {
            preselectedCrefonummer: {
                type: String,
                required: false,
                default: ''
            }
        },
        data () {
            return {
                crefonummer: this.preselectedCrefonummer,
                kurzprofilData: null,
                kurzprofilLoaded: false
            }
        },
        computed: {
            isMeineBonitaetAvailable () {
                return this.kurzprofilData
                    && this.kurzprofilData.adresse
                    && GeneralUtilityHelper.isCountryAllowed(this.kurzprofilData.adresse.land)
                    && this.kurzprofilData.selbstauskunftVerfuegbar
            }
        },
        methods: {
            onCompanySelected (crefonummer) {
                this.kurzprofilLoaded = false
                this.kurzprofilData = null
                this.crefonummer = crefonummer
            },
            onKurzprofilAvailable (kurzprofilData) {
                this.kurzprofilData = kurzprofilData
                this.kurzprofilLoaded = true
            },
            onKurzprofilNoData () {
                this.kurzprofilData = null
                this.kurzprofilLoaded = true
            },
            onKurzprofilFetching () {
                this.kurzprofilData = null
                this.kurzprofilLoaded = false
            },
            onLogin () {
                WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_LandingpageGoLogin')
                dispatchEvent('redirectToLogin')
            },
            onRegister () {
                let loginHost = 'login.creditreform.de'
                const regex = new RegExp('(.+)cp\\.meine\\.creditreform\\.')
                const matchingHost = window.location.host.match(regex)
                if (matchingHost && matchingHost[1]) {
                    loginHost = matchingHost[1] + 'sso.' + loginHost
                }

                const namespace = '7be9491a-d337-4bf1-8ca1-fd1b2e286958'
                const prt = this.$uuid.v5(loginHost, namespace)
                WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_LandingpageGoRegistration')
                window.location.href =
                    `https://${loginHost}/benutzerverwaltung/registrieren?prk=MEINE_BONITAET&prt=${prt}&crefonummer=${this.crefonummer}`
            }
        }
    }
</script>

<style scoped lang="less">
    .btn-purple {
        background-color: #a763a4;

        &:hover {
            background-color: #894d87;
        }
    }
</style>

export default {
    props: {
        defaultLanguage: {
            type: String,
            default: 'de'
        },
        allowedLanguages: {
            type: Array,
            default: () => [ 'de', 'de-at', 'en', 'fr', 'fr-lu' ]
        }
    },
    data () {
        return {
            publicPath: process.env.BASE_URL || ''
        }
    },
    computed: {
        languageVariant () {
            // transform this.$i18n.locale language code to allowed language code
            let websiteLanguage = this.$i18n.locale.toLowerCase().split('_')
            if (websiteLanguage.length === 2 && websiteLanguage[0] !== websiteLanguage[1] && websiteLanguage[0] !== 'en') {
                websiteLanguage = websiteLanguage[0] + '-' + websiteLanguage[1]
            } else {
                websiteLanguage = websiteLanguage[0]
            }

            if (this.allowedLanguages.includes(websiteLanguage)) {
                return websiteLanguage
            } else {
                return this.defaultLanguage
            }
        }
    }
}

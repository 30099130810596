<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
                <li data-qa="headline">
                    {{ $t('Appname') }}
                </li>
            </crefo-breadcrumbs>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="marketing mb">
                        <span>{{ $t('View.PageNotFound.Headline') }}</span>
                    </h1>
                    <p>
                        {{ $t('View.PageNotFound.Description') }}
                    </p>
                    <p class="mb">
                        {{ $t('View.PageNotFound.Notice') }}
                    </p>
                    <a
                        href="/"
                        class="btn btn-default"
                        data-qa="not-found-back-to-home"
                    >
                        {{ $t('View.PageNotFound.ButtonText') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CrefoBreadcrumbs from '../components/helper/crefo-breadcrumbs'
    import { isRouteAllowed, redirectToNextStep } from '../helper/route-helper'
    export default {
        name: 'PageNotFound',
        components: { CrefoBreadcrumbs },
        beforeRouteUpdate (to, from, next) {
            this.setValidRoutesAndNextSteps()
            next()
        },
        data () {
            return {
                validRoutesAndNextSteps: {
                    nextStep: { location: '/' }
                }
            }
        },
        created () {
            this.setValidRoutesAndNextSteps()
        },
        methods: {
            setValidRoutesAndNextSteps: function () {
                isRouteAllowed(null, this.$router.currentRoute, true, 2000)
                    .then(validRoutesAndNextSteps => this.validRoutesAndNextSteps = validRoutesAndNextSteps)
                    .catch(validRoutesAndNextSteps => this.validRoutesAndNextSteps = validRoutesAndNextSteps)
                    .finally(() => {
                        window.setTimeout(() => {
                            redirectToNextStep(
                                newRoute => this.$router.push(newRoute),
                                this.validRoutesAndNextSteps,
                                null
                            )
                        }, 5000)
                    })
            }
        }
    }
</script>

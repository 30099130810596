<template>
    <div class="row">
        <div class="col-12">
            <div class="h3 mb-small">
                {{ $t('Components.Selbstauskunft.Order.Landingpage.ExampleDownload.Headline') }}
            </div>
            <p>
                {{ $t('Components.Selbstauskunft.Order.Landingpage.ExampleDownload.Text') }}
            </p>
            <download-sample filename="Meine-Bonitaet-Muster" download="sample" />
        </div>
    </div>
</template>

<script>
    import DownloadSample from '@/components/helper/download-sample.vue'

    export default {
        name: 'ExampleDownload',
        components: {
            DownloadSample
        }
    }
</script>

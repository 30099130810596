<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
                <li>
                    <router-link :to="{name: 'index'}">
                        {{ $t('Appname') }}
                    </router-link>
                </li>
                <li data-qa="headline">
                    {{ $t('Breadcrumbs.Bestellen.Kontakt') }}
                </li>
            </crefo-breadcrumbs>

            <div class="row">
                <div class="col-12">
                    <h1 data-qa="appname">
                        {{ $t('Appname') }}
                    </h1>
                </div>
            </div>
        </div>

        <div class="container">
            <crefo-kurzprofil
                :kurzprofil="kurzprofilStore.kurzprofil"
                :crefonummer="userStore.crefonummer"
                :show-body="false"
                design="WhiteBox"
            />
        </div>
        <div class="bg-brand bg-brand-light-grey big-box-spacing mb">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-7 col-xl-8 profile-address">
                        <crefo-kurzprofil
                            :kurzprofil="kurzprofilCallback"
                            :silent-update-serial="$i18n.locale"
                            :crefonummer="userStore.crefonummer"
                            :show-header="false"
                            :show-country="true"
                            design="WhiteBox"
                            @crefo-kurzprofil-fetching-success="onKurzprofilSuccess"
                            @crefo-kurzprofil-fetching-error="onKurzprofilError"
                        />
                    </div>
                    <div class="col-12 col-lg-5 col-xl-4">
                        <selbstauskunft-order-example-download>
                            <router-link
                                :to="checkoutRouteDestination"
                                class="btn btn-default"
                                role="button"
                                data-qa="kaufen-first-button"
                            >
                                <span>{{ $t('View.SelbstauskunftErwerben.OrderButtonText') }}</span>
                            </router-link>
                        </selbstauskunft-order-example-download>
                    </div>
                </div>
            </div>
        </div>

        <selbstauskunft-order-text-about-meine-bonitaet>
            <router-link
                :to="checkoutRouteDestination"
                class="btn btn-default"
                role="button"
                data-qa="kaufen-second-button"
            >
                <span>{{ $t('View.SelbstauskunftErwerben.OrderButtonText') }}</span>
            </router-link>
        </selbstauskunft-order-text-about-meine-bonitaet>
    </div>
</template>

<script>
    /**
     * This pages shows options to order a selbstauskunft.
     *
     *  - Redirects to startpage,
     *      when user state is not determined yet
     *      when 'kurzprofil' is not available
     *      when 'selbstauskunft' is not available
     *      when country is not germany
     */
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import CrefoBreadcrumbs from '../components/helper/crefo-breadcrumbs'
    import CrefoKurzprofil from '@dm/crefo-kurzprofil'
    import SelbstauskunftOrderTextAboutMeineBonitaet
        from '../components/selbstauskunft/order/partials/text-about-meine-bonitaet'
    import SelbstauskunftOrderExampleDownload from '../components/selbstauskunft/order/partials/example-download'
    import { getKurzprofil } from '../services/index'

    export default {
        name: 'SelbstauskunftErwerben',
        components: {
            CrefoBreadcrumbs,
            CrefoKurzprofil,
            SelbstauskunftOrderTextAboutMeineBonitaet,
            SelbstauskunftOrderExampleDownload
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore),
            checkoutRouteDestination () {
                let name
                if (this.userStore.isVertretungsberechtigt === true) {
                    name = 'selbstauskunft-abonnement'
                } else {
                    name = 'selbstauskunft-verifikation'
                }
                return { name }
            }
        },
        methods: {
            kurzprofilCallback (crefonummer) {
                return getKurzprofil(crefonummer, this.$i18n.locale.split('_')[0])
            },
            onKurzprofilSuccess (kurzprofil) {
                this.kurzprofilStore.GET_KURZPROFIL_SUCCESS(kurzprofil)
            },
            onKurzprofilError (error) {
                this.kurzprofilStore.GET_KURZPROFIL_FAILED(error)
            }
        }
    }
</script>

<template>
    <div
        v-if="isLoading"
        class="container pt"
    >
        <crefo-breadcrumbs>
            <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
            <li>
                <router-link :to="{name: 'index'}">
                    {{ $t('Appname') }}
                </router-link>
            </li>
        </crefo-breadcrumbs>
        <crefo-ring-loading additional-classes="mt" />
    </div>
    <div v-else>
        <zahlungsturnus />
    </div>
</template>

<script>
    /**
     * Diese Komponente ist nur sichtbar, wenn
     *   - Der Benutzer hat noch kein Abonnement (das zur Crefonummer passt)
     *   - Der Benutzer hat ein Abonnement, ist aber nicht Vertretungsberechtigt (für die Crefonummer)
     */
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import { useAbonnementStore } from '@/stores/abonnement'
    import CrefoBreadcrumbs from '../components/helper/crefo-breadcrumbs'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    import Zahlungsturnus from '../components/selbstauskunft/order/zahlungsturnus'
    export default {
        name: 'SelbstauskunftZahlungsturnus',
        components: {
            CrefoBreadcrumbs,
            CrefoRingLoading,
            Zahlungsturnus
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore, useAbonnementStore),
            isLoading: function () {
                return this.kurzprofilStore.kurzprofilFetching !== false ||
                    this.abonnementStore.rechnungsadresseFetching !== false
            }
        },
        async created () {
            /*
            * meineboni1@testautomatisierung.creditreform.de Test1234
            * daisy@dev.concept5.de Test1234
            * https://www.figma.com/file/wjDtJaA8SljBKGjUN7Ur8u/Checkout-MB?node-id=0%3A1
            */
            await this.abonnementStore.getRechnungsadresse({ crid: this.userStore.user.cr_id })
            this.abonnementStore.ON_CHANGE_CHECKOUT_STARTED(true)
        }
    }
</script>

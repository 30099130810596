<template>
    <selbstauskunft-order-contact-ausland>
        <span data-qa="info-ausland">
            {{ $t('View.SelbstauskunftAusland.InfoText', [ allowedCountriesAsText ]) }}
        </span>
    </selbstauskunft-order-contact-ausland>
</template>

<!--
    Benutzer    MitgliedAustria@testautomatisierung.creditreform.de
    Vorlage     https://sedevcp.meine.creditreform.de/unternehmen/
-->

<script>
    /**
     * This pages shows options to order a selbstauskunft.
     *
     *  - Redirects to startpage, when user state is not determined yet
     *  - Redirects to show selbstauskunft, when user already has one and is vertretungsberechtigt
     */
    import SelbstauskunftOrderContactAusland from '../components/selbstauskunft/order/contact-ausland'
    import GeneralUtilityHelper from '@/helper/general'
    export default {
        name: 'SelbstauskunftAusland',
        components: {
            SelbstauskunftOrderContactAusland
        },
        computed: {
            allowedCountriesAsText: function () {
                const lang = this.$i18n.locale.split('_')[0].toLowerCase().replace('at', 'de')
                return GeneralUtilityHelper.getAllowedCountriesAsText(lang)
            }
        }
    }
</script>

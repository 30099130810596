import { defineStore } from 'pinia'
import * as api from '@/services/index.js'
import * as dateHelper from '@/helper/date'

export const useSelbstauskunftStore = defineStore(
    'selbstauskunft',
    {
        state: () => ({
            selbstauskuenfteFetching: null,
            selbstauskuenfteAvailable: null,
            selbstauskuenfte: [],
            ajaxError: null
        }),
        getters: {
            /**
             * @return {SelbstauskunftMeta|Object}
             */
            getLatestReport () {
                const selbstauskuenfte = this.getSelbstauskuenfte

                if (selbstauskuenfte.length){
                    return selbstauskuenfte[0]
                }

                return {}
            },
            /**
             * @param {SelbstauskunftState} state
             * @return {Array<SelbstauskunftMeta>}
             */
            getSelbstauskuenfte (state) {
                if (state.selbstauskuenfte instanceof Array) {
                    return state.selbstauskuenfte.map(selbstauskunft => {
                        let nachtragsgruende = []
                        if (selbstauskunft.nachtragsgruende instanceof Array) {
                            nachtragsgruende = selbstauskunft.nachtragsgruende.map(nachtragsgrund => {
                                return {
                                    fachlicheId: typeof nachtragsgrund.fachlicheId === 'string' ? nachtragsgrund.fachlicheId : 'Aunbekannt',
                                    bezeichnung: typeof nachtragsgrund.bezeichnung === 'string' ? nachtragsgrund.bezeichnung : ''
                                }
                            })
                        }

                        return {
                            auskunftsId: selbstauskunft.auskunftsId,
                            auskunftsdatum: dateHelper.parseIso8601Date(selbstauskunft.auskunftsdatum),
                            nachtragsgruende: nachtragsgruende
                        }
                    })
                }

                return []
            }
        },
        actions: {
            /**
             * Should be called before a new selbstauskunft is fetched.
             */
            GET_SELBSTAUSKUENFTE_FETCHING () {
                this.selbstauskuenfteFetching = true
            },
            /**
             * Should be called with the fetched selbstauskunft data.
             *
             * @param {Array<SelbstauskunftMeta>} data
             */
            GET_SELBSTAUSKUENFTE_SUCCESS (data) {
                this.selbstauskuenfteFetching = false
                this.selbstauskuenfte = data
                this.selbstauskuenfteAvailable = data instanceof Array && data.length > 0
                this.ajaxError = null
            },
            /**
             * Should be called when selbstauskunft could not be fetched.
             *
             * @param {AjaxError} error
             */
            GET_SELBSTAUSKUENFTE_FAILED (error) {
                this.selbstauskuenfteFetching = false
                this.selbstauskuenfteAvailable = false
                this.selbstauskuenfte = []
                this.ajaxError = error
            },
            /**
             * Fetches selbstauskuenfte for the given aboId.
             *
             * @param {string} aboId
             */
            fetchSelbstauskuenfte (aboId) {
                this.GET_SELBSTAUSKUENFTE_FETCHING()
                api.getSelbstauskuenfte(aboId)
                    .then(response => {
                        this.GET_SELBSTAUSKUENFTE_SUCCESS(response.data)
                    })
                    .catch(error => {
                        this.GET_SELBSTAUSKUENFTE_FAILED(error)
                    })
            }
        }
    }
)

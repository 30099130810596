<template>
    <validation-observer v-slot="{ invalid, dirty, handleSubmit }">
        <div class="bg-white box-shadow-xy box-spacing mb">
            <div class="row">
                <div class="col-12">
                    <h2 class="mb-small">
                        {{ $t('Components.Selbstauskunft.Order.Zahlungsvorgang.Zahlungsweise') }}
                    </h2>
                </div>
                <div class="col-12">
                    <crefo-ring-loading
                        v-if="showLoadingSpinner"
                        class="mb"
                    />
                    <zahlungsart
                        v-show="showZahlungsart"
                        v-model="zahlungsart"
                        :abonnement-submitting-tried="abonnementSubmittingTried"
                        :abonnement-submitting="abonnementSubmitting"
                        :abonnement-submitted="abonnementSubmitted"
                        :crefo-pay-initialization-complete="crefoPayInitializationComplete"
                        :secure-fields-enabled="secureFieldsEnabled"
                    />
                </div>
                <div class="col-12">
                    <agb-digimon
                        v-model="agbsAkzeptiert"
                        :abonnement-submitting-tried="abonnementSubmittingTried"
                        :abonnement-submitting="abonnementSubmitting"
                        :abonnement-submitted="abonnementSubmitted"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button
                    class="btn btn-default"
                    :disabled="submitButtonIsDisabled(invalid, dirty)"
                    data-qa="abo-bestellen-button"
                    @click.prevent="setAbonnementSubmittingTried() && handleSubmit(submitAbonnement)"
                >
                    {{ $t('Components.Selbstauskunft.Order.Zahlungsvorgang.ButtonText') }}
                </button>
                <crefo-ring-loading
                    v-if="abonnementSubmitting"
                    class="submitting"
                />
            </div>
        </div>
        <div
            v-if="abonnementSubmitted"
            class="row mt"
        >
            <div class="col-12">
                <crefo-error-message type="info">
                    <div>
                        {{ $t('Components.Selbstauskunft.Order.Zahlungsvorgang.ErfolgsmeldungHeader') }}
                    </div>
                    <div class="mb-small">
                        {{ $t('Components.Selbstauskunft.Order.Zahlungsvorgang.ErfolgsmeldungText')}}
                    </div>
                    <crefo-ring-loading color="crefo-white" />
                </crefo-error-message>
            </div>
        </div>
        <div
            v-if="ajaxError"
            class="row mt"
        >
            <div class="col-12">
                <crefo-error-message
                    v-if="!crefoPayErrorCode"
                    :ajax-error="ajaxError"
                    error-prefix="Zahlungsvorgang"
                />
                <crefo-error-message
                    v-else
                    :ajax-error="ajaxError"
                >
                    <div v-html="crefoPayMappedErrorMessage"></div>
                </crefo-error-message>
            </div>
        </div>
        <div
            v-if="crefoPayInititializationFailed && secureFieldsEnabled"
            class="row mt"
        >
            <div class="col-12">
                <crefo-error-message>
                    <div>
                        {{ $t('Components.Selbstauskunft.Order.Zahlungsvorgang.FehlermeldungHeader') }}
                    </div>
                    <i18n
                        path="Components.Selbstauskunft.Order.Zahlungsvorgang.FehlermeldungText"
                        tag="div"
                    >
                        <a class="mail-link" href="mailto:ds@creditreform.de?subject=Meine%20Bonit%C3%A4t%20-%20Zahlung%20nicht%20m%C3%B6glich">{{ $t('Components.Selbstauskunft.Order.Zahlungsvorgang.FehlermeldungText.LinkText') }}</a>
                    </i18n>
                </crefo-error-message>
            </div>
        </div>
    </validation-observer>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useAbonnementStore } from '@/stores/abonnement'
    import { ValidationObserver } from 'vee-validate'
    import { CrefopayHelper } from '../../../helper/crefopay-helper'
    import Zahlungsart from './partials/zahlungsart'
    import AgbDigimon from './partials/agb-digimon'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    import CrefoErrorMessage from '@dm/crefo-error-message'
    import WrapperWebtrekk from '@/helper/webtrekk-wrapper'
    export default {
        name: 'Zahlungsvorgang',
        components: {
            ValidationObserver, CrefoRingLoading, CrefoErrorMessage, Zahlungsart, AgbDigimon
        },
        props: {
            rechnungsadresse: {
                type: Object,
                default: null
            },
            zahlungsmethode: {
                type: String,
                default: 'PAYPAL'
            },
            acceptTermsAndConditions: {
                type: Boolean,
                default: false
            },
            turnus: {
                type: String,
                default: ''
            },
            crid: {
                type: String,
                required: true
            },
            crefonummer: {
                type: String,
                default: ''
            },
            secureFieldsEnabled: {
                type: Boolean,
                default: true
            },
            shopPublicKey: {
                type: String,
                default: '5f09e93f2c1e3c2371340598438dbeb3a503df89265a9a260a946f3ccc1080ca'
            },
            crefoPayApiUrl: {
                type: String,
                default: 'https://sandbox.crefopay.de/secureFields/'
            },
            crefoPaySecureFieldsUrl: {
                type: String,
                default: 'https://sandbox.crefopay.de/libs/3.0/secure-fields.js'
            },
            jQueryUrl: {
                type: String,
                default: 'https://code.jquery.com/jquery-3.6.0.slim.min.js'
            }
        },
        data () {
            return {
                zahlungsart: {
                    methode: this.zahlungsmethode
                },
                agbsAkzeptiert: {
                    agbCheckboxChecked: this.acceptTermsAndConditions
                },
                abonnementSubmittingTried: false,
                abonnementSubmitting: false,
                abonnementSubmitted: false,
                ajaxError: null,
                crefoPayErrorCode: null,
                crefoPayInititializationFailed: false,
                crefoPayInitializationComplete: false,
                crefoPayPaymentRegistrationFailed: false,
                crefoPayCreditcardErrors: {
                    accountHolder: false,
                    number: false,
                    validity: false,
                    cvv: false
                },
                crefoPayCreditcardErrorsDescription: {
                    accountHolder: '',
                    number: '',
                    validity: '',
                    cvv: ''
                },
                secureFieldsClientInstance: null,
                orderID: 'orderID',
                configuration: {
                    url: this.crefoPayApiUrl,
                    placeholders: {
                        accountHolder: '',
                        number: '',
                        cvv: ''
                    }
                }
            }
        },
        computed: {
            ...mapStores(useAbonnementStore),
            isReportSpracheEmpty () {
                return typeof this.abonnementStore.reportsprache !== 'string' || this.abonnementStore.reportsprache === ''
            },
            submitButtonIsDisabled () {
                return (invalid, dirty) =>
                    ((invalid || this.isReportSpracheEmpty) && this.abonnementSubmittingTried)
                    || this.abonnementSubmitting
                    || this.abonnementSubmitted
                    || (this.secureFieldsEnabled && this.crefoPayInititializationFailed)
            },
            showLoadingSpinner () {
                return this.secureFieldsEnabled && !this.crefoPayInitializationComplete && !this.crefoPayInititializationFailed
            },
            showZahlungsart () {
                return !this.secureFieldsEnabled || this.crefoPayInitializationComplete || this.crefoPayInititializationFailed
            },
            crefoPayMappedErrorMessage () {
                return this.crefoPayErrorCode && this.$i18n.te('CrefoPayErrorCode.' + this.crefoPayErrorCode, 'de_DE') ?
                    this.$i18n.t('CrefoPayErrorCode.' + this.crefoPayErrorCode) : this.$i18n.t('CrefoPayErrorCode.NOT_MAPPED_ERROR')
            }
        },
        mounted () {
            if (this.secureFieldsEnabled) {
                CrefopayHelper.init({
                    loadScript: this.$loadScript,
                    jQueryUrl: this.jQueryUrl,
                    crefoPaySecureFieldsUrl: this.crefoPaySecureFieldsUrl,
                    shopPublicKey: this.shopPublicKey,
                    orderID: this.orderID,
                    paymentRegisteredCallback: this.paymentRegisteredCallback,
                    initializationCompleteCallback: this.initializationCompleteCallback,
                    configuration: this.configuration
                })
                    .then((secureFieldsClientInstance) => {
                        this.secureFieldsClientInstance = secureFieldsClientInstance
                    })
                    .catch((error) => {
                        console.log(error)
                        this.crefoPayInititializationFailed = true
                        this.crefoPayInitializationComplete = false
                    })
            } else {
                this.crefoPayInititializationFailed = true
                this.crefoPayInitializationComplete = false
            }
        },
        destroyed () {
            CrefopayHelper.destruct(this.$unloadScript, this.jQueryUrl, this.crefoPaySecureFieldsUrl)
        },
        methods: {
            setAbonnementSubmittingTried () {
                this.$emit('update:abonnementSubmittingTried', true)
                return this.abonnementSubmittingTried = true
            },
            submitAbonnement () {
                if (this.abonnementSubmitting || this.abonnementSubmitted || this.isReportSpracheEmpty || (!this.crefoPayInitializationComplete && this.secureFieldsEnabled)) {
                    return
                }
                WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_zahlungsseiteContinue_for_digitales_mitglied')

                this.abonnementSubmitting = true
                this.ajaxError = null
                this.crefoPayErrorCode = null

                if (this.secureFieldsEnabled) {
                    // creditcard (not enabled yet)
                    this.secureFieldsClientInstance.registerPayment()
                } else {
                    this.submitAbonnementWithCrefoPay()
                }
            },
            submitAbonnementWithCrefoPay () {
                this.abonnementStore.createAbonnementForDigitalesMitglied({
                    crid: this.crid,
                    crefonummer: this.crefonummer,
                    agbsAkzeptiert: this.agbsAkzeptiert.agbCheckboxChecked,
                    turnus: this.turnus === 'MONATLICH' ? 'monatlich' : 'jaehrlich',
                    zahlungsart: this.zahlungsart.methode === 'PAYPAL' ? 'paypal' : 'kreditkarte',
                    rechnungsadresse: this.rechnungsadresse,
                    reportsprache: this.abonnementStore.reportsprache
                })
                    .then(redirectUrl => {
                        console.log(`Redirect to: ${redirectUrl}`)
                        if (typeof redirectUrl === 'string' && redirectUrl.length) {
                            this.abonnementSubmitted = true
                            WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_zahlungsseiteRedirectPayPal_for_digitales_mitglied')
                            if (redirectUrl.indexOf('/crefopay/mock-pay') !== -1) {
                                this.$router.push({ name: 'crefopay-mock-pay' })
                            } else {
                                window.location.href = redirectUrl
                            }
                        } else {
                            this.ajaxError = { response: { status: 302 }}
                        }
                    })
                    .catch(ajaxError => {
                        this.ajaxError = ajaxError
                        this.crefoPayErrorCode = CrefopayHelper.getMappedErrorCode(ajaxError)
                    })
                    .finally(() => {
                        this.abonnementSubmitting = false
                    })
            },
            paymentRegisteredCallback (response) {
                if (typeof response !== 'object' || response === null) {
                    this.crefoPayPaymentRegistrationFailed = true
                    this.abonnementSubmitting = false
                } else if (response.resultCode !== 0) {
                    this.crefoPayPaymentRegistrationFailed = true
                    this.abonnementSubmitting = false
                    Object.keys(this.crefoPayCreditcardErrors).forEach(field => this.crefoPayCreditcardErrors[field] = false)
                    if (typeof response.errorDetails === 'object' && response.errorDetails !== null) {
                        response.errorDetails.forEach(errorDetail => {
                            if (errorDetail.field === 'paymentInstrument.accountHolder') {
                                this.crefoPayCreditcardErrors.accountHolder = true
                                this.crefoPayCreditcardErrorsDescription.accountHolder = errorDetail.description
                            }
                            if (errorDetail.field === 'paymentInstrument.number') {
                                this.crefoPayCreditcardErrors.number = true
                                this.crefoPayCreditcardErrorsDescription.number = errorDetail.description
                            }
                            if (errorDetail.field === 'paymentInstrument.validity') {
                                this.crefoPayCreditcardErrors.validity = true
                                this.crefoPayCreditcardErrorsDescription.validity = errorDetail.description
                            }
                            if (errorDetail.field === 'paymentInstrument.cvv') {
                                this.crefoPayCreditcardErrors.cvv = true
                                this.crefoPayCreditcardErrorsDescription.cvv = errorDetail.description
                            }
                        })
                    }
                } else {
                    Object.keys(this.crefoPayCreditcardErrors).forEach(field => this.crefoPayCreditcardErrors[field] = false)
                    this.crefoPayPaymentRegistrationFailed = false
                    this.submitAbonnementWithCrefoPay()
                }
            },
            initializationCompleteCallback (response) {
                if (typeof response !== 'object' || response === null) {
                    this.crefoPayInititializationFailed = true
                    this.crefoPayInitializationComplete = false
                } else if (response.resultCode !== 0) {
                    this.crefoPayInititializationFailed = true
                    this.crefoPayInitializationComplete = false
                } else {
                    this.crefoPayInititializationFailed = false
                    this.crefoPayInitializationComplete = true
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .mail-link {
        color: #ffffff;
        text-decoration: underline;
    }

    .loader-wrapper.submitting {
        position: relative;
        width: auto;
        top: 9px;
        left: 15px;
    }

    @media only screen and (max-width: 899px) {
        button {
            line-height: normal;
        }
    }
</style>

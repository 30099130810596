<template>
    <div class="row">
        <div class="col-12 mb-small">
            <validation-provider v-slot="{ errors, invalid, dirty }" rules="required|max:100" :immediate="true">
                <div class="crefo-input">
                    <div class="input-group" :class="{ 'has-error': invalid && (dirty || submittingTried) }">
                        <label for="billingAddressName" data-qa="company-name-label">
                            {{ $t("Rechnungsadresse.Label.CompanyName") }}
                        </label>
                        <div class="input-wrapper">
                            <input
                                id="billingAddressName"
                                v-model="rechnungsadresse.name"
                                type="text"
                                name="billingAddressName"
                                data-qa="different-billing-address-company-name"
                                data-no-crefo-ui="true"
                            />
                        </div>
                        <div class="error-msg">
                            <span data-qa="different-billing-address-company-name-error">
                                {{ errors[0] }}
                            </span>
                        </div>
                    </div>
                </div>
            </validation-provider>
        </div>

        <div class="col-12">
            <div class="row">
                <div class="col-12 col-lg-8 mb-small">
                    <validation-provider v-slot="{ errors, invalid, dirty }" rules="required|max:80" :immediate="true">
                        <div class="crefo-input">
                            <div class="input-group" :class="{ 'has-error': invalid && (dirty || submittingTried) }">
                                <label for="billingAddressStreet" data-qa="street-label">
                                    {{ $t("Rechnungsadresse.Label.Street") }}
                                </label>
                                <div class="input-wrapper">
                                    <input
                                        id="billingAddressStreet"
                                        v-model="rechnungsadresse.strasse"
                                        type="text"
                                        name="billingAddressStreet"
                                        data-qa="different-billing-address-street"
                                        data-no-crefo-ui="true"
                                    />
                                </div>
                                <div class="error-msg">
                                    <span data-qa="different-billing-address-street-error">
                                        {{ errors[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </validation-provider>
                </div>

                <div class="col-12 col-lg-4 mb-small">
                    <validation-provider v-slot="{ errors, invalid, dirty }" rules="max:15" :immediate="true">
                        <div class="crefo-input">
                            <div class="input-group" :class="{ 'has-error': invalid && (dirty || submittingTried) }">
                                <label for="billingAddressHousenumber" data-qa="housenumber-label">
                                    {{ $t("Rechnungsadresse.Label.Housenumber") }}
                                </label>
                                <div class="input-wrapper">
                                    <input
                                        id="billingAddressHousenumber"
                                        v-model="rechnungsadresse.hausnummer"
                                        type="text"
                                        name="billingAddressHousenumber"
                                        data-qa="different-billing-address-housenumber"
                                        data-no-crefo-ui="true"
                                    />
                                </div>
                                <div class="error-msg">
                                    <span data-qa="different-billing-address-housenumber-error">
                                        {{ errors[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </validation-provider>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="row">
                <div class="col-12 col-lg-4 mb-small">
                    <validation-provider
                        v-slot="{ errors, invalid, dirty }"
                        :rules="{ required: true, zipCode: { countryCode: rechnungsadresse.land } }"
                        :immediate="true"
                    >
                        <div class="crefo-input">
                            <div
                                class="input-group"
                                :class="{
                                    'has-error': invalid && (dirty || submittingTried),
                                }"
                            >
                                <label for="billingAddressPostcode" data-qa="postcode-label">
                                    {{ $t("Rechnungsadresse.Label.Postcode") }}
                                </label>
                                <div class="input-wrapper">
                                    <input
                                        id="billingAddressPostcode"
                                        v-model="rechnungsadresse.plz"
                                        type="text"
                                        name="billingAddressPostcode"
                                        data-qa="different-billing-address-postcode"
                                        data-no-crefo-ui="true"
                                    />
                                </div>
                                <div class="error-msg">
                                    <span data-qa="different-billing-address-postcode-error">
                                        {{ errors[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </validation-provider>
                </div>

                <div class="col-12 col-lg-8 mb-small">
                    <validation-provider v-slot="{ errors, invalid, dirty }" rules="required|max:80" :immediate="true">
                        <div class="crefo-input">
                            <div class="input-group" :class="{ 'has-error': invalid && (dirty || submittingTried) }">
                                <label for="billingAddressCity" data-qa="city-label">
                                    {{ $t("Rechnungsadresse.Label.City") }}
                                </label>
                                <div class="input-wrapper">
                                    <input
                                        id="billingAddressCity"
                                        v-model="rechnungsadresse.ort"
                                        type="text"
                                        name="billingAddressCity"
                                        data-qa="different-billing-address-city"
                                        data-no-crefo-ui="true"
                                    />
                                </div>
                                <div class="error-msg">
                                    <span data-qa="different-billing-address-city-error">
                                        {{ errors[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </validation-provider>
                </div>
            </div>
        </div>

        <div class="col-12 mb-small">
            <div class="crefo-input">
                <div class="input-group" data-qa="different-billing-address-country-wrapper">
                    <label for="billingAddressCountry" data-qa="country-label">
                        {{ $t("Rechnungsadresse.Label.Country") }}
                    </label>
                    <div class="input-wrapper">
                        <input
                            id="billingAddressCountry"
                            type="text"
                            :value="countryName"
                            name="billingAddressCountry"
                            data-qa="different-billing-address-country"
                            data-no-crefo-ui="true"
                            readonly
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 mb-small">
            <validation-provider v-slot="{ errors, invalid, dirty }" rules="required|max:100|email" :immediate="true">
                <div class="crefo-input">
                    <div class="input-group" :class="{ 'has-error': invalid && (dirty || submittingTried) }">
                        <label for="billingAddressEmail" data-qa="email-label">
                            {{ $t("Rechnungsadresse.Label.Email") }}
                        </label>
                        <div class="input-wrapper">
                            <input
                                id="billingAddressEmail"
                                v-model="rechnungsadresse.email"
                                type="text"
                                name="billingAddressEmail"
                                data-qa="different-billing-address-email"
                                data-no-crefo-ui="true"
                            />
                        </div>
                        <div class="error-msg">
                            <span data-qa="different-billing-address-email-error">
                                {{ errors[0] }}
                            </span>
                        </div>
                    </div>
                </div>
            </validation-provider>
        </div>

        <div class="col-12 mb-small">
            <validation-provider
                rules="required"
                name="languageProvider"
                ref="languageProvider"
                v-slot="{ errors, invalid, dirty }"
            >
                <div class="crefo-input crefo-ui-select">
                    <div
                        class="input-group"
                        :class="{ 'has-error': invalid && (dirty || submittingTried)}"
                        data-qa="different-billing-communication-language-country-wrapper"
                    >
                        <label for="billingCommunicationLanguage" data-qa="different-billing-communication-language-label">
                            {{ $t("InformationView.Label.Kommunikationssprache") }}
                        </label>
                        <div class="select-group">
                            <div class="input-wrapper">
                                <select
                                    id="billingCommunicationLanguage"
                                    v-model="rechnungsadresse.kommunikationssprache"
                                    name="billingCommunicationLanguage"
                                    data-qa="different-billing-communication-language-country"
                                    data-no-crefo-ui="true"
                                >
                                    <option value="">
                                        {{ $t("InformationView.Label.BitteWaehlen") }}
                                    </option>
                                    <option
                                        v-for="language in languageList"
                                        :key="language.code"
                                        :value="language.code"
                                    >
                                        {{ $t(language.name) }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="error-msg">
                            <span data-qa="different-billing-communication-language-error">
                                {{ errors[0] }}
                            </span>
                        </div>
                    </div>
                </div>
            </validation-provider>
        </div>

        <div>
            <validation-provider
                v-slot="{ errors, invalid, dirty }"
                :rules="{
                    required: hasReverseCharge,
                    max: 30,
                }"
                :immediate="true"
            >
                <div v-show="hasReverseCharge" class="col-12 mb-small">
                    <div class="crefo-input">
                        <div class="input-group" :class="{ 'has-error': invalid && (dirty || submittingTried) }">
                            <label for="billingAddressUmsatzsteuerId" data-qa="UmsatzsteuerId-label">
                                {{ $t("Rechnungsadresse.Label.UmsatzsteuerId") }}
                            </label>
                            <div class="input-wrapper">
                                <input
                                    id="billingAddressUmsatzsteuerId"
                                    v-model="rechnungsadresse.umsatzsteuerId"
                                    type="text"
                                    name="billingAddressUmsatzsteuerId"
                                    data-qa="different-billing-address-UmsatzsteuerId"
                                    data-no-crefo-ui="true"
                                />
                            </div>
                            <div class="error-msg">
                                <span data-qa="different-billing-address-UmsatzsteuerId-error">
                                    {{ errors[0] }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </validation-provider>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useAbonnementStore } from '@/stores/abonnement'
    import { ValidationProvider, extend, localeChanged } from "vee-validate"
    import { required, max, email } from "vee-validate/dist/rules"
    import GeneralUtilityHelper from "@/helper/general"
    import i18n from "@/i18n"
    import countries from "i18n-iso-countries"
    import i18nZipcodes from "i18n-zipcodes"
    import ISO6391 from "iso-639-1"

    extend("required", {
        ...required,
        message: (value, args) => {
            return i18n.t("Rechnungsadresse.Fehlertext.Required")
        }
    })

    extend("max", {
        ...max,
        message: (value, args) => {
            return i18n.t("Rechnungsadresse.Fehlertext.Max", [args.length])
        }
    })

    extend("email", {
        ...email,
        message: (value, args) => {
            return i18n.t("Rechnungsadresse.Fehlertext.Email")
        }
    })

    extend("zipCode", {
        params: [ "countryCode" ],
        validate: (value, { countryCode }) => {
            if (typeof countryCode === "string" && countryCode.length) {
                // Land befüllt
                if (typeof value === "string" && value.length && i18nZipcodes(countryCode.toUpperCase(), value) === false) {
                    return false
                }
                return true
            } else if (typeof value !== "string" || value.length === 0) {
                // Land und PLZ leer
                return true
            } else {
                // Land leer aber PLZ befüllt
                let result = false
                GeneralUtilityHelper.getAllowedCountries().forEach(countryCoding => {
                    if (i18nZipcodes(countryCoding.toUpperCase(), value)) {
                        result = true
                    }
                })
                return result
            }
        },
        message: (value, args) => {
            return i18n.t("Rechnungsadresse.Fehlertext.Zip")
        }
    })

    export default {
        name: "FormularRechnungsadresse",
        components: { ValidationProvider },
        props: {
            rechnungsadresse: {
                type: Object,
                required: true
            },
            submittingTried: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data () {
            return {
                selectedLanguage: null
            }
        },
        computed: {
            ...mapStores(useAbonnementStore),
            countryName () {
                const lang = this.$i18n.locale.split("_")[0].toLowerCase().replace("at", "de")
                return countries.getName(this.rechnungsadresse.land, lang || "de", { select: "official" })
            },
            hasReverseCharge () {
                return this.rechnungsadresse.land === "AT" || this.rechnungsadresse.land === "LU"
            },
            languageList () {
                return ISO6391.getLanguages([ 'de', 'fr' ])
            }
        },
        watch: {
            selectedLanguage (newValue, oldValue) {
                this.abonnementStore.ON_CHANGE_RECHNUNGSADRESSE_KOMMUNIKATIONSSPRACHE(newValue)
            },
            '$i18n.locale' (newValue) {
                localeChanged()
            }
        }
    }
</script>

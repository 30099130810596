<template>
    <div>
        <div class="bg-brand bg-brand-light-grey box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="h1 mb-small">{{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Header') }}</div>
                        <div class="h2 mb-small">{{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.SubHeader') }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-7 col-xl-8">
                        <div class="box-teaser">
                            <dynamic-img-tag src-template="{PUBLIC_PATH}assets/img/selbstauskunft-snippet-min-{LANG}.png" />
                        </div>
                    </div>
                    <div class="col-12 col-lg-5 col-xl-4">
                        <div class="row">
                            <div class="col-12">
                                <h2>
                                    {{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box1Header') }}
                                </h2>
                                <ul class="list-unordered list-checked">
                                    <li>{{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box1Line1') }}</li>
                                    <li>{{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box1Line2') }}</li>
                                    <li>{{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box1Line3') }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-brand box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-5 col-xl-4">
                        <div class="row">
                            <div class="col-12">
                                <h2>
                                    {{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box2Header') }}
                                </h2>
                                <ul class="list-unordered list-checked">
                                    <li>{{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box2Line1') }}</li>
                                    <li>{{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box2Line2') }}</li>
                                    <li>{{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box2Line3') }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-7 col-xl-8">
                        <div class="box-teaser">
                            <dynamic-img-tag src-template="{PUBLIC_PATH}assets/img/monitoring-snippet-min-{LANG}.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-brand bg-brand-light-grey box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-7 col-xl-8">
                        <div class="box-teaser">
                            <dynamic-img-tag src-template="{PUBLIC_PATH}assets/img/daten-snippet-min-{LANG}.png" />
                        </div>
                    </div>
                    <div class="col-12 col-lg-5 col-xl-4">
                        <div class="row">
                            <div class="col-12">
                                <h2>
                                    {{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box3Header') }}
                                </h2>
                                <ul class="list-unordered list-checked">
                                    <li>{{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box3Line1') }}</li>
                                    <li>{{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box3Line2') }}</li>
                                    <li>{{ $t('Components.Selbstauskunft.Order.Landingpage.MoreInformation.Box3Line3') }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DynamicImgTag from "@/components/helper/dynamic-img-tag.vue"

    export default {
        name: 'MoreInformation',
        components: { DynamicImgTag }
    }
</script>

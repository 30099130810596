<template>
    <div
        v-if="mockPayActive"
        class="container pt"
    >
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-11">
                <div class="mock-pay-container">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div style="overflow: hidden; clear: both;">
                                <div class="mock-pay-logo">
                                    <img src="https://emoji.slack-edge.com/TAEEMFELE/mumpitz/e06d512a7b2c3259.gif" width="23" height="15" />
                                    <span class="mock-pay-logo-left">Mock</span>
                                    <span class="mock-pay-logo-right">Pay</span>
                                </div>
                                <div class="mock-pay-summe">
                                    25,00 MockTaler (zzgl. MockSteuer)
                                </div>
                            </div>
                            <p class="mock-pay-user">
                                Hallo {{ userStore.user.givenName }} {{ userStore.user.surname }}
                            </p>
                            <div class="mock-pay-with">
                                <p class="h3">Rübermocken mit</p>
                                <div class="crefo-input">
                                    <div class="input-group">
                                        <div class="radio-group">
                                            <div class="input-wrapper">
                                                <div class="crefo-radio">
                                                    <div class="radio-btn">
                                                        <input type="radio" id="A" name="mock-method" checked value="mock-guthaben">
                                                        <label for="A">
                                                            <span class="radio-box"><span class="radio-box-circle"></span></span>
                                                            <span class="radio-label">Mock-Guthaben</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="crefo-radio">
                                                    <div class="radio-btn">
                                                        <input type="radio" id="B" name="mock-method" value="mock-misa">
                                                        <label for="B">
                                                            <span class="radio-box"><span class="radio-box-circle"></span></span>
                                                            <span class="radio-label">Misa x-5890</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="crefo-radio">
                                                    <div class="radio-btn">
                                                        <input type="radio" id="C" name="mock-method" value="mock-mockster">
                                                        <label for="C">
                                                            <span class="radio-box"><span class="radio-box-circle"></span></span>
                                                            <span class="radio-label">Mockstercard x-1275</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="crefo-radio">
                                                    <div class="radio-btn">
                                                        <input type="radio" id="D" name="mock-method" value="mock-mockbank">
                                                        <label for="D">
                                                            <span class="radio-box"><span class="radio-box-circle"></span></span>
                                                            <span class="radio-label">Mockbank x-0943</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mock-pay-jetzt">
                                <button
                                    class="btn btn-default btn-block"
                                    :disabled="mocking"
                                    @click.prevent="mockNow"
                                    data-qa="jetzt-rueber-mocken"
                                >
                                    Geld jetzt rübermocken
                                </button>
                                <div class="mock-pay-note text-small">
                                    Sie können Ihren Mockwunsch überprüfen, bevor Sie Ihren Mock abschließen
                                </div>
                            </div>
                        </div>
                        <div class="col-6 d-none d-lg-block">
                            <div class="mock-pay-shield text-center">
                                <i class="crefo-brand-icon icon-shield icon-color-blue" aria-hidden="true"></i>
                            </div>
                            <div class="h3 pt text-center" style="padding-left: 20px; padding-right: 20px;">
                                Mit MockPay einfacher, schneller und sicherer bemocken
                            </div>
                            <p class="text-center" style="padding-left: 20px; padding-right: 20px;">
                                Ihre Mockdaten bleiben bei uns sicher vermockt.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="mb text-center text-small">
                    <a
                        :href="'/portal/meine-bonitaet/crefopay/zahlungsvorgang-nicht-erfolgreich?merchantID=mockMuhHaendler&storeID=mockMuhStore&orderID=' + orderID"
                        data-qa="hier-wird-nix-gemocked"
                    >
                        Mocken abbrechen
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useAbonnementStore } from '@/stores/abonnement'
    export default {
        name: 'CrefopayMockPay',
        data () {
            return {
                mockPayActive: false,
                mocking: false
            }
        },
        computed: {
            ...mapStores(useUserStore, useAbonnementStore),
            orderID: function () {
                return this.abonnementStore.selectedAbonnement ?
                    this.abonnementStore.selectedAbonnement.aboId : 'XXX'
            }
        },
        created () {
            if (location.host.indexOf('sedevcp.') === 0 || location.host.indexOf('abcp.') === 0) {
                this.mockPayActive = true
            } else {
                location.href = '/'
            }
        },
        methods: {
            mockNow () {
                if (this.mocking) {
                    return
                }
                this.mocking = true;
                window.location.href = '/portal/meine-bonitaet/crefopay/zahlungsvorgang-erfolgreich'
                    + '?merchantID=mockMuhHaendler&storeID=mockMuhStore&orderID=' + this.orderID
            }
        }
    }
</script>

<style scoped lang="less">
    .mock-pay-container {
        border: 1px solid #b5b5b5;
        border-radius: 12px;
        margin-top: 25px;
        padding: 30px 30px 50px;
        overflow: hidden;

        .text-center {
            text-align: center;
        }

        .mock-pay-shield {
            zoom: 3;
        }

        .mock-pay-logo {
            zoom: 1.5;
            font-weight: bold;
            float: left;
            line-height: 24px;

            .mock-pay-logo-left {
                position: relative;
                top: 2px;
                padding-left: 5px;
                color: #004884;
            }

            .mock-pay-logo-right {
                position: relative;
                top: 2px;
                color: #009ee2;
            }
        }

        .mock-pay-summe {
            float: right;
            padding-top: 11px;
        }

        .mock-pay-user {
            margin: 20px 0 0 0;
            border-top: 1px solid #b5b5b5;
            padding-top: 13px;
        }

        .mock-pay-with {
            margin: 13px 0 20px 0;
            border-top: 1px solid #b5b5b5;
            padding-top: 13px;

            .h3 {
                color: #4c4c4c;
                margin-bottom: 15px;
            }

            .crefo-radio {
                display: block;
                margin-bottom: 15px;

                .radio-label {
                    padding-left: 10px;
                }
            }
        }

        .mock-pay-jetzt {
            .btn-default {
                border-radius: 12px!important;
            }
        }

        .mock-pay-note {
            padding-top: 15px;
        }
    }

    .text-center {
        text-align: center;
    }
</style>

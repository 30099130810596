<template>
    <crefo-kurzprofil
        :kurzprofil="fetchKurzprofil"
        :crefonummer="crefonummer"
        :silent-update-serial="$i18n.locale.split('_')[0]"
        :show-header="showHeader"
        :show-country="true"
        :translations="{ 'Kurzprofil.Label.Kurzprofil': $t('Components.Selbstauskunft.Order.Landingpage.SuggestedKurzprofil.KurzprofilHeader') }"
        design="WhiteBoxVertical"
        error-prefix="Kurzprofil"
        v-on="$listeners"
    />
</template>

<script>
    import CrefoKurzprofil from '@dm/crefo-kurzprofil'
    import * as api from '../../../../services/index'
    export default {
        name: 'SuggestedKurzprofil',
        components: { CrefoKurzprofil },
        props: {
            crefonummer: {
                type: String,
                required: true
            },
            showHeader: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        computed: {
            kurzprofilSprache () {
                return this.$i18n.locale.split('_')[0]
            }
        },
        methods: {
            fetchKurzprofil (crefonummer) {
                return api.getKurzprofil(crefonummer, this.kurzprofilSprache)
            }
        }
    }
</script>

<template>
    <div
        v-if="isLoading"
        class="container pt mb-large"
    >
        <crefo-breadcrumbs>
            <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
            <li>
                <router-link :to="{name: 'index'}">
                    {{ $t('Appname') }}
                </router-link>
            </li>
        </crefo-breadcrumbs>
        <crefo-ring-loading additional-classes="mt" />
    </div>
    <div v-else>
        <selbstauskunft-order-abonnement
            :verified="verified"
        />
    </div>
</template>

<script>
    /**
     * Diese Komponente ist nur sichtbar, wenn
     *   - Der Benutzer hat noch kein Abonnement (das zur Crefonummer passt)
     *   - Der Benutzer hat ein Abonnement, ist aber nicht Vertretungsberechtigt (für die Crefonummer)
     */
    import { mapStores } from 'pinia'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import CrefoBreadcrumbs from '../components/helper/crefo-breadcrumbs'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    import SelbstauskunftOrderAbonnement from '../components/selbstauskunft/order/abonnement'
    export default {
        name: 'SelbstauskunftAbonnement',
        components: {
            CrefoBreadcrumbs,
            CrefoRingLoading,
            SelbstauskunftOrderAbonnement
        },
        props: {
            verified: {
                type: Boolean,
                default: null
            }
        },
        computed: {
            ...mapStores(useKurzprofilStore),
            isLoading: function () {
                return this.kurzprofilStore.kurzprofilFetching !== false
            }
        }
    }
</script>

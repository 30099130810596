<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
                <li>
                    <router-link :to="{ name: 'index' }">
                        {{ $t('Appname') }}
                    </router-link>
                </li>
                <li data-qa="headline">
                    {{ $t('View.CrefopayErfolgsseite.BreadcrumbTitle') }}
                </li>
            </crefo-breadcrumbs>

            <div class="row mb">
                <div class="col-12">
                    <div class="mb">
                        <i
                            class="crefo-brand-icon icon-check-circle icon-color-blue"
                            aria-hidden="true"
                        />
                    </div>
                    <h1
                        class="h2 mb-small"
                        data-qa="crefopay-bestellung-erfolgreich"
                    >
                        <span v-if="currentAboId">
                            {{ $t('View.CrefopayErfolgsseite.AboIdPrefix') }}
                            <span data-qa="aboId">{{ currentAboId }}</span>
                            {{ $t('View.CrefopayErfolgsseite.AboIdPostfix') }}
                        </span>
                        <span v-else>
                            {{ $t('View.CrefopayErfolgsseite.AboIdFehlt') }}
                        </span>
                    </h1>
                    <p class="h4 mb-small d-none">
                        {{ $t('View.CrefopayErfolgsseite.AboStatus') }}
                        <span
                            class="text-semibold"
                            data-qa="aboStatus"
                        >
                            {{ $t(`Abonnement.State.${currentAboStatus}`) }}
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <div class="bg-brand bg-brand-blue box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-7 col-xl-8 mb">
                        <div class="box-teaser">
                            <img
                                src="@/assets/img/MeineBonitaet-Mockup-Freisteller.png"
                                alt=""
                            >
                        </div>
                    </div>

                    <div class="col-12 col-lg-5 col-xl-4">
                        <div class="row pt">
                            <div class="col-12">
                                <h2 class="text-white">
                                    {{ $t('View.CrefopayErfolgsseite.TeaserText') }}
                                </h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <h3 class="text-white mb-small">
                                    <span>{{ $t('View.CrefopayErfolgsseite.ProductTitle') }} </span>
                                    <span data-qa="firmenname">{{ kurzprofilStore.getFirmenname }}</span>
                                </h3>
                            </div>
                        </div>
                        <div v-if="errorVisible">
                            <crefo-error-message
                                :ajax-error="lastAjaxError"
                                error-prefix="CrefoPaySuccessPage"
                            />
                        </div>
                        <div v-else>
                            <div class="row">
                                <div class="col-12">
                                    <div
                                        v-if="!isLoading"
                                        class="h3"
                                    >
                                        <p>
                                            <router-link
                                                :to="{ name: 'selbstauskunft-show', params: { aboId: aboId } }"
                                                class="btn btn-default bg-brand-purple"
                                                role="button"
                                                data-qa="show-button"
                                            >
                                                <span>{{ $t('Abonnement.Label.Weiter') }}</span>
                                            </router-link>
                                        </p>
                                    </div>
                                </div>
                                <div
                                    v-if="isLoading"
                                    class="col-12 text-bold text-white"
                                >
                                    <crefo-ring-loading color="crefo-white" />
                                    <p class="pt mb-small">
                                        {{ $t('View.CrefopayErfolgsseite.WaitingText') }}
                                    </p>
                                    <p>
                                        {{ $t('View.CrefopayErfolgsseite.WaitingText2') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import { useAbonnementStore } from '@/stores/abonnement'
    import CrefoBreadcrumbs from '@/components/helper/crefo-breadcrumbs'
    import CrefoRingLoading from '@dm/crefo-ring-loading'
    import CrefoErrorMessage from '@dm/crefo-error-message'
    import * as api from '@/services/index.js'
    import WrapperWebtrekk from '@/helper/webtrekk-wrapper'
    export default {
        name: 'CrefopayErfolgsseite',
        components: { CrefoBreadcrumbs, CrefoRingLoading, CrefoErrorMessage },
        props: {
            merchantId: {
                type: String,
                required: false,
                default: ''
            },
            storeId: {
                type: String,
                required: false,
                default: ''
            },
            orderId: {
                type: String,
                required: false,
                default: ''
            },
            paymentReference: {
                type: String,
                required: false,
                default: ''
            },
            merchantReference: {
                type: String,
                required: false,
                default: ''
            }
        },
        data () {
            return {
                aboId: '',
                isLoading: true,
                errorCounter: 0,
                errorVisible: false,
                lastAjaxError: null,
                currentAboId: null,
                currentAboStatus: 'UNBEKANNT',
                matchingAboId: null
            }
        },
        computed: {
            ...mapStores(useUserStore, useKurzprofilStore, useAbonnementStore)
        },
        mounted () {
            if (this.userStore.crefonummer && this.userStore.user && this.userStore.user.cr_id) {
                this.setAbonnementInfo()
                this.checkIfAbonnementIsAvailable()
            }
        },
        methods: {
            setAbonnementInfo () {
                const currentAboId = sessionStorage.getItem('meine_bonitaet_aboId') || null
                const waitingAbonnements = this.abonnementStore.abonnementsVertretungsberechtigt.filter(abonnement =>
                    [ 'AKTIV', 'NICHT_BEGONNEN', 'VORBEREITET' ].includes(abonnement.status))
                let matchingAbonnement = waitingAbonnements.find(abonnement => currentAboId === abonnement.aboId)
                if (!matchingAbonnement && this.orderId) {
                    matchingAbonnement = waitingAbonnements.find(abonnement => this.orderId === abonnement.aboId)
                }
                if (matchingAbonnement) {
                    this.currentAboId = matchingAbonnement.aboId
                    this.currentAboStatus = matchingAbonnement.status
                    this.matchingAboId = matchingAbonnement.aboId
                } else if (currentAboId) {
                    this.currentAboId = currentAboId
                    this.currentAboStatus = 'UNBEKANNT'
                }
            },
            checkIfAbonnementIsAvailable (timeout = 3000, timeElapsed = 0) {
                api.getAbonnements(this.userStore.user.cr_id)
                    .then(response => {
                        this.abonnementStore.GET_ABONNEMENTS_SUCCESS(response.data)
                    })
                    .catch(err => {
                        ++this.errorCounter
                        this.lastAjaxError = err
                        this.abonnementStore.GET_ABONNEMENTS_FAILED(err)
                    })
                    .finally(() => {
                        this.setAbonnementInfo()
                        const selectedAbonnement = this.abonnementStore.abonnementsVertretungsberechtigt.find(abonnement => {
                            if (this.matchingAboId) {
                                return [ 'AKTIV' ].includes(abonnement.status) && this.matchingAboId === abonnement.aboId
                            } else {
                                return [ 'AKTIV' ].includes(abonnement.status) && this.userStore.crefonummer === abonnement.crefonummer
                            }
                        })
                        if (selectedAbonnement) {
                            this.userStore.ON_SET_CREFONUMMER_SUCCESS(selectedAbonnement.crefonummer)
                            this.userStore.ON_SET_VERTRETUNGSBERECHTIGT(true)
                            this.abonnementStore.ON_SELECT_ABONNEMENT(selectedAbonnement)
                            this.aboId = selectedAbonnement.aboId
                            this.isLoading = false
                            WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_erfolgsseiteAboReady_for_digitales_mitglied')
                        } else if (this.errorCounter < 5 && timeElapsed < 3600000) { // max. 1 hour
                            let nextTimeout = timeout + Math.floor(timeout / 4)
                            if (nextTimeout > 600000) { // max. 10 minutes
                                nextTimeout = 600000
                            }
                            setTimeout(() => {
                                this.checkIfAbonnementIsAvailable(nextTimeout, timeElapsed + timeout)
                            }, timeout)
                        } else {
                            if (!this.lastAjaxError) {
                                WrapperWebtrekk.trackAction('meine_bonitaet_frontend_action_erfolgsseiteTimeout_for_digitales_mitglied')
                                this.lastAjaxError = {
                                    response: {
                                        status: 408 // Timeout
                                    }
                                }
                            }
                            this.errorVisible = true
                            this.isLoading = false
                        }
                    })
            }
        }
    }
</script>

<style scoped lang="less">
    .icon-check-circle {
        font-size: 73px;
    }

    span.text-semibold {
        font-weight: normal;
        text-transform: uppercase;
    }

    .bg-brand-blue {
        margin-bottom: -60px;

        @media only screen and (min-width: 992px) {
            padding-bottom: 0 !important;
        }

        .box-teaser {
            margin-top: 15px;

            img {
                width: 70%;
            }

            & > div {
                a.btn {
                    margin-top: 3px;
                }
            }
        }
    }
</style>

<script>
    export default {
        name: 'CrefoBreadcrumbs',
        render: function (createElement) {
            return createElement('div', { class: 'row' }, [
                createElement('div', { class: 'col-12' }, [
                    createElement('div', { class: 'breadcrumbs-wrapper' }, [
                        createElement('ul', { class: 'breadcrumbs' }, this.$slots.default)
                    ])
                ])
            ])
        }
    }
</script>

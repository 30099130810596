<template>
    <div class="row">
        <div class="col-12">
            <h2 class="mb-small">
                {{ $t('Components.Selbstauskunft.Order.Zahlungsvorgang.ReportLanguageSelectionHeadline') }}
            </h2>
        </div>
        <div class="col-12">
            <div class="crefo-input crefo-ui-select">
                <div
                    class="input-group"
                    :class="{ 'has-error': showValidationError && hasValidationError }"
                    data-qa="report-language-wrapper"
                >
                    <label
                        for="reportLanguage"
                        data-qa="report-language-label"
                    >
                        {{ $t('Components.Selbstauskunft.Order.Zahlungsvorgang.ReportLanguageSelectionLabel') }}
                    </label>
                    <div class="select-group">
                        <div class="input-wrapper">
                            <select
                                id="reportLanguage"
                                v-model="reportLanguage"
                                name="reportLanguage"
                                data-qa="report-language"
                                data-no-crefo-ui="true"
                            >
                                <option
                                    value=""
                                    data-qa="report-language-empty"
                                >
                                    {{ $t('Components.Selbstauskunft.Order.Zahlungsvorgang.ReportLanguageBitteWaehlen') }}
                                </option>
                                <option
                                    v-for="language in languageSelection"
                                    :key="language.code"
                                    :value="language.code"
                                    :data-qa="'report-language-' + language.code"
                                >
                                    {{ $t(language.name) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="error-msg">
                        <span data-qa="report-language-error">
                            {{ $t('Components.Selbstauskunft.Order.Zahlungsvorgang.ReportLanguageValidationError') }}
                        </span>
                    </div>
                </div>
            </div>
            <p data-qa="report-language-hinweis">
                {{ $t('Components.Selbstauskunft.Order.Zahlungsvorgang.ReportLanguageNote') }}
            </p>
        </div>
    </div>
</template>

<script>
    import ISO6391 from 'iso-639-1'

    export default {
        name: 'ReportLanguageSelection',
        props: {
            preselectedReportLanguage: {
                type: String,
                default: null
            },
            showValidationError: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hasValidationError () {
                return this.reportLanguage === ''
            },
            reportLanguage: {
                get () {
                    const allowedReportLanguages = this.languageSelection.map(language => language.code)
                    if (allowedReportLanguages.includes(this.preselectedReportLanguage)) {
                        return this.preselectedReportLanguage
                    } else {
                        return ''
                    }
                },
                set (newValue) {
                    this.$emit('update:reportLanguage', newValue)
                }
            },
            languageSelection () {
                return ISO6391.getLanguages([ 'de', 'fr', 'en' ])
            }
        }
    }
</script>

<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-7 col-xl-8 mb">
                    <h2>
                        {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.Header') }}
                    </h2>
                    <p>
                        {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.Subline') }}
                    </p>
                </div>
                <div class="col-12 col-lg-5 col-xl-4">
                    <div class="box-shadow-xy box-teaser">
                        <img src="@/assets/img/bild_tablet-auskunft.jpg" alt="" />
                        <div class="box-spacing">
                            <h3 class="mb-small">
                                {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.PreisHeader') }}
                            </h3>
                            <ul class="list-unordered list-checked">
                                <li>
                                    {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.PreisReason1') }}
                                </li>
                                <li>
                                    {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.PreisReason2') }}
                                </li>
                                <li>
                                    {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.PreisReason3') }}
                                </li>
                            </ul>
                            <div>
                                <template v-if="showPrice">
                                    <p>
                                        <strong>{{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.Monatlich') }}</strong>
                                    </p>
                                    <p class="h2 mb-small px-small">
                                        {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.MonatlichBetrag') }}<sup>*</sup>
                                    </p>
                                    <p>
                                        <sup>*</sup> {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.MonatlichSternchen') }}
                                    </p>
                                </template>
                                <slot></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-brand bg-brand-blue box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center">
                            {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.NichtUeberzeugt') }}
                        </h2>
                    </div>
                </div>
                <div class="row row-reasons">
                    <div class="col-12 col-lg-4 mb">
                        <img src="@/assets/img/icon-24-7.png" alt="" />
                        <p class="h2 pt-small mb-small">
                            {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.NichtsVerpassen') }}
                        </p>
                        <p>
                            {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.NichtsVerpassenText') }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-4 mb">
                        <img src="@/assets/img/icon-contact.png" alt="" />
                        <p class="h2 pt-small mb-small">
                            {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.24Service') }}
                        </p>
                        <p>
                            {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.24ServiceText') }}
                        </p>
                    </div>
                    <div class="col-12 col-lg-4 mb">
                        <i class="crefo-ui-icon icon-circle-success icon-color-white" aria-hidden="true"></i>
                        <p class="h2 pt-small mb-small">
                            {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.Update') }}
                        </p>
                        <p>
                            {{ $t('Components.Selbstauskunft.Order.Partials.TextAboutVerifikation.UpdateText') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SelbstauskunftOrderTextAboutVerifikation',
        props: {
            showPrice: {
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style scoped lang="less">
    .box-teaser {
        img {
            width: 100%;
        }

        & > div {
            a.btn {
                margin-top: 3px;
            }
        }
    }

    .bg-brand {
        margin-top: 60px;
        margin-bottom: -60px;
        padding-bottom: 0 !important;

        .text-center {
            text-align: center;
        }

        h2, p, span {
            color: #ffffff;
        }

        @media only screen and (max-width: 1199px) {
            h2 {
                margin-bottom: 30px;
            }
        }

        .row-reasons {
            & > div {
                text-align: center;

                i {
                    display: inline-block;
                    font-size: 73px;
                }

                img {
                    width: 73px;
                    height: 73px;
                }

                span {
                    line-height: 1.7;
                }
            }
        }
    }
</style>

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import validationMessagesEN from 'vee-validate/dist/locale/en.json'
import validationMessagesDE from 'vee-validate/dist/locale/de.json'
import validationMessagesFR from 'vee-validate/dist/locale/fr.json'
import messagesDE from '@/i18n/de_DE.json'
import messagesUS from '@/i18n/en_US.json'
import messagesLU from '@/i18n/fr_LU.json'

Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'de_DE',
    fallbackLocale: {
        'de_DE': [ 'de_AT', 'en_US', 'fr_FR', 'fr_LU' ],
        'de_AT': [ 'de_DE', 'en_US', 'fr_FR', 'fr_LU' ],
        'fr_FR': [ 'fr_LU', 'en_US', 'de_DE', 'de_AT' ],
        'fr_LU': [ 'fr_FR', 'en_US', 'de_DE', 'de_AT' ],
        'default': [ 'en_US', 'fr_FR', 'fr_LU', 'de_DE', 'de_AT' ]
    },
    messages: loadLocaleMessages(),
    silentTranslationWarn: true
})

function loadLocaleMessages () {
    // In case of unit testing
    if (process && process.env && process.env.NODE_ENV === 'test') {
        return {
            de_DE: Object.assign(messagesDE, validationMessagesDE.messages),
            en_US: Object.assign(messagesUS, validationMessagesEN.messages),
            fr_LU: Object.assign(messagesLU, validationMessagesFR.messages)
        }
    }

    const locales = require.context('./i18n', true, /([a-z]{2})+[_]+([A-Z]{2})+\.json$/)
    const messages = {}

    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)

            if (window.sharedTranslations[locale]) {
                for (let i in window.sharedTranslations[locale]) {
                    messages[locale][`shared.${i}`] = window.sharedTranslations[locale][i]
                }
            }
            // vee validate validation messages
            switch (locale) {
                case 'de_DE':
                    messages[locale] = Object.assign(messages[locale], validationMessagesDE.messages)
                    break
                case 'de_AT':
                    messages[locale] = Object.assign(messages[locale], validationMessagesDE.messages)
                    break
                case 'fr_LU':
                    messages[locale] = Object.assign(messages[locale], validationMessagesFR.messages)
                    break
                case 'fr_FR':
                    messages[locale] = Object.assign(messages[locale], validationMessagesFR.messages)
                    break
                case 'en_EN':
                    messages[locale] = Object.assign(messages[locale], validationMessagesEN.messages)
                    break
            }
        }
    })

    return messages
}

export default i18n

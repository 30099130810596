<template>
    <img
        :src="dynamicSrc"
        alt=""
    >
</template>

<script>
    import DynamicTagHelper from '@/mixins/dynamic-tag-helper'

    export default {
        name: 'DynamicImgTag',
        mixins: [ DynamicTagHelper ],
        props: {
            srcTemplate: {
                type: String,
                default: ''
            }
        },
        computed: {
            dynamicSrc () {
                return this.srcTemplate
                    .replace('{PUBLIC_PATH}', this.publicPath)
                    .replace('{LANG}', this.languageVariant)
            }
        }
    }
</script>

<template>
    <div>
        <div class="container pt">
            <crefo-breadcrumbs>
                <li><a href="/">{{ $t('shared.breadcrumbs.home') }}</a></li>
                <li>
                    <router-link :to="{name: 'index'}">
                        {{ $t('Appname') }}
                    </router-link>
                </li>
                <li data-qa="headline">
                    {{ $t('Breadcrumbs.Bestellen.Abonnement') }}
                </li>
            </crefo-breadcrumbs>
            <div class="row">
                <div class="col-12">
                    <h1 data-qa="kopfzeile-firmenname">
                        {{ kurzprofilStore.getFirmenname }}
                    </h1>
                </div>
            </div>
        </div>
        <information-zahlungsturnus />
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useKurzprofilStore } from '@/stores/kurzprofil'
    import InformationZahlungsturnus from './partials/information-zahlungsturnus'
    import CrefoBreadcrumbs from '../../helper/crefo-breadcrumbs'
    export default {
        name: 'Zahlungsturnus',
        components: { InformationZahlungsturnus, CrefoBreadcrumbs },
        computed: {
            ...mapStores(useKurzprofilStore)
        }
    }
</script>

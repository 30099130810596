import axios from 'axios'
import { axiosGetCached } from '@/helper/axios-cache'

axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
}

/**
 * Send a GET request to fetch matching company
 *
 * @param {string} crefonummer
 * @param {string} [sprache]
 * @returns {Promise<AxiosResponse<T>>}
 * @see
 */
export const getKurzprofil = (crefonummer, sprache = 'de') =>
    axiosGetCached(`/api/meine-bonitaet/kurzprofile/${crefonummer}`, { params: { sprache }, cache: { ttl: 60 }})

/**
 * GET Abonnements
 *
 * @param {string} crid
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getAbonnements = crid =>
    axios.get('/api/meine-bonitaet/portal/abonnements', { params: { crid }})

/**
 * PUT Rechnungsadresse for Abonnement
 *
 * @param {string} aboId
 * @param {object} rechnungsadresse
 * @param {string} rechnungsadresse.name
 * @param {string} rechnungsadresse.strasse
 * @param {string} rechnungsadresse.hausnummer
 * @param {string} rechnungsadresse.plz
 * @param {string} rechnungsadresse.ort
 * @param {string} rechnungsadresse.land
 * @param {string} rechnungsadresse.email
 * @returns {Promise<AxiosResponse<T>>}
 */
export const setRechnungsadresseForAbonnement = (aboId, rechnungsadresse) =>
    axios.put(`/api/meine-bonitaet/portal/abonnements/${aboId}/rechnungsadresse`, rechnungsadresse)

/**
 * GET Selbstauskünfte
 *
 * @param {string} aboId
 * @param {object} [cache]
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getSelbstauskuenfte = aboId =>
    axiosGetCached('/api/meine-bonitaet/selbstauskuenfte', {params: {aboId}, cache: {ttl: 60}})

/**
 * GET pdf for Selbstauskunft
 *
 * @param {number} auskunftsId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getPdf = auskunftsId =>
    axios.get(`/api/meine-bonitaet/selbstauskuenfte/${auskunftsId}/pdf`, {responseType: "blob"})

/**
 * Get Member data for current user.
 *
 * @return {Promise<AxiosResponse<T>>}
 */
export const getMitglied = () =>
    axios.get(`/cbra/members`)

/**
 * Get User data for current user.
 *
 * @return {Promise<AxiosResponse<T>>}
 */
export const getUser = () => {
    const loginHost = getLoginHost()
    return axios.get(`https://${loginHost}/api/user-management/persons/me`, {
        transformRequest: (data, headers) => {
            transformHeadersForCORS(headers)
            return data
        }
    })
}

/**
 * Patch User data for current user.
 *
 * @param {Object} payload - JSON Patch Object
 * @return {Promise<AxiosResponse<T>>}
 */
export const patchUser = payload => {
    let data = ''
    try {
        data = JSON.stringify(payload)
    } catch (e) {
        return new Promise((resolve, reject) => {
            reject({ response: { status: 400 } })
        })
    }
    const loginHost = getLoginHost()
    return axios.patch(`https://${loginHost}/api/user-management/persons/me`, data, {
        headers: {
            'Content-Type': 'application/json-patch+json'
        },
        transformRequest: (data, headers) => {
            transformHeadersForCORS(headers)
            return data
        }
    })
}

/**
 * Send a POST request to create an abonnement for a member user.
 *
 * @param {string} crid
 * @param {string} crefonummer
 * @param {boolean} agbsAkzeptiert
 * @param {string} reportsprache
 * @returns {Promise<AxiosResponse<T>>}
 */
export const createAbonnementForMitglied = (crid, crefonummer, agbsAkzeptiert, reportsprache) =>
    axios.post(`/api/meine-bonitaet/portal/actions/mitglieder/do-abo-anlegen`, {crid, crefonummer, agbsAkzeptiert, reportsprache})

/**
 * Send a POST request to create an abonnement for a registered user.
 *
 * @param {string} crid
 * @param {string} crefonummer
 * @param {boolean} agbsAkzeptiert
 * @param {string} turnus
 * @param {string} zahlungsart
 * @param {object} rechnungsadresse
 * @param {string} rechnungsadresse.name
 * @param {string} rechnungsadresse.strasse
 * @param {string} rechnungsadresse.hausnummer
 * @param {string} rechnungsadresse.plz
 * @param {string} rechnungsadresse.ort
 * @param {string} rechnungsadresse.land
 * @param {string} rechnungsadresse.email
 * @param {string} rechnungsadresse.kommunikationssprache
 * @param {string} reportsprache
 * @returns {Promise<AxiosResponse<T>>}
 */
export const createAbonnementForDigitalesMitglied = (crid, crefonummer, agbsAkzeptiert, turnus, zahlungsart, rechnungsadresse, reportsprache) =>
    axios.post(
        `/api/meine-bonitaet/portal/actions/digitale-kunden/do-abo-anlegen`,
        { crid, crefonummer, agbsAkzeptiert, turnus, zahlungsart, rechnungsadresse, reportsprache }
    )

/**
 * Send a POST request to create an abonnement for a registered user.
 *
 * @param {object} rechnungsadresse
 * @param {string} rechnungsadresse.crid
 * @param {string} rechnungsadresse.name
 * @param {string} rechnungsadresse.strasse
 * @param {string} rechnungsadresse.hausnummer
 * @param {string} rechnungsadresse.plz
 * @param {string} rechnungsadresse.ort
 * @param {string} rechnungsadresse.land
 * @param {string} rechnungsadresse.email
 * @param {string} rechnungsadresse.kommunikationssprache
 * @returns {Promise<AxiosResponse<T>>}
 */
export const createRechnungsadresseForDigitalesMitglied = rechnungsadresse =>
    axios.put(`/api/meine-bonitaet/rechnungsadressen/${rechnungsadresse.crid}`, rechnungsadresse)

/**
 * GET Rechnungsadressen
 *
 * @param {string} crid
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getRechnungsadressen = crid =>
    axios.get(`/api/meine-bonitaet/rechnungsadressen/${crid}`)

/**
 * Send a POST request to stop an abonnement.
 *
 * @param {string} aboId
 * @return {Promise<AxiosResponse<T>>}
 */
export const stopAbonnementForMitglied = aboId =>
    axios.post('/api/meine-bonitaet/portal/actions/mitglieder/do-beantrage-kuendigung-abonnement', {aboId})

/**
 * Send a POST request to stop an abonnement.
 *
 * @param {string} aboId
 * @return {Promise<AxiosResponse<T>>}
 */
export const stopAbonnementForDigitalesMitglied = aboId =>
    axios.post('/api/meine-bonitaet/portal/actions/digitale-kunden/do-beantrage-kuendigung-abonnement', {aboId})

/**
 * Gets the login host name for user-management scs.
 *
 * @returns {string}
 */
function getLoginHost () {
    let loginHost = 'login.creditreform.de'
    const regex = new RegExp('(.+)cp\\.meine\\.creditreform\\.')
    const matchingHost = window.location.host.match(regex)
    if (matchingHost && matchingHost[1]) {
        loginHost = matchingHost[1] + 'sso.' + loginHost
    }
    return loginHost
}

/**
 * Transform axios headers for cors requests.
 *
 * @param {Array<string>} headers
 * @return {*[]}
 */
function transformHeadersForCORS (headers = []) {
    headers['Cache-Control'] = 'no-cache'
    delete headers['X-Act-For-Member-Id']
    delete headers['Pragma']
    delete headers['Expires']
    return headers
}

<template>
    <div
        v-if="isVisible"
        ref="modal"
        class="crefo-ui-modal"
        :class="modalIconClass"
        :data-qa="dataQaName"
    >
        <div class="modal-content">
            <div class="modal-header">
                <i
                    v-if="icon"
                    :class="headerIconClass"
                    :data-qa="dataQaName + '-icon'"
                />
                <h2
                    class="mt"
                    :data-qa="dataQaName + '-titel'"
                >
                    {{ title }}
                </h2>
                <span
                    class="modal-close"
                    @click="close"
                />
            </div>
            <div
                class="modal-body"
                :data-qa="dataQaName + '-body'"
            >
                <slot />
                <div>
                    <button
                        v-if="cancelLabel !== ''"
                        class="btn btn-default mt"
                        data-qa="cancel-button"
                        @click="close"
                    >
                        {{ cancelLabel }}
                    </button>
                    <button
                        v-if="confirmLabel !== ''"
                        class="btn btn-default mt"
                        :class="{ ml: cancelLabel !== '', 'btn-disabled': confirmButtonDisabled === true}"
                        :disabled="confirmButtonDisabled === true"
                        data-qa="confirm-button"
                        @click.prevent="submit"
                    >
                        {{ confirmLabel }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /**
     * Shows a modal dialog.
     *
     * Example usage:
     *     <modal-dialog
     *         icon="error"
     *         :title="$t('Error.Text')"
     *         :confirmLabel="$t('OK.Label')"
     *         :confirm-callback="myFunc"
     *         v-bind:visible="showModal"
     *         v-on:update:visible="showModal = $event"
     *     >
     *         <p>Es liegen {{ numberErrors }} Fehler vor</p>
     *     </modal-dialog>
     *
     *     methods: {
     *         myFunc: (event) {
     *             alert('OK was pressed')
     *         }
     *     }
     */
    export default {
        name: 'ModalDialog',
        props: {
            title: {
                type: String,
                required: false,
                default: ''
            },
            icon: {
                type: String,
                required: false,
                default: '',
                validator: value => {
                    return [ '', 'success', 'error', 'info', 'warning' ].includes(value)
                }
            },
            dataQa: {
                type: String,
                required: false,
                default: ''
            },
            confirmLabel: {
                type: String,
                required: false,
                default: ''
            },
            cancelLabel: {
                type: String,
                required: false,
                default: ''
            },
            confirmCallback: {
                type: Function,
                required: false
            },
            cancelCallback: {
                type: Function,
                required: false
            },
            visible: {
                type: Boolean,
                required: false,
                default: true
            },
            confirmButtonDisabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data () {
            return {
                isVisible: this.visible,
                eventListenerAdded: false
            }
        },
        computed: {
            modalIconClass () {
                return this.icon.length ? `modal-statusicon modal-${ this.icon }` : ''
            },
            dataQaName () {
                return this.dataQa ? `crefo-ui-modal--${ this.dataQa }` : 'crefo-ui-modal'
            },
            headerIconClass () {
                if (this.icon === 'success') {
                    return 'crefo-ui-icon icon-circle-success'
                } else if (this.icon === 'error') {
                    return 'crefo-ui-icon icon-circle-error'
                } else if (this.icon === 'info') {
                    return 'crefo-ui-icon icon-circle-info'
                } else if (this.icon === 'warning') {
                    return 'crefo-ui-icon icon-circle-warning'
                }
            }
        },
        watch: {
            visible: function (newProps, oldProps) {
                this.isVisible = newProps
            }
        },
        updated () {
            if (this.$refs.modal) {
                if (this.eventListenerAdded === false) {
                    this.eventListenerAdded = true
                    this.$refs.modal.addEventListener('click', event => {
                        // close the modal when the backdrop is clicked
                        if (event.target.classList.contains('crefo-ui-modal')) {
                            this.close(event)
                        }
                    })
                }
            } else {
                this.eventListenerAdded = false
            }
        },
        methods: {
            close (event) {
                if (this.isVisible) {
                    this.isVisible = false
                    this.$emit('update:visible', false)
                    if (typeof this.cancelCallback === 'function') {
                        setTimeout(() => {
                            this.cancelCallback(event)
                        }, 100)
                    }
                }
            },
            submit (event) {
                if (this.isVisible && !this.confirmButtonDisabled) {
                    this.isVisible = false
                    this.$emit('update:visible', false)
                    if (typeof this.confirmCallback === 'function') {
                        setTimeout(() => {
                            this.confirmCallback(event)
                        }, 100)
                    }
                }
            }
        }
    }
</script>

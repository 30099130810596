<template>
    <selbstauskunft-order-contact-keine-produktverfuegbarkeit>
        <span data-qa="info-nicht-verfuegbar">
            {{ $t('View.SelbstauskunftNichtVerfuegbar.InfoText') }}
        </span>
        <router-link
            :to="`/portal/ubo/?crefonummer=${userStore.crefonummer ? userStore.crefonummer : ''}`"
            class="btn btn-default btn-small mt"
            role="button"
            data-qa="jump-to-ubo"
        >
            {{ $t('View.SelbstauskunftNichtVerfuegbar.ButtonText') }}
        </router-link>
    </selbstauskunft-order-contact-keine-produktverfuegbarkeit>
</template>

<script>
    /**
     * This pages shows options to order a selbstauskunft.
     *
     *  - Redirects to startpage, when user state is not determined yet
     *  - Redirects to show selbstauskunft, when user already has one and is vertretungsberechtigt
     */
    import { mapStores } from 'pinia'
    import { useUserStore } from '@/stores/user'
    import SelbstauskunftOrderContactKeineProduktverfuegbarkeit from '../components/selbstauskunft/order/contact-keine-produktverfuegbarkeit'
    export default {
        name: 'SelbstauskunftNichtVerfuegbar',
        components: {
            SelbstauskunftOrderContactKeineProduktverfuegbarkeit
        },
        computed: {
            ...mapStores(useUserStore)
        }
    }
</script>

<style scoped lang="less">
    .btn-small {
        line-height: 1.5;
        padding-top: 9px;
        padding-bottom: 9px;
    }
</style>

<template>
    <div>
        <div
            v-if="showError"
            class="bg-white box-spacing box-shadow-xy box-profile"
        >
            <div class="row">
                <div class="col-12">
                    <crefo-error-message
                        v-if="showError"
                        class="mb"
                        :ajax-error="selbstauskunftStore.ajaxError"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="!showError && !selbstauskunftStore.selbstauskuenfteAvailable"
            class="bg-white box-spacing box-shadow-xy box-profile"
        >
            <div class="row">
                <div class="col-12">
                    <div class="crefo-ui-alert error">
                        <div data-qa="error-message">
                            {{ $t('CompanyProfileHistory.Error.NoItems') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!showError && selbstauskunftStore.selbstauskuenfteAvailable"
            class="bg-white box-spacing box-shadow-xy box-profile"
        >
            <div class="row">
                <div class="col-12 mb">
                    <div
                        class="h2 mb-small"
                        data-qa="appname"
                    >
                        {{ $t('Appname') }}
                    </div>
                    <p v-html="$t('CompanyProfileHistory.Label.AuskunftVom', { date: selbstauskunftStore.getLatestReport.auskunftsdatum })" />
                    <selbstauskunft-show-download-button
                        :auskunfts-id="selbstauskunftStore.getLatestReport.auskunftsId"
                        :is-abonnement-gesperrt="abonnementStore.isAboGesperrt"
                        class="btn btn-default btn-block mb-small"
                        :class="{ 'btn-disabled': abonnementStore.isAboGesperrt }"
                        role="button"
                    >
                        <p>
                            <span class="btn-description">{{ $t('CompanyProfileHistory.Label.Download') }}</span>
                            <span class="btn-description btn-description-secondary">{{ $t('CompanyProfileHistory.Label.DownloadPdf') }}</span>
                        </p>
                    </selbstauskunft-show-download-button>
                    <p
                        v-if="abonnementStore.isAboGesperrt"
                        class="text-brand-red"
                        data-qa="selbstauskunft-gesperrt"
                    >
                        {{ $t('Components.Selbstauskunft.Show.AbonnementGesperrt') }}
                    </p>
                </div>
                <div
                    v-if="isVisible"
                    class="col-12"
                >
                    <div class="h3 table-header mb-small">
                        {{ $t('CompanyProfileHistory.Label.Auskunftshistorie') }}
                    </div>
                    <div
                        class="row"
                        data-qa="auskunftshistorie-table"
                    >
                        <div
                            class="col-3 text-semibold toggle-sort-order"
                            data-qa="auskunftshistorie-header-datum"
                            @click.prevent="toggleSortOrder"
                        >
                            {{ $t('CompanyProfileHistory.Label.Auskunftsdatum') }}
                            <span :class="{ 'sorted-desc': sortDateDescending, 'sorted-asc': !sortDateDescending }" />
                        </div>
                        <div
                            class="col-9 text-semibold"
                            data-qa="auskunftshistorie-header-nachtragsgrund"
                        >
                            {{ $t('CompanyProfileHistory.Label.Nachtragsgrund') }}
                        </div>
                        <div
                            ref="scrollerWrapper"
                            class="col-12 page-wrapper mb-small"
                        >
                            <div
                                ref="scrollerItem"
                                class="page-wrapper-inner"
                                data-qa="auskunftshistorie-body"
                            >
                                <div
                                    v-for="(selbstauskunftPage, page) in selbstauskuenftePages"
                                    :key="'page-' + page"
                                    :ref="'page-' + page"
                                    :data-qa="'page-' + page"
                                    class="page"
                                    :class="{ active: currentPageIndex === page }"
                                >
                                    <div
                                        v-for="(selbstauskunft, index) in selbstauskunftPage"
                                        :key="'page-' + page + '-index-' + index"
                                        class="page-item"
                                    >
                                        <div
                                            class="row"
                                            data-qa="selbstauskunft-historie"
                                        >
                                            <div class="col-3 text-brand-darkest-grey">
                                                <span data-qa="auskunftsdatum">{{ selbstauskunft.auskunftsdatum }}</span>
                                            </div>
                                            <div class="col-9">
                                                <selbstauskunft-show-download-button
                                                    :auskunfts-id="selbstauskunft.auskunftsId"
                                                    :is-abonnement-gesperrt="abonnementStore.isAboGesperrt"
                                                    class="btn-icon"
                                                    :class="{ 'btn-disabled': abonnementStore.isAboGesperrt }"
                                                >
                                                    <ul
                                                        data-qa="nachtragsgruende"
                                                        @click.prevent="download"
                                                    >
                                                        <li
                                                            v-for="(nachtrag, nachtragIndex) in selbstauskunft.nachtragsgruende"
                                                            :key="'nachtrag-' + nachtragIndex"
                                                        >
                                                            <span data-qa="nachtragsgrund">{{ getNachtragsgrund(nachtrag) }}</span>
                                                        </li>
                                                        <li v-if="selbstauskunft.nachtragsgruende.length === 0">
                                                            <span data-qa="nachtragsgrund">{{ $t('CompanyProfileHistory.Nachtragsgrund.Erstauskunft') }}</span>
                                                        </li>
                                                    </ul>
                                                </selbstauskunft-show-download-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="selbstauskuenftePages.length > 1"
                            class="col-12 page-browser"
                        >
                            <button
                                class="btn btn-default btn-small btn-min text-brand-darkest-grey"
                                :class="{ 'btn-disabled': currentPageIndex === 0 }"
                                :disabled="currentPageIndex === 0"
                                data-qa="historie-prev-page"
                                @click.prevent="prevPage"
                            >
                                <i
                                    class="crefo-ui-icon icon-chevron-prev"
                                    aria-hidden="true"
                                />
                            </button>
                            <button
                                class="btn btn-default btn-small btn-min text-brand-darkest-grey"
                                :class="{ 'btn-disabled': currentPageIndex >= lastPageIndex }"
                                :disabled="currentPageIndex >= lastPageIndex"
                                data-qa="historie-next-page"
                                @click.prevent="nextPage"
                            >
                                <i
                                    class="crefo-ui-icon icon-chevron-next"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapStores } from 'pinia'
    import { useAbonnementStore } from '@/stores/abonnement'
    import { useSelbstauskunftStore } from '@/stores/selbstauskunft'
    import SelbstauskunftShowDownloadButton from './download-button'
    import CrefoErrorMessage from '@dm/crefo-error-message'
    export default {
        name: 'SelbstauskunftShowHistorie',
        components: { SelbstauskunftShowDownloadButton, CrefoErrorMessage },
        props: {
            pageSize: {
                type: Number,
                default: 3
            }
        },
        data () {
            return {
                sortDateDescending: true,
                currentPageIndex: 0,
                lastPageIndex: 0
            }
        },
        computed: {
            ...mapStores(useAbonnementStore, useSelbstauskunftStore),
            selbstauskuenftePages () {
                const pages = []
                let items = this.selbstauskunftStore.getSelbstauskuenfte
                if (this.sortDateDescending === false) {
                    items = [ ...items ].reverse()
                }
                for (let i = 0; i < items.length; i += this.pageSize) {
                    pages.push(items.slice(i, i + this.pageSize))
                }
                this.lastPageIndex = pages.length - 1
                return pages
            },
            isVisible () {
                return this.selbstauskunftStore.getSelbstauskuenfte.length > 1
            },
            showError () {
                return this.selbstauskunftStore.ajaxError !== null
            }
        },
        mounted () {
            this.gotoPage(this.currentPageIndex, true)
            window.addEventListener('resize', this.handleWindowResize)
        },
        updated () {
            this.gotoPage(this.currentPageIndex, true)
        },
        beforeDestroy () {
            window.removeEventListener('resize', this.handleWindowResize)
        },
        methods: {
            getNachtragsgrund: function (nachtrag) {
                const translationKey = 'SupplementReason.' + nachtrag.fachlicheId
                if (this.$te(translationKey, 'de_DE')) {
                    return this.$t(translationKey)
                } else if (nachtrag.bezeichnung.length) {
                    return nachtrag.bezeichnung
                } else {
                    return this.$t('SupplementReason.Aunbekannt')
                }
            },
            toggleSortOrder: function () {
                this.currentPageIndex = 0
                this.sortDateDescending = !this.sortDateDescending
            },
            prevPage: function () {
                if (this.currentPageIndex > 0) {
                    this.gotoPage(--this.currentPageIndex)
                }
            },
            nextPage: function () {
                if (this.currentPageIndex < this.lastPageIndex) {
                    this.gotoPage(++this.currentPageIndex)
                }
            },
            getHeightOfPage: function (page) {
                if (this.$refs['page-' + page].length) {
                    return this.$refs['page-' + page][0].offsetHeight
                }
                return 0
            },
            getOffsetTopOfPage: function (page) {
                if (this.$refs['page-' + page].length) {
                    return this.$refs['page-' + page][0].offsetTop
                }
                return 0
            },
            adjustScrollerWrapperHeight: function (page) {
                if (this.$refs.scrollerWrapper) {
                    this.$refs.scrollerWrapper.style.maxHeight = this.getHeightOfPage(page) + 'px'
                }
            },
            advanceScrollerItemToPage: function (page) {
                if (this.$refs.scrollerItem) {
                    this.$refs.scrollerItem.style.opacity = '0.75'
                    this.$refs.scrollerItem.style.top = (this.getOffsetTopOfPage(page) * -1) + 'px'
                    setTimeout(() => {
                        this.$refs.scrollerItem.style.opacity = '1'
                    }, 150)
                }
            },
            advanceWindowToPageBrowser: function (page) {
                let currentHeight = parseInt(this.$refs.scrollerWrapper.style.maxHeight)
                if (isNaN(currentHeight)) {
                    currentHeight = 0
                }
                const diffHeight = this.getHeightOfPage(page) - currentHeight
                if (diffHeight !== 0) {
                    if ('scrollBehavior' in document.documentElement.style) {
                        window.scrollBy({ top: diffHeight, left: 0, behavior: 'smooth' })
                    } else {
                        window.scrollBy(0, diffHeight)
                    }
                }
            },
            gotoPage: function (page, triggerdByUpdate = false) {
                this.advanceScrollerItemToPage(page)
                if (triggerdByUpdate === false) {
                    this.advanceWindowToPageBrowser(page)
                }
                this.adjustScrollerWrapperHeight(page)
            },
            handleWindowResize: function () {
                this.gotoPage(this.currentPageIndex, true)
            }
        }
    }
</script>

<style scoped lang="less">
    .btn-block {
        p {
            margin: 15px 0;

            .btn-description {
                display: block;
            }

            .btn-description-secondary {
                font-weight: normal;
            }
        }
    }

    .table-header {
        margin-bottom: 0;
    }

    .toggle-sort-order {
        cursor: pointer;

        .sorted-asc {
            position: relative;
            top: 16px;
            left: 5px;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 9px solid #009ee2;
        }

        .sorted-desc {
            position: relative;
            top: -13px;
            left: 5px;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 9px solid #009ee2;
        }
    }

    .page-wrapper {
        margin-top: 5px;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: auto;
        min-height: 39px;
        max-height: 39px;
        transition: all 0.2s ease-in-out;

        .page-wrapper-inner {
            position: relative;
            width: 100%;
            top: 0;
            left: 0;
            opacity: 1;
            transition: top 0.35s ease-in-out, opacity 0.1s ease-in-out;

            .page {
                line-height: 26px;

                .page-item {
                    padding-top: 6px;
                    padding-bottom: 7px;
                    border-bottom: 1px solid #edece5;

                    ul {
                        list-style-type: none;
                        margin: 0 53px 0 0;
                        padding: 0;

                        li {
                            display: block;
                        }

                        li span {
                            display: inline-block;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }

    .page-browser {
        margin-top: 3px;

        .btn-default {
            margin-right: 5px;
            padding: 0;
            min-width: 25px;
            background-color: transparent;

            &:hover, &:focus, &:active {
                color: #000000 !important;
                background-color: transparent;
            }
        }

        .btn-disabled {
            color: #7f7f7f !important;
            cursor: default;
            pointer-events: none;

            &:hover, &:focus, &:active {
                color: #7f7f7f !important;
            }
        }
    }
</style>
